import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import tnimage from '../../utils/tnimage.png';

const Landing = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <section className="landing" >
      <div style={{color: "crimson"}}>
        <div className="landing-inner" style={{paddingTop: "100px"}}>
        <img src={tnimage} style={{height: "100px", width: "auto"}} alt="tn"/>
        <br/>
        <h4 style={{color: "black", textAlign: "center",  fontWeight: "bolder"}}> தொழில்நுட்பக் கல்வி இயக்குனரகம், சென்னை - 600025 </h4>
            
            <h3 className="" style={{color: "brown", fontWeight: "bold"}}>இணைய வழி இடமாறுதல் கலந்தாய்வு </h3>
          
          {/* <p className="lead" style={{color: "navy", fontWeight: "bold"}}>
           Principal Approval System
          </p> */}
          <div className="buttons my-3">
            {/* <Link to="/register" className="btn btn-primary">
              Sign Up
            </Link> */}
            <Link to="/coord3" className="btn btn-white">
            உள்நுழைக
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated
});

export default connect(mapStateToProps)(Landing);
