const EXAMINATION_BOARDS = [{
    code: '01',
    key:1,
    label:'Tamil nadu Board of Higher Secondary Education (HSC)',
    state:'Tamil nadu',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '02',
    key:2,
    label:'Central Board of Secondary Education (CBSE)',
    state:'All-India Board',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '03',
    key:3,
    label:'Council for Indian School Certificate Examinations',
    state:'All-India Board',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '04',
    key:4,
    label:'Andhra Pradesh Board of Intermediate Education',
    state:'Andhra Pradesh',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '05',
    key:5,
    label:'Bihar Intermediate Education Council',
    state:'Bihar',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '10',
    key:6,
    label:'Himachal Pradesh Board of School Education',
    state:'Himachal Pradesh',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '11',
    key:7,
    label:'The Jammu & Kashmir State Board of School Education(Annual 2018 Regular Kashmir (18AKTR))',
    state:'Jammu & Kashmir',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:99
},{
    code: '11',
    key:8,
    label:'The Jammu & Kashmir State Board of School Education(Annual 2018 Regular Kargil (18AGTR))',
    state:'Jammu & Kashmir',
    maxMaths:93,
    maxPhysics:98,
    maxChemistry:98
},{
    code: '11',
    key:9,
    label:'The Jammu & Kashmir State Board of School Education(Annual 2018 Regular Jammu (18AJTW) Winter Zone)',
    state:'Jammu & Kashmir',
    maxMaths:98,
    maxPhysics:99,
    maxChemistry:99
},{
    code: '11',
    key:10,
    label:'The Jammu & Kashmir State Board of School Education(Annual 2019 Regular Jammu (19AJTR) Summer Zone)',
    state:'Jammu & Kashmir',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '11',
    key:11,
    label:'The Jammu & Kashmir State Board of School Education(Annual 2018 Regular Leh (18ALTW) Winter Zone)',
    state:'Jammu & Kashmir',
    maxMaths:92,
    maxPhysics:97,
    maxChemistry:89
},{
    code: '15',
    key:12,
    label:'Madhya Pradesh Board of Secondary Education',
    state:'Madhya Pradesh',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '16',
    key:13,
    label:'Manipur Council of Higher Secondary Education',
    state:'Manipur',
    maxMaths:99,
    maxPhysics:99,
    maxChemistry:93
},{
    code: '17',
    key:14,
    label:'Meghalaya Board of School Education',
    state:'Meghalaya',
    maxMaths:97,
    maxPhysics:94,
    maxChemistry:95
},{
    code: '18',
    key:15,
    label:'Mizoram Board of School Education',
    state:'Mizoram',
    maxMaths:99,
    maxPhysics:97,
    maxChemistry:95
},{
    code: '19',
    key:16,
    label:'Nagaland Board of School Education',
    state:'Nagaland',
    maxMaths:98,
    maxPhysics:98,
    maxChemistry:97
},{
    code: '21',
    key:17,
    label:'Punjab School Education Board',
    state:'Punjab',
    maxMaths:100,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '24',
    key:18,
    label:'U.P. Board of High School & Intermediate Education',
    state:'Uttar Pradesh',
    maxMaths:99,
    maxPhysics:100,
    maxChemistry:100
},{
    code: '25',
    key:19,
    label:'International General Certificate of Secondary Education (IGCSE)',
    state:'Cambridge Assessment International Education',
    maxMaths:99,
    maxPhysics:97,
    maxChemistry:98
}];


const STATES =  [
    {
        key:'AN',
        label:'Andaman Nicobar'
    },
    {
        key:'AP',
        label:'Andhra Pradesh'
    },{
        key:'ARP',
        label:'Arunachal Pradesh'
    },{
        key:'AS',
        label:'Assam'
    },{
        key:'BI',
        label:'Bihar'
    },{
        key:'CH',
        label:'Chandigarh'
    },{
        key:'CHH',
        label:'Chhattisgarh'
    },{
        key:'DNH',
        label:"Dadra Nagar Haveli"
    },{
        key:'DA',
        label:'Daman & Diu'
    },{
        key:'DL',
        label:'Delhi'
    },{
        key:'GO',
        label:'Goa'
    },{
        key:'GU',
        label:'Gujarat'
    },{
        key:'HA',
        label:'Haryana'
    },{
        key:'HP',
        label:'Himachal Pradesh'
    },{
        key:'JK',
        label:'Jammu Kashmir'
    },{
        key:'JH',
        label:'Jharkhand'
    },{
        key:'KA',
        label:'Karnataka'
    },{
        key:'KE',
        label:'Kerala'
    },{
        key:'LD',
        label:'Ladakh'
    },{
        key:'LA',
        label:'Lakshadweep'
    },{
        key:'MP',
        label:'Madhya Pradesh'
    },{
        key:'MA',
        label:'Maharashtra'
    },{
        key:'MN',
        label:'Manipur'
    },{
        key:'ME',
        label:'Meghalaya'
    },{
        key:'MI',
        label: 'Mizoram'
    },{
        key:'NA',
        label:'Nagaland'
    },{
        key:'OD',
        label:'Odisha'
    },{
        key:'PY',
        label:'Puducherry'
    },{
        key:'PJ',
        label:'Punjab'
    },{
        key:'RJ',
        label:'Rajasthan'
    },
    {
        key:'GI',
        label:'Sikkim'
    },{
        key:'TN',
        label:'Tamil nadu'
    },{
        key:'TS',
        label:'Telangana'
    },{
        key:'TI',
        label:'Tripura'
    },{
        key:'UP',
        label:'Uttar Pradesh'
    },{
        key:'UT',
        label:'Uttarakhand'
    },{
        key:'WB',
        label:'West Bengal'
    },{
       key:'OTH',
       label:"Others"
    }
];
const DISTRICTS = {
    AN:['Nicobar','North Middle Andaman','South Andaman'],
    AP:['Anantapur','Chittoor','East Godavari','Guntur','Kadapa','Krishna','Kurnool','Nellore','Prakasam','Srikakulam','Visakhapatnam','Vizianagaram','West Godavari'],
    ARP:['Anjaw','Central Siang','Changlang','Dibang Valley','East Kameng','East Siang','Kamle','Kra Daadi','Kurung Kumey','Lepa Rada','Lohit','Longding','Lower Dibang Valley','Lower Siang',
        'Lower Subansiri','Namsai','Pakke Kessang','Papum Pare','Shi Yomi','Tawang','Tirap','Upper Siang','Upper Subansiri','West Kameng','West Siang'],
    AS:['Baksa','Barpeta','Biswanath','Bongaigaon','Cachar','Charaideo','Chirang','Darrang','Dhemaji','Dhubri','Dibrugarh','Dima Hasao','Goalpara','Golaghat','Hailakandi','Hojai','Jorhat'
        ,'Kamrup','Kamrup Metropolitan','Karbi Anglong','Karimganj','Kokrajhar','Lakhimpur','Majuli','Morigaon','Nagaon','Nalbari','Sivasagar','Sonitpur','South Salmara-Mankachar','Tinsukia'
        ,'Udalguri','West Karbi Anglong'],
    BI:['Araria','Arwal','Aurangabad','Banka','Begusarai','Bhagalpur','Bhojpur','Buxar','Darbhanga','East Champaran','Gaya','Gopalganj','Jamui','Jehanabad','Kaimur','Katihar','Khagaria','Kishanganj',
        'Lakhisarai','Madhepura','Madhubani','Munger','Muzaffarpur','Nalanda','Nawada','Patna','Purnia','Rohtas','Saharsa','Samastipur','Saran','Sheikhpura','Sheohar','Sitamarhi','Siwan','Supaul'
        ,'Vaishali','West Champaran'],
    CH:['Chandigarh'],
    CHH:["Balod", "Baloda Bazar", "Balrampur", "Bastar", "Bemetara", "Bijapur", "Bilaspur", "Dantewada", "Dhamtari", "Durg", "Gariaband", "Janjgir Champa", "Jashpur", "Kabirdham", "Kanker", "Kondagaon",
        "Korba", "Koriya", "Mahasamund", "Mungeli", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Sukma", "Surajpur", "Surguja"],
    DNH:['Dadra Nagar Haveli'],
    DA:['Daman','Diu'],
    DL:["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", "Shahdara", "South Delhi", "South East Delhi", "South West Delhi", "West Delhi"],
    GO:['North Goa','South Goa'],
    GU:["Ahmedabad", "Amreli", "Anand", "Aravalli", "Banaskantha", "Bharuch", "Bhavnagar", "Botad", "Chhota Udaipur", "Dahod", "Dang", "Devbhoomi Dwarka", "Gandhinagar", "Gir Somnath",
        "Jamnagar", "Junagadh", "Kheda", "Kutch", "Mahisagar", "Mehsana", "Morbi", "Narmada", "Navsari", "Panchmahal", "Patan", "Porbandar", "Rajkot", "Sabarkantha", "Surat", "Surendranagar",
        "Tapi", "Vadodara", "Valsad"],
    HA:["Ambala", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gurugram", "Hisar", "Jhajjar", "Jind", "Kaithal", "Karnal", "Kurukshetra", "Mahendragarh", "Mewat",
        "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sirsa", "Sonipat", "Yamunanagar"],
    HP:["Bilaspur", "Chamba", "Hamirpur", "Kangra", "Kinnaur", "Kullu", "Lahaul Spiti", "Mandi", "Shimla", "Sirmaur", "Solan", "Una"],
    JK:["Anantnag", "Bandipora", "Baramulla", "Budgam", "Doda", "Ganderbal", "Jammu", "Kathua", "Kishtwar", "Kulgam", "Kupwara", "Poonch", "Pulwama", "Rajouri", "Ramban",
        "Reasi", "Samba", "Shopian", "Srinagar", "Udhampur"],
    JH:["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridih", "Godda", "Gumla", "Hazaribagh", "Jamtara", "Khunti",
        "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamu", "Ramgarh", "Ranchi", "Sahebganj", "Seraikela Kharsawan", "Simdega", "West Singhbhum"],
    KA:["Bagalkot", "Bangalore Rural", "Bangalore Urban", "Belgaum", "Bellary", "Bidar", "Chamarajanagar", "Chikkaballapur", "Chikkamagaluru",
        "Chitradurga", "Dakshina Kannada", "Davanagere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal",
        "Mandya", "Mysore", "Raichur", "Ramanagara", "Shimoga", "Tumkur", "Udupi", "Uttara Kannada", "Vijayapura", "Yadgir"],
    KE:["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasaragod", "Kollam", "Kottayam", "Kozhikode", "Malappuram", "Palakkad", "Pathanamthitta",
        "Thiruvananthapuram", "Thrissur", "Wayanad"],
    LA:['Lakshadweep'],
    LD:['Kargil','Leh'],
    MP:["Agar Malwa", "Alirajpur", "Anuppur", "Ashoknagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur",
        "Chhatarpur", "Chhindwara", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "Guna", "Gwalior", "Harda", "Hoshangabad",
        "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Mandla", "Mandsaur", "Morena", "Narsinghpur",
        "Neemuch", "Niwari", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol",
        "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha"],
    MA:["Ahmednagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur",
        "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai City", "Mumbai Suburban",
        "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Palghar", "Parbhani", "Pune", "Raigad", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur",
        "Thane", "Wardha", "Washim", "Yavatmal"],
    MN:["Bishnupur", "Chandel", "Churachandpur", "Imphal East", "Imphal West", "Jiribam", "Kakching", "Kamjong", "Kangpokpi", "Noney", "Pherzawl", "Senapati", "Tamenglong", "Tengnoupal",
        "Thoubal", "Ukhrul"],
    ME:["East Garo Hills", "East Jaintia Hills", "East Khasi Hills", "North Garo Hills", "Ri Bhoi", "South Garo Hills", "South West Garo Hills", "South West Khasi Hills",
        "West Garo Hills", "West Jaintia Hills", "West Khasi Hills"],
    MI:["Aizawl", "Champhai", "Kolasib", "Lawngtlai", "Lunglei", "Mamit", "Saiha", "Serchhip"],
    NA:["Mon", "Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Noklak", "Peren", "Phek", "Tuensang", "Wokha", "Zunheboto"],
    OD:["Angul", "Balangir", "Balasore", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Debagarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghpur", "Jajpur", "Jharsuguda", "Kalahandi",
        "Kandhamal", "Kendrapara", "Kendujhar", "Khordha", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Subarnapur", "Sundergarh"],
    PY:["Karaikal", "Mahe", "Puducherry", "Yanam"],
    PJ:["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Firozpur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Mansa", "Moga", "Mohali", "Muktsar",
        "Pathankot", "Patiala", "Rupnagar", "Sangrur", "Shaheed Bhagat Singh Nagar", "Tarn Taran"],
    RJ:["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Hanumangarh", "Jaipur", "Jaisalmer",
        "Jalore", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Pratapgarh", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur"],
    GI:["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
    TN:["Ariyalur", "Chengalpattu", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kallakurichi", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai",
        "Nagapattinam", "Namakkal", "Perambalur", "Pudukkottai", "Ramanathapuram", "Ranipet", "Salem", "Sivaganga", "Tenkasi ", "Thanjavur", "The Nilgiris","Theni", 'Thirupattur',"Thoothukudi", "Tiruchirappalli",
        "Tirunelveli",  "Tiruppur", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Vellore", "Viluppuram", "Virudhunagar"],
    TS:["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar", "Jogulamba", "Kamareddy", "Karimnagar", "Khammam", "Komaram Bheem", "Mahabubabad", "Mahbubnagar",
        "Mancherial", "Medak", "Medchal", "Mulugu", "Nagarkurnool", "Nalgonda", "Narayanpet", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Ranga Reddy", "Sangareddy", "Siddipet",
        "Suryapet", "Vikarabad", "Wanaparthy", "Warangal Rural", "Warangal Urban", "Yadadri Bhuvanagiri"],
    TI:["Dhalai", "Gomati", "Khowai", "North Tripura", "Sepahijala", "South Tripura", "Unakoti", "West Tripura"],
    UP:["Agra", "Aligarh", "Ambedkar Nagar", "Amethi", "Amroha", "Auraiya", "Ayodhya", "Azamgarh", "Baghpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti",
        "Bhadohi", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur",
        "Gonda", "Gorakhpur", "Hamirpur", "Hapur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kasganj", "Kaushambi", "Kheri", "Kushinagar",
        "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Prayagraj", "Raebareli", "Rampur",
        "Saharanpur", "Sambhal", "Sant Kabir Nagar", "Shahjahanpur", "Shamli", "Shravasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"],
    UT:["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri", "Pithoragarh", "Rudraprayag", "Tehri", "Udham Singh Nagar", "Uttarkashi"],
    WB:["Alipurduar", "Bankura", "Birbhum", "Cooch Behar", "Dakshin Dinajpur", "Darjeeling", "Hooghly", "Howrah", "Jalpaiguri", "Jhargram", "Kalimpong", "Kolkata", "Malda", "Murshidabad", "Nadia",
        "North 24 Parganas", "Paschim Bardhaman", "Paschim Medinipur", "Purba Bardhaman", "Purba Medinipur", "Purulia", "South 24 Parganas", "Uttar Dinajpur"]
};

const DIST_LIST= [{key:1,label:"Ariyalur"}, {key:2,label:"Chengalpattu"},{key:3,label:"Chennai"}, {key:4,label:"Coimbatore"}, {key:5,label:"Cuddalore"}, {key:6,label:"Dharmapuri"}, {key:7,label:"Dindigul"}, {key:8,label:"Erode"}, {key:9,label:"Kallakurichi"},
    {key:10,label:"Kancheepuram"}, {key:11,label:"Kanyakumari"}, {key:12,label:"Karur"}, {key:13,label:"Krishnagiri"}, {key:14,label:"Madurai"}, {key:15,label:"Mayiladuthurai"},{key:16,label:"Nagapattinam"},
    {key:17,label:"Namakkal"}, {key:18,label:"Perambalur"}, {key:19,label:"Pudukkottai"}, {key:20,label:"Ramanathapuram"}, {key:21,label:"Ranipet"}, {key:22,label:"Salem"}, {key:23,label:"Sivaganga"}, {key:24,label:"Tenkasi"},
    {key:25,label:"Thanjavur"}, {key:26,label:"The Nilgiris"},{key:27,label:"Theni"},{key:28,label:"Thoothukudi"}, {key:29,label:"Tiruchirappalli"},
    {key:30,label:"Tirunelveli"}, {key:31,label:'Tirupathur'},  {key:32,label:"Tiruppur"}, {key:33,label:"Tiruvallur"}, {key:34,label:"Tiruvannamalai"}, {key:35,label:"Tiruvarur"}, {key:36,label:"Vellore"}, {key:37,label:"Viluppuram"}, {key:38,label:"Virudhunagar"},{key:39,label:"Others"}];

const TALUK = {
    "1":[{ key: "256",  label: "Ariyalur" },
        { key: "257",  label: "Andimadam" },
        { key: "258",  label: "Sendurai" },
        { key: "259",  label: "Udaiyarpalayam" }],
    "2" : [{ key: "277",  label: "Chengalpattu" },
        { key: "278",  label: "Cheyyur" },
        { key: "279",  label: "Maduranthakam" },
        { key: "280",  label: "Pallavaram" },
        { key: "281",  label: "Tambaram" },
        { key: "282",  label: "Thirukalukundram" },
        { key: "283",  label: "Tiruporur" },
        { key: "284",  label: "Vandalur" }],
    "3" : [{ key: "1",  label: "Alandur" },
        { key: "2",  label: "Ambattur" },
        { key: "3",  label: "Aminjikarai" },
        { key: "4",  label: "Ayanavaram" },
        { key: "5",  label: "Egmore" },
        { key: "6",  label: "Guindy" },
        { key: "7",  label: "Madhavaram" },
        { key: "8",  label: "Madhuravoyal" },
        { key: "9",  label: "Mambalam" },
        { key: "10",  label: "Mylapore" },
        { key: "11",  label: "Perambur" },
        { key: "12",  label: "Purasavakkam" },
        { key: "13",  label: "Sholinganallur" },
        { key: "14",  label: "Thiruvottriyur" },
        { key: "15",  label: "Tondiarpet" },
        { key: "16",  label: "Velacherry" }],
    "4" : [{ key: "17",  label: "Aanaimalai" },
        { key: "18",  label: "Annur" },
        { key: "19",  label: "Coimbatore(North)" },
        { key: "20",  label: "Coimbatore(South)" },
        { key: "21",  label: "Kinathukadavu" },
        { key: "22",  label: "Madukarai" },
        { key: "23",  label: "Mettupalayam" },
        { key: "24",  label: "Perur" },
        { key: "25",  label: "Pollachi" },
        { key: "26",  label: "Sulur" },
        { key: "27",  label: "Valparai" }],
    "5":[{ key: "28",  label: "Cuddalore" },
        { key: "29",  label: "Bhuvanagiri" },
        { key: "30",  label: "Chidambaram" },
        { key: "31",  label: "Kattumannarkoil" },
        { key: "32",  label: "Kurinjipadi" },
        { key: "33",  label: "Panruti" },
        { key: "34",  label: "Srimushnam" },
        { key: "35",  label: "Thittakudi" },
        { key: "36",  label: "Veppur" },
        { key: "37",  label: "Virudhachalam" }],
    "6":[{ key: "38",  label: "Dharmapuri" },
        { key: "39",  label: "Harur" },
        { key: "40",  label: "Karimangalam" },
        { key: "41",  label: "Nallampalli" },
        { key: "42",  label: "Palacode" },
        { key: "43",  label: "Pappireddipatti" },
        { key: "44",  label: "Pennagaram" }],
    "7":[{ key: "45",  label: "Atthur" },
        { key: "46",  label: "Dindigul (East)" },
        { key: "47",  label: "Dindigul (West)" },
        { key: "48",  label: "Guziliyamparai" },
        { key: "49",  label: "Kodaikanal" },
        { key: "50",  label: "Natham" },
        { key: "51",  label: "Nilakottai" },
        { key: "52",  label: "Oddanchatram" },
        { key: "53",  label: "Palani" },
        { key: "54",  label: "Vedasandur" }],
    "8":[{ key: "55",  label: "Erode" },
        { key: "56",  label: "Anthiyur" },
        { key: "57",  label: "Bhavani" },
        { key: "58",  label: "Gobichettipalayam" },
        { key: "59",  label: "Kodumudi" },
        { key: "60",  label: "Modakurichi" },
        { key: "61",  label: "Nambiyur" },
        { key: "62",  label: "Perundurai" },
        { key: "63",  label: "Sathiyamangalam" },
        { key: "64",  label: "Thalavadi" }],
    "9":[{ key: "285",  label: "Kallakurichi" },
        { key: "286",  label: "Chinnaselam" },
        { key: "287",  label: "Kalvarayan Hills" },
        { key: "288",  label: "Sankarapuram" },
        { key: "289",  label: "Tirukoilur" },
        { key: "290",  label: "Ulundurpet" }],
    "10":[{ key: "65",  label: "Kancheepuram" },
        { key: "66",  label: "Kundrathur" },
        { key: "67",  label: "Sriperumbudur" },
        { key: "68",  label: "Uthiramerur" },
        { key: "69",  label: "Walajabad" }],
    "11":[{ key: "70",  label: "Agasteeswaram" },
        { key: "71",  label: "Kalkulam" },
        { key: "72",  label: "Killiyur" },
        { key: "73",  label: "Thiruvatar" },
        { key: "74",  label: "Thovalai" },
        { key: "75",  label: "Vilavankodu" }],
    "12":[{ key: "76",  label: "Karur" },
        { key: "77",  label: "Aravakurichi" },
        { key: "78",  label: "Kadavur" },
        { key: "79",  label: "Krishnarayapuram" },
        { key: "80",  label: "Kulithalai" },
        { key: "81",  label: "Manmangalam" },
        { key: "82",  label: "Pugalur" }],
    "13":[{ key: "260",  label: "Krishnagiri" },
        { key: "261",  label: "Anjetty" },
        { key: "262",  label: "Bargur" },
        { key: "263",  label: "Hosur" },
        { key: "264",  label: "Pochampalli" },
        { key: "265",  label: "Sulagiri" },
        { key: "266",  label: "Thenkanikottai" },
        { key: "267",  label: "Uthangarai" }],
    "14":[{ key: "83",  label: "Kallikudi" },
        { key: "84",  label: "Madurai (East)" },
        { key: "85",  label: "Madurai (North)" },
        { key: "86",  label: "Madurai (South)" },
        { key: "87",  label: "Madurai (West)" },
        { key: "88",  label: "Melur" },
        { key: "89",  label: "Peraiyur" },
        { key: "90",  label: "Thirumangalam" },
        { key: "91",  label: "Thiruparankundram" },
        { key: "92",  label: "Usilampatti" },
        { key: "93",  label: "Vadipatti" }],
    "16":[{ key: "94",  label: "Nagapattinam" },
        { key: "95",  label: "Kilvelur" },
        { key: "96",  label: "Thirukkuvalai" },
        { key: "97",  label: "Vedaranyam" }],
    "17":[{ key: "98",  label: "Namakkal" },
        { key: "99",  label: "Kholli Hills" },
        { key: "100",  label: "Kumarapalayam" },
        { key: "101",  label: "Mohanoor" },
        { key: "102",  label: "Paramathi Velur" },
        { key: "103",  label: "Rasipuram" },
        { key: "104",  label: "Senthamangalam" },
        { key: "105",  label: "Tiruchengode" }],
    "18":[{ key: "112",  label: "Perambalur" },
        { key: "113",  label: "Alathur" },
        { key: "114",  label: "Kunnam" },
        { key: "115",  label: "Veppanthattai" }],
    "19":[{ key: "116",  label: "Pudukottai" },
        { key: "117",  label: "Alangudi" },
        { key: "118",  label: "Aranthangi" },
        { key: "119",  label: "Avudiyarkoil" },
        { key: "120",  label: "Gandarvakottai" },
        { key: "121",  label: "Iluppur" },
        { key: "122",  label: "Karambakudi" },
        { key: "123",  label: "Kulathur" },
        { key: "124",  label: "Manamelkudi" },
        { key: "125",  label: "Ponnamaravathi" },
        { key: "126",  label: "Thirumayam" },
        { key: "127",  label: "Viralimalai" }],
    "20":[{ key: "128",  label: "Ramanathapuram" },
        { key: "129",  label: "Kadaladi" },
        { key: "130",  label: "Kamuthi" },
        { key: "131",  label: "Kezhakarai" },
        { key: "132",  label: "Mudukulathur" },
        { key: "133",  label: "Paramakudi" },
        { key: "134",  label: "Rajasingamangalam" },
        { key: "135",  label: "Rameswaram" },
        { key: "136",  label: "Tiruvadanai" }],
    "21":[{ key: "291",  label: "Arakkonam" },
        { key: "292",  label: "Arcot" },
        { key: "293",  label: "Kalavai" },
        { key: "294",  label: "Nemili" },
        { key: "295",  label: "Sholingur" },
        { key: "296",  label: "Walajah" }],
    "22":[{ key: "137",  label: "Salem" },
        { key: "138",  label: "Attur" },
        { key: "139",  label: "Edapadi" },
        { key: "140",  label: "Gangavalli" },
        { key: "141",  label: "Kadaiyampatti" },
        { key: "142",  label: "Mettur" },
        { key: "143",  label: "Omalur" },
        { key: "144",  label: "Pethanayakanpalayam" },
        { key: "145",  label: "Salem South" },
        { key: "146",  label: "Salem West" },
        { key: "147",  label: "Sankari" },
        { key: "148",  label: "Vazhapadi" },
        { key: "149",  label: "Yercaud" }],
    "23":[{ key: "150",  label: "Sivagangai" },
        { key: "151",  label: "Devakottai" },
        { key: "152",  label: "Ilayankudi" },
        { key: "153",  label: "Kalaiyarkovil" },
        { key: "154",  label: "Karaikudi" },
        { key: "155",  label: "Manamadurai" },
        { key: "156",  label: "Singampunari" },
        { key: "157",  label: "Thirupuvanam" },
        { key: "158",  label: "Tirupathur" }],
    "24":[{ key: "297",  label: "Tenkasi" },
        { key: "298",  label: "Alangulam" },
        { key: "299",  label: "Kadayanallur" },
        { key: "300",  label: "Sankarankovil" },
        { key: "301",  label: "Shenkottai" },
        { key: "302",  label: "Sivagiri" },
        { key: "303",  label: "Thiruvengadam" },
        { key: "304",  label: "Veerakeralampudur" }],
    "25":[{ key: "159",  label: "Thanjavur" },
        { key: "160",  label: "Boothalur" },
        { key: "161",  label: "Kumbakonam" },
        { key: "162",  label: "Orathanadu" },
        { key: "163",  label: "Papanasam" },
        { key: "164",  label: "Pattukottai" },
        { key: "165",  label: "Peravurani" },
        { key: "166",  label: "Thiruvaiyaru" },
        { key: "167",  label: "Thiruvidaimaruthur" }],
    "26":[{ key: "106",  label: "Udagamandalam" },
        { key: "107",  label: "Coonoor" },
        { key: "108",  label: "Gudalur" },
        { key: "109",  label: "Kothagiri" },
        { key: "110",  label: "Kundah" },
        { key: "111",  label: "Pandalur" }],
    "27":[{ key: "168",  label: "Theni" },
        { key: "169",  label: "Aandipatti" },
        { key: "170",  label: "Bodinayakanur" },
        { key: "171",  label: "Periyakulam" },
        { key: "172",  label: "Uthamapalayam" }],
    "31":[{ key: "305",  label: "Tirupathur" },
        { key: "306",  label: "Ambur" },
        { key: "307",  label: "Natrampalli" },
        { key: "308",  label: "Vaniyambadi" }],
    "28":[{ key: "202",  label: "Thoothukudi" },
        { key: "203",  label: "Eral" },
        { key: "204",  label: "Ettayapuram" },
        { key: "205",  label: "Kayathar" },
        { key: "206",  label: "Kovilpatti" },
        { key: "207",  label: "Ottapidaram" },
        { key: "208",  label: "Sattankulam" },
        { key: "209",  label: "Srivaikundam" },
        { key: "210",  label: "Tiruchendur" },
        { key: "211",  label: "Vilathikulam" }],
    "29":[{ key: "212",  label: "Lalgudi" },
        { key: "213",  label: "Manachanallur" },
        { key: "214",  label: "Manapparai" },
        { key: "215",  label: "Marungapuri" },
        { key: "216",  label: "Musiri" },
        { key: "217",  label: "Srirangam" },
        { key: "218",  label: "Thottiam" },
        { key: "219",  label: "Thuraiyur" },
        { key: "220",  label: "Tiruchirapalli (West)" },
        { key: "221",  label: "Tiruchirappalli (East)" },
        { key: "222",  label: "Tiruverumbur" }],
    "30":[{ key: "223",  label: "Tirunelveli" },
        { key: "224",  label: "Ambasamudram" },
        { key: "225",  label: "Cheranmahadevi" },
        { key: "226",  label: "Manur" },
        { key: "227",  label: "Nanguneri" },
        { key: "228",  label: "Palayamkottai" },
        { key: "229",  label: "Radhapuram" },
        { key: "230",  label: "Thisayanvilai" }],
    "32" : [{ key: "268",  label: "Avinashi" },
        { key: "269",  label: "Dharapuram" },
        { key: "270",  label: "Kangeyam" },
        { key: "271",  label: "Madathukkulam" },
        { key: "272",  label: "Oothukuli" },
        { key: "273",  label: "Palladam" },
        { key: "274",  label: "Tiruppur (North)" },
        { key: "275",  label: "Tiruppur (South)" },
        { key: "276",  label: "Udumalaipettai" }],
    "33":[{ key: "173",  label: "Thiruvallur" },
        { key: "174",  label: "Avadi" },
        { key: "175",  label: "Gummidipoondi" },
        { key: "176",  label: "Pallipattu" },
        { key: "177",  label: "Ponneri" },
        { key: "178",  label: "Poonamallee" },
        { key: "179",  label: "R.K. Pet" },
        { key: "180",  label: "Tiruthani" },
        { key: "181",  label: "Uthukottai" }],
    "34" :[{ key: "182",  label: "Thiruvannamalai" },
        { key: "183",  label: "Arni" },
        { key: "184",  label: "Chengam" },
        { key: "185",  label: "Chetpet" },
        { key: "186",  label: "Cheyyar" },
        { key: "187",  label: "Jamunamarathur" },
        { key: "188",  label: "Kalasapakkam" },
        { key: "189",  label: "Kilpennathur" },
        { key: "190",  label: "Polur" },
        { key: "191",  label: "Thandramet" },
        { key: "192",  label: "Vandavasi" }],
    "35":[{ key: "193",  label: "Vembakkam" },
        { key: "194",  label: "Thiruvarur" },
        { key: "195",  label: "Kodavasal" },
        { key: "196",  label: "Koothanallur" },
        { key: "197",  label: "Mannargudi" },
        { key: "198",  label: "Nannilam" },
        { key: "199",  label: "Needamangalam" },
        { key: "200",  label: "Thiruthuraipoondi" },
        { key: "201",  label: "Valangaiman" }],
    "36":[{ key: "231",  label: "Vellore" },
        { key: "232",  label: "Aanikattu" },
        { key: "233",  label: "Gudiyatham" },
        { key: "234",  label: "K V Kuppam" },
        { key: "235",  label: "Katpadi" },
        { key: "236",  label: "Pernambut" }],
    "37":[{ key: "237",  label: "Villupuram" },
        { key: "238",  label: "Gingee" },
        { key: "239",  label: "Kandachipuram" },
        { key: "240",  label: "Marakanam" },
        { key: "241",  label: "Melmalaiyanur" },
        { key: "242",  label: "Thiruvennainallur" },
        { key: "243",  label: "Tindivanam" },
        { key: "244",  label: "Vanur" },
        { key: "245",  label: "Vikravandi" }],
    "38":[{ key: "246",  label: "Virudhunagar" },
        { key: "247",  label: "Aruppukottai" },
        { key: "248",  label: "Kariyapatti" },
        { key: "249",  label: "Rajapalayam" },
        { key: "250",  label: "Sathur" },
        { key: "251",  label: "Sivakasi" },
        { key: "252",  label: "Srivilliputhur" },
        { key: "253",  label: "Tiruchuli" },
        { key: "254",  label: "Vembakottai" },
        { key: "255",  label: "Watrap" }],
    "15":[{ key: "309",  label: "Mayiladuthurai" },
        { key: "310",  label: "Kuthalam" },
        { key: "311",  label: "Sirkali" },
        { key: "312",  label: "Tharangambadi" }],
    "39":[{ key: "313",  label: "Others" }]
}

const ALL_TN_TALUK = [{ key: "1",  label: "Alandur" },
    { key: "2",  label: "Ambattur" },
    { key: "3",  label: "Aminjikarai" },
    { key: "4",  label: "Ayanavaram" },
    { key: "5",  label: "Egmore" },
    { key: "6",  label: "Guindy" },
    { key: "7",  label: "Madhavaram" },
    { key: "8",  label: "Madhuravoyal" },
    { key: "9",  label: "Mambalam" },
    { key: "10",  label: "Mylapore" },
    { key: "11",  label: "Perambur" },
    { key: "12",  label: "Purasavakkam" },
    { key: "13",  label: "Sholinganallur" },
    { key: "14",  label: "Thiruvottriyur" },
    { key: "15",  label: "Tondiarpet" },
    { key: "16",  label: "Velacherry" },
    { key: "17",  label: "Aanaimalai" },
    { key: "18",  label: "Annur" },
    { key: "19",  label: "Coimbatore(North)" },
    { key: "20",  label: "Coimbatore(South)" },
    { key: "21",  label: "Kinathukadavu" },
    { key: "22",  label: "Madukarai" },
    { key: "23",  label: "Mettupalayam" },
    { key: "24",  label: "Perur" },
    { key: "25",  label: "Pollachi" },
    { key: "26",  label: "Sulur" },
    { key: "27",  label: "Valparai" },
    { key: "28",  label: "Cuddalore" },
    { key: "29",  label: "Bhuvanagiri" },
    { key: "30",  label: "Chidambaram" },
    { key: "31",  label: "Kattumannarkoil" },
    { key: "32",  label: "Kurinjipadi" },
    { key: "33",  label: "Panruti" },
    { key: "34",  label: "Srimushnam" },
    { key: "35",  label: "Thittakudi" },
    { key: "36",  label: "Veppur" },
    { key: "37",  label: "Virudhachalam" },
    { key: "38",  label: "Dharmapuri" },
    { key: "39",  label: "Harur" },
    { key: "40",  label: "Karimangalam" },
    { key: "41",  label: "Nallampalli" },
    { key: "42",  label: "Palacode" },
    { key: "43",  label: "Pappireddipatti" },
    { key: "44",  label: "Pennagaram" },
    { key: "45",  label: "Atthur" },
    { key: "46",  label: "Dindigul (East)" },
    { key: "47",  label: "Dindigul (West)" },
    { key: "48",  label: "Guziliyamparai" },
    { key: "49",  label: "Kodaikanal" },
    { key: "50",  label: "Natham" },
    { key: "51",  label: "Nilakottai" },
    { key: "52",  label: "Oddanchatram" },
    { key: "53",  label: "Palani" },
    { key: "54",  label: "Vedasandur" },
    { key: "55",  label: "Erode" },
    { key: "56",  label: "Anthiyur" },
    { key: "57",  label: "Bhavani" },
    { key: "58",  label: "Gobichettipalayam" },
    { key: "59",  label: "Kodumudi" },
    { key: "60",  label: "Modakurichi" },
    { key: "61",  label: "Nambiyur" },
    { key: "62",  label: "Perundurai" },
    { key: "63",  label: "Sathiyamangalam" },
    { key: "64",  label: "Thalavadi" },
    { key: "65",  label: "Kancheepuram" },
    { key: "66",  label: "Kundrathur" },
    { key: "67",  label: "Sriperumbudur" },
    { key: "68",  label: "Uthiramerur" },
    { key: "69",  label: "Walajabad" },
    { key: "70",  label: "Agasteeswaram" },
    { key: "71",  label: "Kalkulam" },
    { key: "72",  label: "Killiyur" },
    { key: "73",  label: "Thiruvatar" },
    { key: "74",  label: "Thovalai" },
    { key: "75",  label: "Vilavankodu" },
    { key: "76",  label: "Karur" },
    { key: "77",  label: "Aravakurichi" },
    { key: "78",  label: "Kadavur" },
    { key: "79",  label: "Krishnarayapuram" },
    { key: "80",  label: "Kulithalai" },
    { key: "81",  label: "Manmangalam" },
    { key: "82",  label: "Pugalur" },
    { key: "83",  label: "Kallikudi" },
    { key: "84",  label: "Madurai (East)" },
    { key: "85",  label: "Madurai (North)" },
    { key: "86",  label: "Madurai (South)" },
    { key: "87",  label: "Madurai (West)" },
    { key: "88",  label: "Melur" },
    { key: "89",  label: "Peraiyur" },
    { key: "90",  label: "Thirumangalam" },
    { key: "91",  label: "Thiruparankundram" },
    { key: "92",  label: "Usilampatti" },
    { key: "93",  label: "Vadipatti" },
    { key: "94",  label: "Nagapattinam" },
    { key: "95",  label: "Kilvelur" },
    { key: "96",  label: "Thirukkuvalai" },
    { key: "97",  label: "Vedaranyam" },
    { key: "98",  label: "Namakkal" },
    { key: "99",  label: "Kholli Hills" },
    { key: "100",  label: "Kumarapalayam" },
    { key: "101",  label: "Mohanoor" },
    { key: "102",  label: "Paramathi Velur" },
    { key: "103",  label: "Rasipuram" },
    { key: "104",  label: "Senthamangalam" },
    { key: "105",  label: "Tiruchengode" },
    { key: "106",  label: "Udagamandalam" },
    { key: "107",  label: "Coonoor" },
    { key: "108",  label: "Gudalur" },
    { key: "109",  label: "Kothagiri" },
    { key: "110",  label: "Kundah" },
    { key: "111",  label: "Pandalur" },
    { key: "112",  label: "Perambalur" },
    { key: "113",  label: "Alathur" },
    { key: "114",  label: "Kunnam" },
    { key: "115",  label: "Veppanthattai" },
    { key: "116",  label: "Pudukottai" },
    { key: "117",  label: "Alangudi" },
    { key: "118",  label: "Aranthangi" },
    { key: "119",  label: "Avudiyarkoil" },
    { key: "120",  label: "Gandarvakottai" },
    { key: "121",  label: "Iluppur" },
    { key: "122",  label: "Karambakudi" },
    { key: "123",  label: "Kulathur" },
    { key: "124",  label: "Manamelkudi" },
    { key: "125",  label: "Ponnamaravathi" },
    { key: "126",  label: "Thirumayam" },
    { key: "127",  label: "Viralimalai" },
    { key: "128",  label: "Ramanathapuram" },
    { key: "129",  label: "Kadaladi" },
    { key: "130",  label: "Kamuthi" },
    { key: "131",  label: "Kezhakarai" },
    { key: "132",  label: "Mudukulathur" },
    { key: "133",  label: "Paramakudi" },
    { key: "134",  label: "Rajasingamangalam" },
    { key: "135",  label: "Rameswaram" },
    { key: "136",  label: "Tiruvadanai" },
    { key: "137",  label: "Salem" },
    { key: "138",  label: "Attur" },
    { key: "139",  label: "Edapadi" },
    { key: "140",  label: "Gangavalli" },
    { key: "141",  label: "Kadaiyampatti" },
    { key: "142",  label: "Mettur" },
    { key: "143",  label: "Omalur" },
    { key: "144",  label: "Pethanayakanpalayam" },
    { key: "145",  label: "Salem South" },
    { key: "146",  label: "Salem West" },
    { key: "147",  label: "Sankari" },
    { key: "148",  label: "Vazhapadi" },
    { key: "149",  label: "Yercaud" },
    { key: "150",  label: "Sivagangai" },
    { key: "151",  label: "Devakottai" },
    { key: "152",  label: "Ilayankudi" },
    { key: "153",  label: "Kalaiyarkovil" },
    { key: "154",  label: "Karaikudi" },
    { key: "155",  label: "Manamadurai" },
    { key: "156",  label: "Singampunari" },
    { key: "157",  label: "Thirupuvanam" },
    { key: "158",  label: "Tirupathur" },
    { key: "159",  label: "Thanjavur" },
    { key: "160",  label: "Boothalur" },
    { key: "161",  label: "Kumbakonam" },
    { key: "162",  label: "Orathanadu" },
    { key: "163",  label: "Papanasam" },
    { key: "164",  label: "Pattukottai" },
    { key: "165",  label: "Peravurani" },
    { key: "166",  label: "Thiruvaiyaru" },
    { key: "167",  label: "Thiruvidaimaruthur" },
    { key: "168",  label: "Theni" },
    { key: "169",  label: "Aandipatti" },
    { key: "170",  label: "Bodinayakanur" },
    { key: "171",  label: "Periyakulam" },
    { key: "172",  label: "Uthamapalayam" },
    { key: "173",  label: "Thiruvallur" },
    { key: "174",  label: "Avadi" },
    { key: "175",  label: "Gummidipoondi" },
    { key: "176",  label: "Pallipattu" },
    { key: "177",  label: "Ponneri" },
    { key: "178",  label: "Poonamallee" },
    { key: "179",  label: "R.K. Pet" },
    { key: "180",  label: "Tiruthani" },
    { key: "181",  label: "Uthukottai" },
    { key: "182",  label: "Thiruvannamalai" },
    { key: "183",  label: "Arni" },
    { key: "184",  label: "Chengam" },
    { key: "185",  label: "Chetpet" },
    { key: "186",  label: "Cheyyar" },
    { key: "187",  label: "Jamunamarathur" },
    { key: "188",  label: "Kalasapakkam" },
    { key: "189",  label: "Kilpennathur" },
    { key: "190",  label: "Polur" },
    { key: "191",  label: "Thandramet" },
    { key: "192",  label: "Vandavasi" },
    { key: "193",  label: "Vembakkam" },
    { key: "194",  label: "Thiruvarur" },
    { key: "195",  label: "Kodavasal" },
    { key: "196",  label: "Koothanallur" },
    { key: "197",  label: "Mannargudi" },
    { key: "198",  label: "Nannilam" },
    { key: "199",  label: "Needamangalam" },
    { key: "200",  label: "Thiruthuraipoondi" },
    { key: "201",  label: "Valangaiman" },
    { key: "202",  label: "Thoothukudi" },
    { key: "203",  label: "Eral" },
    { key: "204",  label: "Ettayapuram" },
    { key: "205",  label: "Kayathar" },
    { key: "206",  label: "Kovilpatti" },
    { key: "207",  label: "Ottapidaram" },
    { key: "208",  label: "Sattankulam" },
    { key: "209",  label: "Srivaikundam" },
    { key: "210",  label: "Tiruchendur" },
    { key: "211",  label: "Vilathikulam" },
    { key: "212",  label: "Lalgudi" },
    { key: "213",  label: "Manachanallur" },
    { key: "214",  label: "Manapparai" },
    { key: "215",  label: "Marungapuri" },
    { key: "216",  label: "Musiri" },
    { key: "217",  label: "Srirangam" },
    { key: "218",  label: "Thottiam" },
    { key: "219",  label: "Thuraiyur" },
    { key: "220",  label: "Tiruchirapalli (West)" },
    { key: "221",  label: "Tiruchirappalli (East)" },
    { key: "222",  label: "Tiruverumbur" },
    { key: "223",  label: "Tirunelveli" },
    { key: "224",  label: "Ambasamudram" },
    { key: "225",  label: "Cheranmahadevi" },
    { key: "226",  label: "Manur" },
    { key: "227",  label: "Nanguneri" },
    { key: "228",  label: "Palayamkottai" },
    { key: "229",  label: "Radhapuram" },
    { key: "230",  label: "Thisayanvilai" },
    { key: "231",  label: "Vellore" },
    { key: "232",  label: "Aanikattu" },
    { key: "233",  label: "Gudiyatham" },
    { key: "234",  label: "K V Kuppam" },
    { key: "235",  label: "Katpadi" },
    { key: "236",  label: "Pernambut" },
    { key: "237",  label: "Villupuram" },
    { key: "238",  label: "Gingee" },
    { key: "239",  label: "Kandachipuram" },
    { key: "240",  label: "Marakanam" },
    { key: "241",  label: "Melmalaiyanur" },
    { key: "242",  label: "Thiruvennainallur" },
    { key: "243",  label: "Tindivanam" },
    { key: "244",  label: "Vanur" },
    { key: "245",  label: "Vikravandi" },
    { key: "246",  label: "Virudhunagar" },
    { key: "247",  label: "Aruppukottai" },
    { key: "248",  label: "Kariyapatti" },
    { key: "249",  label: "Rajapalayam" },
    { key: "250",  label: "Sathur" },
    { key: "251",  label: "Sivakasi" },
    { key: "252",  label: "Srivilliputhur" },
    { key: "253",  label: "Tiruchuli" },
    { key: "254",  label: "Vembakottai" },
    { key: "255",  label: "Watrap" },
    { key: "256",  label: "Ariyalur" },
    { key: "257",  label: "Andimadam" },
    { key: "258",  label: "Sendurai" },
    { key: "259",  label: "Udaiyarpalayam" },
    { key: "260",  label: "Krishnagiri" },
    { key: "261",  label: "Anjetty" },
    { key: "262",  label: "Bargur" },
    { key: "263",  label: "Hosur" },
    { key: "264",  label: "Pochampalli" },
    { key: "265",  label: "Sulagiri" },
    { key: "266",  label: "Thenkanikottai" },
    { key: "267",  label: "Uthangarai" },
    { key: "268",  label: "Avinashi" },
    { key: "269",  label: "Dharapuram" },
    { key: "270",  label: "Kangeyam" },
    { key: "271",  label: "Madathukkulam" },
    { key: "272",  label: "Oothukuli" },
    { key: "273",  label: "Palladam" },
    { key: "274",  label: "Tiruppur (North)" },
    { key: "275",  label: "Tiruppur (South)" },
    { key: "276",  label: "Udumalaipettai" },
    { key: "277",  label: "Chengalpattu" },
    { key: "278",  label: "Cheyyur" },
    { key: "279",  label: "Maduranthakam" },
    { key: "280",  label: "Pallavaram" },
    { key: "281",  label: "Tambaram" },
    { key: "282",  label: "Thirukalukundram" },
    { key: "283",  label: "Tiruporur" },
    { key: "284",  label: "Vandalur" },
    { key: "285",  label: "Kallakurichi" },
    { key: "286",  label: "Chinnaselam" },
    { key: "287",  label: "Kalvarayan Hills" },
    { key: "288",  label: "Sankarapuram" },
    { key: "289",  label: "Tirukoilur" },
    { key: "290",  label: "Ulundurpet" },
    { key: "291",  label: "Arakkonam" },
    { key: "292",  label: "Arcot" },
    { key: "293",  label: "Kalavai" },
    { key: "294",  label: "Nemili" },
    { key: "295",  label: "Sholingur" },
    { key: "296",  label: "Walajah" },
    { key: "297",  label: "Tenkasi" },
    { key: "298",  label: "Alangulam" },
    { key: "299",  label: "Kadayanallur" },
    { key: "300",  label: "Sankarankovil" },
    { key: "301",  label: "Shenkottai" },
    { key: "302",  label: "Sivagiri" },
    { key: "303",  label: "Thiruvengadam" },
    { key: "304",  label: "Veerakeralampudur" },
    { key: "305",  label: "Tirupathur" },
    { key: "306",  label: "Ambur" },
    { key: "307",  label: "Natrampalli" },
    { key: "308",  label: "Vaniyambadi" },
    { key: "309",  label: "Mayiladuthurai" },
    { key: "310",  label: "Kuthalam" },
    { key: "311",  label: "Sirkali" },
    { key: "312",  label: "Tharangambadi" },
    { key: "313",  label: "Others" }
]

const MOTHER_TONGUE = [{ key : 1, label: "Tamil"},
{ key : 2, label: "Telugu"},
{ key : 3, label: "Malayalam"},
{ key : 4, label: "Kannada"},
{ key : 5, label: "Others"}];

const CIVIC_STATUS = [{ key : 1, label: "Corporation"},
    { key : 2, label: "Municipality"},
    { key : 3, label: "Town Panchayat"},
    { key : 4, label: "Village Panchayat"}];

const RELIGION = [{ key : 1, label: "Hindu"},
    { key : 2, label: "Christian"},
    { key : 3, label: "Muslim"},
    { key : 4, label: "Sikhism"},
    { key : 5, label: "Jainism"},
    { key : 6, label: "Buddhism"},
    { key : 7, label: "Others"}];

const NATIONALITY = [{key:'IN',label:'Indian'},{key:'SLR',label:'Srilankan Tamil Refugee'},{key:'OTH',label:'Others'}];
const NATIVITY = [{key:'TN',label:'Tamil nadu'},{key:'OTH',label: 'Others'}];
const COMMUNITY=[{key:'OC',label:'OC'},{key:'BCM',label:'BCM'},{key:'BC',label:'BC'},{key:'MBC',label:'MBC & DNC'},{key:'SC',label:'SC'},{key:'SCA',label:'SCA'},{key:'ST',label:'ST'}];
const CASTE= {
    "ST":[{key:'1', label: "Adiyan"},
        {key:'2', label: "Aranadan"},
        {key:'3', label: "Eravallan"},
        {key:'4', label: "Irular"},
        {key:5, label: "Kadar"},
        {key:6, label: "Kammar (excluding Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:7, label: "Kanikaran, Kanikkar (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:8, label: "Kaniyan, Kanyan"},
        {key:9, label: "Kattunayakan"},
        {key:10, label: "Kochu Velan"},
        {key:11, label: "Konda Kapus"},
        {key:12, label: "Kondareddis"},
        {key:13, label: "Koraga"},
        {key:14, label: "Kota (excluding Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:15, label: "Kudiya, Melakudi"},
        {key:16, label: "Kurichchan"},
        {key:17, label: "Kurumbas (in the Nilgiris District)"},
        {key:18, label: "Kurumans"},
        {key:19, label: "Maha Malasar"},
        {key:20, label: "Malai Arayan"},
        {key:21, label: "Malai Pandaram"},
        {key:22, label: "Malai Vedan"},
        {key:23, label: "Malakkuravan"},
        {key:24, label: "Malasar"},
        {key:25, label: "Malayali (in Dharmapuri, North Arcot, Pudukkottai, Salem, South Arcot and Tiruchirapalli Districts)"},
        {key:26, label: "Malayakandi"},
        {key:27, label: "Mannan"},
        {key:28, label: "Mudugar, Mudvan"},
        {key:29, label: "Muthuvan"},
        {key:30, label: "Pallayan"},
        {key:31, label: "Palliyan"},
        {key:32, label: "Palliyar"},
        {key:33, label: "Paniyan"},
        {key:34, label: "Sholaga"},
        {key:35, label: "Toda( excluding Kanyakamari District and Shenlottah Taluk of Tirunelveli District)"},
        {key:36, label: "Uraly"}],
    "SC":[{key:37, label: "Adi Dravida"},
        {key:38, label: "Adi Karnataka"},
        {key:39, label: "Ajila"},
        {key:40, label: "Ayyanavar (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:41, label: "Baira"},
        {key:42, label: "Bakuda"},
        {key:43, label: "Bandi"},
        {key:44, label: "Bellara"},
        {key:45, label: "Bharatar (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:46, label: "Chalavadi"},
        {key:47, label: "Chamar, Muchi"},
        {key:48, label: "Chandala"},
        {key:49, label: "Cheruman"},
        {key:50, label: "Devendrakula Velalar (Devendrakulathan, Kadaiyan(excluding in the coastal areas of Tirunelveli, Thoothukudi,Ramanathapuram, pudukottai, Thanjavur, Tiruvarur and Nagapattinam districts), kalladi, Kudumban, Pallan, pannadi, Vathiriyan)"},
        {key:51, label: "Dom, Dombar, Paidi, Pano"},
        {key:52, label: "Domban"},
        {key:53, label: "Godagali"},
        {key:54, label: "Godda"},
        {key:55, label: "Gosargi"},
        {key:56, label: "Holeya"},
        {key:57, label: "Jaggali"},
        {key:58, label: "Jambuvulu"},
        {key:59, label: "Kadaiyan (in the districts of Tirunelveli, Thoothukudi, Ramanathapuram, Pudukottai, Thanjavur, Tiruvarur and Nagapattinam)"},
        {key:60, label: "Kakkalan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:61, label: "Kanakkan, Padanna (in the Nilgiris District)"},
        {key:62, label: "Karimpalan"},
        {key:63, label: "Kavara (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:64, label: "Koliyan"},
        {key:65, label: "Koosa"},
        {key:66, label: "Kootan, Koodan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:67, label: "Kuravan, Sidhanar"},
        {key:68, label: "Maila"},
        {key:69, label: "Mala"},
        {key:70, label: "Mannan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:71, label: "Mavilan"},
        {key:72, label: "Moger"},
        {key:73, label: "Mundala"},
        {key:74, label: "Nalakeyava"},
        {key:75, label: "Nayadi"},
        {key:76, label: "Padannan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:77, label: "Palluvan"},
        {key:78, label: "Pambada"},
        {key:79, label: "Panan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:80, label: "Panchama"},
        {key:81, label: "Panniandi"},
        {key:82, label: "Paraiyan, Parayan,Sambavar"},
        {key:83, label: "Paravan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:84, label: "Pathiyan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:85, label: "Pulayan,Cheramar"},
        {key:86, label: "PuthiraiVannan"},
        {key:87, label: "Raneyar"},
        {key:88, label: "Samagara"},
        {key:89, label: "Samban"},
        {key:90, label: "Sapari"},
        {key:91, label: "Semman"},
        {key:92, label: "Thandan (in Kanyakumari District and Shenkottah Taluk of TirunelveliDistrict)"},
        {key:93, label: "Tiruvalluvar"},
        {key:94, label: "Vallon"},
        {key:95, label: "Valluvan"},
        {key:96, label: "Vannan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:97, label: "Velan"},
        {key:98, label: "Vetan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"},
        {key:99, label: "Vettiyan"},
        {key:100, label: "Vettuvan (in Kanyakumari District and Shenkottah Taluk of Tirunelveli District)"}],
    "SCA" :[{key:101, label: "AdiAndhra"},
        {key:102, label: "Arunthathiyar"},
        {key:103, label: "Chakkiliyan"},
        {key:104, label: "Madari"},
        {key:105, label: "Madiga"},
        {key:106, label: "Pagadai"},
        {key:107, label: "Thoti"}],
     "MBC":[
        {key:111, label: "Andipandaram"},
        {key:136, label: "Eravallar (except in Kanniyakumari District and Shencottah Taluk of Tenkasi District where the community is a Scheduled Tribe)"},
        {key:142, label: "Isaivellalar"},
        {key:145, label: "Jangam"},
        {key:154, label: "Kongu Chettiar (in Coimbatore, Tiruppur and Erode Districts only)"},
        {key:158, label: "Kulala (including Kuyavar and Kumbarar)"},
        {key:159, label: "Kunnuvar Mannadi"},
        {key:160, label: "Kuruhini Chetty"},
        {key:161, label: "Kurumba, Kurumba Gounder"},
        {key:163, label: "Mahendra, Medara"},
        {key:165, label: "Maruthuvar, Navithar, Mangala, Velakattalavar, Velakatalanair and Pronopakari"},
        {key:170, label: "Moundadan Chetty"},
        {key:174, label: "Narikoravar (Kuruvikars)"},
        {key:180, label: "Panisaivan/Panisivan"},
        {key:181, label: "Pannayar (other than Kathikarar in Kanniyakumari District)"},
        {key:194, label: "Sathatha Srivaishnava (including Sathani, Chattadi and Chattada Srivaishnava)"},
        {key:198, label: "Sozhia Chetty"},
        {key:203, label: "Thondaman"},
        {key:204, label: "Thoraiyar (Nilgiris)"},
        {key:205, label: "Thoraiyar (Plains)"},
        {key:208, label: "Transgender/Eunuch (Thirunangai / Aravani)"},
        {key:215, label: "Vanniakula Kshatriya (including Vanniyar, Vanniya, Vannia Gounder, Gounder or Kander, Padayachi, Palli and Agnikula Kshatriya)"},
        {key:223, label: "Yogeeswarar"},
         {key:108, label: "Ambalakarar"},
         {key:109, label: "Ambalakarar (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:110, label: "Ambalakkarar (Suriyanur, Tiruchirapalli District)"},
         {key:112, label: "Appanad Kondayam kottai Maravar (Sivaganga, Virudhunagar, Ramanathapuram, Madurai, Theni and Dindigul Districts)"},
         {key:113, label: "Arayar (in Kanniyakumari District)"},
         {key:114, label: "Attur Kilnad Koravars (Salem, Namakkal, Cuddalore, Villupuram, Kallakurichi, Ramanathapuram, Sivaganga and Virudhunagar Districts)"},
         {key:115, label: "Attur Melnad Koravars (Salem and Namakkal Districts)"},
         {key:116, label: "Battu Turkas"},
         {key:117, label: "Bestha, Siviar"},
         {key:118, label: "Bhatraju (other than Kshatriya Raju)"},
         {key:119, label: "Boyar, Oddar"},
         {key:120, label: "Boyas (Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, The Nilgiris, Salem, Namakkal, Dharmapuri and Krishnagiri Districts)"},
         {key:121, label: "C.K. Koravars (Cuddalore, Villupuram and Kallakurichi Districts)"},
         {key:122, label: "Chakkala (Sivaganga, Virudhunagar, Ramanathapuram, Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Pudukottai, Tiruchirapalli, Karur, Perambalur, Ariyalur, Madurai, Theni, Dindigul and The Nilgiris Districts)"},
         {key:123, label: "Changyampudi Koravars (Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:124, label: "Chettinad Valayars (Sivaganga, Virudhunagar and Ramanathapuram Districts)"},
         {key:125, label: "Dabi Koravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:126, label: "Dasari"},
         {key:127, label: "Devagudi Talayaris"},
         {key:128, label: "Dobba Koravars (Salem and Namakkal Districts)"},
         {key:129, label: "Dobbai Korachas (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:130, label: "Dombs (Pudukottai,Tiruchirapalli, Karur, Perambalur and Ariyalur Districts)"},
         {key:131, label: "Dommara"},
         {key:132, label: "Dommars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Pudukottai, Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:133, label: "Donga Boya"},
         {key:134, label: "Donga Dasaris (Chengalpattu, Kancheepuram, Tiruvallur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, Chennai, Salem and Namakkal Districts)"},
         {key:135, label: "Donga Ur.Korachas"},
         {key:137, label: "Gandarvakottai Kallars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur and Pudukottai Districts)"},
         {key:138, label: "Gandarvakottai Koravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, Cuddalore, Villupuram and Kallakurichi Districts)"},
         {key:139, label: "Gorrela Dodda Boya"}, {key:140, label: "Gudu Dasaris"},
         {key:141, label: "Inji Koravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:143, label: "Jambavanodai"},
         {key:144, label: "Jambuvanodai"},
         {key:146, label: "Jogi"},
         {key:147, label: "Jogis (Chengalpattu, Kancheepuram, Tiruvallur, Chennai, Cuddalore, Villupuram, Kallakurichi, Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:148, label: "Kal Oddars (Chengalpattu, Kancheepuram, Tiruvallur, Ramanathapuram, Sivaganga, Virudhunagar, Madurai, Theni, Dindigul, Pudukottai, Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Tirunelveli, Tenkasi, Thoothukudi, Salem and Namakkal Districts)"},
         {key:149, label: "Kala Koravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:150, label: "Kaladis (Sivaganga, Virudhunagar, Ramanathapuram, Madurai, Theni, Dindigul, Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Pudukottai, Tiruchirapalli, Karur, Perambalur and Ariyalur Districts)"},
         {key:151, label: "Kalavathila Boyas"},
         {key:152, label: "Kalinji Dabikoravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur and Pudukottai Districts )"},
         {key:153, label: "Kepmaris (Chengalpattu, Kancheepuram, Tiruvallur, Pudukottai, Tiruchirapalli, Karur, Perambalur and Ariyalur Districts)"},
         {key:155, label: "Kootappal Kallars (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:156, label: "Koracha"},
         {key:157, label: "Koravars (Chengalpattu, Kancheepuram, Tiruvallur, Ramanathapuram, Sivaganga, Virudhunagar, Pudukottai, Thanjavur, Nagapattinam, Mayiladuthurai, Thiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Tirunelveli, Tenkasi, Thoothukudi, Chennai, Madurai, Theni, Dindigul and The Nilgiris Districts)"},
         {key:162, label: "Latin Catholic Christian Vannar (in Kanniyakumari District)"},
         {key:164, label: "Maravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Pudukottai, Ramanathapuram, Sivaganga, Virudhunagar, Tirunelveli, Tenkasi and Thoothukudi Districts)"},
         {key:166, label: "Meenavar (Parvatharajakulam, Pattanavar, Sembadavar) (including converts to Christianity)."},
         {key:167, label: "Mond Golla"},
         {key:168, label: "Monda Golla (Salem and Namakkal Districts)"},
         {key:169, label: "Monda Koravars"},
         {key:171, label: "Mukkuvar or Mukayar (including converts to Christianity)"},
         {key:172, label: "Mutlakampatti"},
         {key:173, label: "Mutlakampatti (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:175, label: "Nellorepet Oddars (Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:176, label: "Nokkar"},
         {key:177, label: "Nokkars (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:178, label: "Oddars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, Madurai, Theni and Dindigul Districts)"},
         {key:179, label: "Padayachi (Vellayan Kuppam in Cuddalore District and Tennore in Tiruchirapalli District)"},
         {key:182, label: "Paravar (except in Kanniyakumari District and Shencottah Taluk of Tenkasi District where the Community is a Scheduled Caste)"},
         {key:183, label: "Paravar converts to Christianity including the Paravar converts to Christianity of Kanniyakumari District and Shencottah Taluk of Tenkasi District."},
         {key:184, label: "Pedda Boyas (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:185, label: "Peria Suriyur Kallars (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:186, label: "Piramalai Kallars (Sivaganga, Virudhunagar, Ramanathapuram, Madurai, Theni, Dindigul, Pudukottai, Thanjavur, Nagapattinam, Mayiladuthurai and Tiruvarur Districts)"},
         {key:187, label: "Ponnai Koravars (Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:188, label: "Punnan Vettuva Gounder"},
         {key:189, label: "Punnan Vettuva Gounder (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:190, label: "Sakkaraithamadai Koravars (Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:191, label: "Salem Melnad Koravars (Madurai, Theni, Dindigul, Coimbatore, Tiruppur, Erode, Pudukottai, Tiruchirapalli, Karur, Perambalur, Ariyalur, Salem, Namakkal, Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:192, label: "Salem Uppu Koravars (Salem and Namakkal Districts)"},
         {key:193, label: "Saranga Palli Koravars"},
         {key:195, label: "Sembanad Maravars (Sivaganga, Virudunagar and Ramanathapuram Districts)"},
         {key:196, label: "Servai (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:197, label: "Sooramari Oddars (Salem and Namakkal Districts)"},
         {key:199, label: "Telugupatty Chetty"},
         {key:200, label: "Telungapattti Chettis (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:201, label: "Thalli Koravars (Salem and Namakkal Districts)"},
         {key:202, label: "Thogamalai Koravars or Kepmaris (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:206, label: "Thottia Naicker (including Rajakambalam, Gollavar, Sillavar, Thockalavar, Thozhuva Naicker and Erragollar)"},
         {key:207, label: "Thottia Naickers (Sivaganga, Virudunagar ,Ramanathapuram, Chengalpattu, Kancheepuram, Tiruvallur, Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, Tirunelveli, Tenkasi, Thoothukudi, Salem, Namakkal, Tirupattur, Ranipet, Vellore, Tiruvannamalai, Coimbatore, Tiruppur and Erode Districts)"},
         {key:209, label: "Uppukoravars or Settipalli Koravars (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur, Pudukottai, Madurai, Theni, Dindigul, Tirupattur, Ranipet, Vellore and Tiruvannamalai Districts)"},
         {key:210, label: "Urali Gounders (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:211, label: "Vaduvarpatti Koravars (Madurai, Theni, Dindigul, Ramanathapuram, Sivaganga, Virudunagar, Tirunelveli, Tenkasi, Thoothukudi, Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:212, label: "Valaiyar (including Chettinad Valayars)"},
         {key:213, label: "Valayars (Madurai, Theni, Dindigul, Tiruchirapalli, Karur, Perambalur, Ariyalur, Pudukottai, Erode, Coimbatore and Tiruppur Districts)"},
         {key:214, label: "Vannar (Salavai Thozhilalar) (including Agasa, Madivala, Ekali, Rajakula, Veluthadar and Rajaka) (except in Kanniyakumari District and Shencottah Taluk of Tenkasi District where the community is a Scheduled Caste)"},
         {key:216, label: "Varaganeri Koravars (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:217, label: "Vetta Koravars (Salem and Namakkal Districts)"},
         {key:218, label: "Vettaikarar"},
         {key:219, label: "Vettaikarar (Thanjavur, Nagapattinam, Mayiladuthurai, Tiruvarur and Pudukottai Districts)"},
         {key:220, label: "Vettuva Gounder"},
         {key:221, label: "Vettuva Gounder (Tiruchirapalli, Karur, Perambalur, Ariyalur and Pudukottai Districts)"},
         {key:222, label: "Wayalpad or Nawalpeta Korachas"}
     ],
    "BC":[{key:224, label: "Agamudayar including Thozhu or Thuluva Vellala"},
        {key:225, label: "Agaram Vellan Chettiar"},
        {key:226, label: "Alwar, Azhavar and Alavar (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District.)"},
        {key:227, label: "Servai(except Tiruchirapalli, Karur, Perambalur and Pudukottai Districts.)"},
        {key:228, label: "Nulayar(in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:229, label: "Archakarai Vellala"},
        {key:230, label: "Aryavathi(in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:231, label: "Ayira Vaisyar"},
        {key:232, label: "Badagar"},
        {key:233, label: "Billava"},
        {key:234, label: "Bondil"},
        {key:235, label: "Boyas (except Tiruchirapalli, Karur, Perambalur, Pudukottai, The Nilgiris, Salem , Namakkal Dharmapuri and Krishnagiri Districts). Pedda Boyar (except Tiruchirapalli, Karur ,Perambalur and Pudukottai Districts).Oddars (except Thanjavur, Nagapattinam, Tiruvarur, Tiruchirapalli, Karur, Perambalur, Pudukottai, Madurai,Theni and Dindigul Districts).Kaloddars (except Kancheepuram, Tiruvallur, Ramanathapuram, Sivaganga, Viruthunagar, Madurai, Theni, Dindigul, Purdukkottai, Tiruchirappalli, Karur, Perambalur, Tirunelveli, Thoothukudi, Salam and Namakkal Districts).Nellorepet oddars (except Vellore and Tiruvannamalai Districts) Sooramari oddars( except Salem and Namakkal)"},
        {key:236, label: "Chakkala (except Sivaganga, Virudhunagar, Ramanathapuram, Thanjavur ,Nagapattinam,Tiruvarur, Pudukottai, Tiruchirapalli, Karur, Perambalur, Madurai, Theni, Dindigul and the Nilgiris Districts)"},
        {key:237, label: "Chavalakarar (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:238, label: "Chettu or Chetty (including Kottar Chetty, Elur Chetty, Pathira Chetty, Valayal Chetty,Pudukadai Chetty ) ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:239, label: "Chowdry"},
        {key:240, label: "Converts to Christianity from Scheduled Castes irrespective of the generation of conversion for the purpose of reservation of seats in Educational Institutions and for seats in Public Services."},
        {key:241, label: "C.S.I formerly S.I.U.C (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:242, label: "Donga Dasaris ( except Kancheepuram, Tiruvallur, Trichirapalli, Karur, Parambalur, Pudukottai, Chennai, Salem and Namakkal Districts."},
        {key:243, label: "Devangar, Sedar"},
        {key:244, label: "Dombs (except Pudukottai, Tiruchirapalli ,Karur and Perambalur Districts). Dommars (except Thanjavur, Nagapattinam,Tiruvarur, Pudukottai,Vellore and Thiruvannamalai Districts)"},
        {key:245, label: "Enadi"},
        {key:246, label: "Ezhavathy(in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:247, label: "Ezhuthachar (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:248, label: "Ezhuva(in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:249, label: "Gangavar"},
        {key:250, label: "Gavara, Gavarai and Vadugar(Vaduvar)(other than Kamma, Kapu, Balija and Reddi)"},
        {key:251, label: "Gounder"},
        {key:252, label: "Gowda (including Gammala, Kalali and Anuppa Gounder)"},
        {key:253, label: "Hegde"},
        {key:254, label: "Idiga"},
        {key:255, label: "IllathuPillaimar, Illuvar, Ezhuvar and Illathar"},
        {key:256, label: "Jhetty"},
        {key:257, label: "Jogis (Except Kancheepuram, Tiruvallur, Madurai, Theni, Dindigul, Cuddalore, Villupuram, Vellore and Tiruvannamalai Districts)"},
        {key:258, label: "Kabbera"},
        {key:259, label: "Kaikolar, Sengunthar"},
        {key:260, label: "Kaladi (except Sivaganga, Virudhunagar, Ramanathapuram, Madurai, Theni, Dindigul,Thanjavur, Nagapattinam,Tiruvarur, Pudukottai, Tiruchirapalli, Karur and Perambalur Districts)"},
        {key:261, label: "Kalari Kurup including Kalari Panicker (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:262, label: "Kalingi"},
        {key:263, label: "Kallar , Easanattu Kallar.Gandharva Kottai Kallars(except Thanjavur, Nagapattinam, Tiruvarur and Pudukottai Districts). Kootappal Kallars-(except Pudukottai, Tiruchirapalli,Karur and Perambalur Districts).Piramalai Kallars- (except Sivaganga,Virudhunagar, Ramanathapuram. Madurai. Theni, Dindigul, Pudukottai, Thanjavur, pattinam and Tiruvarur Districts). Periyasooriyur Kallars- (except Tiruchirapalli, Karur, Perambalur and Pudukottai Districts)"},
        {key:264, label: "Kallar Kula Thondaman"},
        {key:265, label: "Kalveli Gounder"},
        {key:266, label: "Kambar"},
        {key:267, label: "Kammalar or Viswakarma , Viswakarmala (including Thattar, Porkollar, Kannar, Karumar, Kollar, Thacher, Kal Thacher, Kamsala and Viswa brahmin.)"},
        {key:268, label: "Kani, Kanisu, Kaniyar Panicker"},
        {key:269, label: "Kaniyala Vellalar"},
        {key:270, label: "Kannada Saineegar ,Kannadiyar (Throughout the State) and Dasapalanjika (Coimbatore, Erode and the Nilgiris Districts)"},
        {key:271, label: "Kannadiya Naidu"},
        {key:272, label: "Karpoora Chettiar"},
        {key:273, label: "Karuneegar (Seer Karuneegar, Sri Karuneegar, Sarattu Karuneegar, Kaikatti Karuneegar, Mathuvazhi Kanakkar, Sozhi Kanakkar, and Sunnambu Karuneegar)"},
        {key:274, label: "Kasukkara Chettiar"},
        {key:275, label: "Katesar, Pattamkatti"},
        {key:276, label: "Kavuthiyar"},
        {key:277, label: "Kerala Mudali"},
        {key:278, label: "Kharvi"},
        {key:279, label: "Khatri"},
        {key:280, label: "Kongu Vaishnava"},
        {key:281, label: "Kongu Vellalars( including Vellala Gounder, Nattu Gounder, Narambukkatti Gounder, Tirumudi Vellalar, Thondu Vellalar, Pala Gounder, Poosari Gounder, Anuppa Vellala Gounder, Padaithalai Gounder, Chendalai Gounder, Pavalankatti Vellala Gounder,Palavellala Gounder, Sanku Vellala Gounder and Rathinagiri Gounder)."},
        {key:282, label: "KoppalaVelama"},
        {key:283, label: "Koteyar"},
        {key:284, label: "Krishnanvaka (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:285, label: "Kudikara Vellalar"},
        {key:286, label: "Kudumbi ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:287, label: "Kuga Vellalar"},
        {key:288, label: "Kunchidigar"},
        {key:289, label: "Latin Catholics except Latin Catholic Vannar in Kanniyakumari District."},
        {key:290, label: "Lathin Catholics in Shencottah Taluk of Tirunelveli District"},
        {key:291, label: "Lambadi"},
        {key:292, label: "Lingayat (Jangama)"},
        {key:293, label: "Mahratta (Non-Brahmin) (including Namdev Mahratta)"},
        {key:294, label: "Malayar"},
        {key:295, label: "Male"},
        {key:296, label: "Maniagar"},
        {key:297, label: "Maravars (except Thanjavur, Nagapattinum , Tiruvarur, Pudukottai, Ramanathapuram, Sivaganga, Virudhunagar, Tirunelveli and Toothukudi Districts). Karumaravars. Appanad Kondayam kottai Maravar –(except Sivaganga, Virudhunagar, Ramanathapuram, Madurai, Theni and Dindigul Districts.). Sembanad Maravars- (except Sivaganga, Virudhunagar, and Ramanathapuram Districts)"},
        {key:298, label: "Moondrumandai Enbathunalu (84) Ur. Sozhia Vellalar"},
        {key:299, label: "Mooppan"},
        {key:300, label: "Muthuraja, Muthuracha, Muttiriyar, Mutharaiyar"},
        {key:301, label: "Nadar, Shanar and Gramani"},
        {key:302, label: "Nagaram"},
        {key:303, label: "Naikkar (in Kanniyakumari District and Shencottah Taluk of Tirunelveli District)"},
        {key:304, label: "Nangudi Vellalar"},
        {key:305, label: "Nanjil Mudali ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:306, label: "Odar ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:307, label: "Odiya"},
        {key:308, label: "Oottruvalanattu Vellalar"},
        {key:309, label: "O.P.S. Vellalar"},
        {key:310, label: "Ovachar"},
        {key:311, label: "Paiyur Kotta Vellalar"},
        {key:312, label: "Pamulu"},
        {key:313, label: "Panar ( except in Kanniyakumari District and Shencottah Taluk of Tirunelveli District where the community is a Scheduled Caste )"},
        {key:314, label: "Pandiya Vellalar"},
        {key:315, label: "Kathikarar in Kanniyakumari District"},
        {key:316, label: "Pannirandam Chettiar or Uthama Chettiar"},
        {key:317, label: "Parkavakulam ( including Surithimar, Nathamar, Malayamar, Moopanar and Nainar )"},
        {key:318, label: "Perike ( including Perike Balija )"},
        {key:319, label: "Perumkollar ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:320, label: "Podikara Vellalar"},
        {key:321, label: "Pooluva Gounder"},
        {key:322, label: "Poraya"},
        {key:323, label: "Pulavar ( in Coimbatore and Erode Districts )"},
        {key:324, label: "Pulluvar or Pooluvar"},
        {key:325, label: "Pusala"},
        {key:326, label: "Reddy ( Ganjam )"},
        {key:327, label: "Sadhu Chetty ( including Telugu Chetty, Twenty four Manai Telugu Chetty)"},
        {key:328, label: "Sakkaravar or Kavathi ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:329, label: "Salivagana"},
        {key:330, label: "Saliyar, Padmasaliyar, Pattusaliyar, Pattariyar, and Adhaviyar"},
        {key:331, label: "Savalakkarar"},
        {key:332, label: "Senaithalaivar, Senaikudiyar and Illaivaniar"},
        {key:333, label: "Serakula Vellalar"},
        {key:334, label: "Sourashtra ( Patnulkarar )"},
        {key:335, label: "Sozhiavellalar ( including Sozha Vellalar, Vetrilaikarar, Kodikalkarar and Keeraikarar )"},
        {key:336, label: "Srisayar"},
        {key:337, label: "Sundaram Chetty"},
        {key:338, label: "Thogatta Veerakshatriya"},
        {key:339, label: "Tholkollar ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:340, label: "Tholuva Naicker and Vetalakara Naicker"},
        {key:341, label: "Thoraiyar"},
        {key:'342', label: "Thoriyar"},
        {key:'343', label: "Ukkirakula Kshatriya Naicker"},
        {key:'344', label: "Uppara, Uppillia and Sagara"},
        {key:'345', label: "Urali Gounder ( except Tiruchirapalli, Karur , Perambalur and Pudukottai District) and Orudaya Gounder or Oorudaya Gounder (in Madurai ,Theni, Dindigul, Coimbatore, Erode, Tiruchirapalli, Karur , Perambalur, Pudukottai, Salem and Namakkal Districts )"},
        {key:'346', label: "Urikkara Nayakkar"},
        {key:'347', label: "Virakodi Vellala"},
        {key:'348', label: "Vallambar"},
        {key:'349', label: "Vallanattu Chettiar"},
        {key:'350', label: "Valmiki"},
        {key:'351', label: "Vaniyar, Vania Chettiar ( including Gandla, Ganika, Telikula and Chekkalar)"},
        {key:'352', label: "Veduvar and Vedar ( except in Kanniyakumari District and Shencottah Taluk of Tirunelveli District where the community is aScheduled Castes)"},
        {key:'353', label: "Veerasaiva ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:'354', label: "Velar"},
        {key:'355', label: "Vellan Chettiar"},
        {key:'356', label: "Veluthodathu Nair ( in Kanniyakumari District and Shencottah Taluk of Tirunelveli District )"},
        {key:'357', label: "Vokkaligar ( including Vakkaligar, Okkaligar, Kappiliyar, Kappiliya, Okkaliga Gowda, Okkaliya- Gowda, Okkaliya- Gowder, Okkaliya Gowda )"},
        {key:'358', label: "Wynad Chetty ( The Nilgiris District )"},
        {key:'359', label: "Yadhava ( including Idaiyar, Telugu Speaking Idaiyar known as Vaduga Ayar or Vaduga Idaiyar or Golla and Asthanthra Golla )"},
        {key:'360', label: "Yavana"},
        {key:'361', label: "Yerukula"},
        {key:'362', label: "Orphans and destitute children who have lost their parents before reaching the age of ten and are destitutes; and who have nobody else to take care of them either by law or custom; and also who are admitted into any of the schools or orphanages run by the Government or recognized by the Government."},
        {key:'370', label:'Thiyya'},
        {key: '371', label: "Converts to Christianity from any Hindu Backward Classes Community or Most Backward Classes Community or Denotified Communities (except the Converts to Christianity from Meenavar, Parvatharajakulam, Pattanavar, Sembadavar, Mukkuvar or Mukayar and Paravar)"}
    ],
    "BCM":[{key:'363', label: "Ansar "},
        {key:'364', label: "Dekkani Muslims "},
        {key:'365', label: "Dudekula"},
        {key:'366', label: "Labbais including Rowthar and Marakayar (whether their spoken language is Tamil or Urdu)"},
        {key:'367', label: "Mapilla"},
        {key:'368', label: "Sheik "},
        {key:'369', label: "Syed "}],
    "OC":[{ key:'500' , label:"Others"}]
};
const GENDER = [
    {
        key: 'M',
        label : 'Male'
    },
    {
        key:'F',
        label:'Female'
    },
   {
        key:'T',
        label:'Third Gender'
    }
];

const STEPS = {
    REGISTER:'register',
    PERSONAL_INFO:'personalInfo',
    SPL_RESERVE:'splReserve',
    SCHOLARSHIP:'scholarship',
    SCHOOL_DETAILS:'schooldDetails',
    ACADEMIC:'academic',
    PAYMENT:'payment',
    PREVIEW:'preview',
    REPORT:'report'
};

const PAYMENT_STATUS = {
    SUCCESS: 1,
    DEFAULT: 0,
    FAILED: 2,
    PENDING: 3,
    INITIATED: 4
};

const DIFF_ABLED_TYPES = [{key : 1, label: "Visually Impaired"},
    {key : 2, label: "Hearing Impaired"},
    {key : 3, label: "Locomotor disability"},
    {key : 4, label: "Autism / Intellectual disability / Specific learning disability / Mental illness"},
    {key : 5, label: "Multiple Disability"}
];

const TFC_CENTER =  [{key:"39", district:"Ariyalur", dist_no:"1", label:" Govt. Polytechnic College, Kilapalur Village, Ariyalur – 621 707."},
    {key:"53", district:"Ariyalur", dist_no:"1", label:"University College Of Engineering, Ariyalur, Kathankudikadu Village, Thelur Post, Ariyalur - 621704"},
    {key:"7", district:"Chengalpattu", dist_no:"2", label:" IRT Polytechnic College, Bharathamadha Street, Bharathipuram, Chromepet, Pallavaram (Tk),Chengalpet   – 600 044"},
    {key:"54", district:"Chengalpattu", dist_no:"2", label:"University Departments Of Anna University, Chennai - Mit Campus, Chrompet, Tambaram Taluk, Chengalpattu - 600 044"},
    {key:"1", district:"Chennai", dist_no:"3", label:"Central Polytechnic College, CIT Campus Tharamani, Chennai – 600 113. (Integerated Workshop) - 1"},
    {key:"2", district:"Chennai", dist_no:"3", label:"Central Polytechnic College, CIT Campus Tharamani, Chennai – 600 113. (Integerated Workshop) - 2"},
    {key:"3", district:"Chennai", dist_no:"3", label:"Institute Of Chemical Technology, Chennai- 600  113."},
    {key:"4", district:"Chennai", dist_no:"3", label:"Central Polytechnic College, CIT Campus Tharamani, Chennai – 600 113. (Auditorium)"},
    {key:"5", district:"Chennai", dist_no:"3", label:" Government Polytechnic College RK Nagar Tondiarpet , Chennai-600 081"},
    {key:"6", district:"Chennai", dist_no:"3", label:" Government Polytechnic College Purasawalkkam Chennai-600 012"},
    {key:"55", district:"Chennai", dist_no:"3", label:"Institute Of  Textile  Technology, Chennai-600 113."},
    {key:"56", district:"Chennai", dist_no:"3", label:"Institute Of Printing  Technology,Chennai -600  113."},
    {key:"57", district:"Chennai", dist_no:"3", label:"State  Institute  Of  Commerce Education,Chennai – 600 113."},
    {key:"58", district:"Chennai", dist_no:"3", label:"University Departments Of Anna University, Chennai - CEG Campus, Sardar Patel Road, Guindy, Chennai 600 025"},
    {key:"102", district:"Chennai", dist_no:"3", label:"Dr.Dharmambal Government Polytechnic College for Women, Tharamani, Chennai - 600 113"},
    {key:"22", district:"Coimbatore", dist_no:"4", label:"Government  College Of Technology,(GCT) , Thadagam Road Coimbatore-641 013"},
    {key:"23", district:"Coimbatore", dist_no:"4", label:"Government  Polytechnic College For Women, Bharathiyar Road, New Siddhapudur (Po), Coimbatore – 641 044."},
    {key:"24", district:"Coimbatore", dist_no:"4", label:"Coimbatore Institute Of Technology,   Avinashi Road,Coimbatore -641 014"},
    {key:"60", district:"Coimbatore", dist_no:"4", label:"Government Polytechnic College,Coimbatore – 641 014."},
    {key:"61", district:"Coimbatore", dist_no:"4", label:"PSG College Of Technology, Peelamedu,Coimbatore – 641 004"},
    {key:"62", district:"Coimbatore", dist_no:"4", label:"Anna University Regional Campus, Coimbatore, Maruthamalai Main Road, Navavoor, Coimbatore - 641046"},
    {key:"16", district:"Cuddalore", dist_no:"5", label:"Thiru  Kolanjiappar Government Arts College. Virudhachalam, Cuddalore  – 606 001."},
    {key:"17", district:"Cuddalore", dist_no:"5", label:" Faculty Of Engineering And Technology, Annamalai University, Chidambaram, Cuddalore  -608 001. "},
    {key:"63", district:"Cuddalore", dist_no:"5", label:"Government Polytechnic College,Kooduveli, Cuddalore - 608305."},
    {key:"64", district:"Cuddalore", dist_no:"5", label:"University College Of Engineering, Panruti, Chennai-Kumbakonam Highway, Panikkankuppam, Panruti, Cuddalore - 607106"},
    {key:"28", district:"Dharmapuri", dist_no:"6", label:"Government College Of Engineering, Chettikarai P.O. Dharmapuri - 636 704."},
    {key:"65", district:"Dharmapuri", dist_no:"6", label:"Government Polytechnic College,Dharmapuri – 635 205."},
    {key:"66", district:"Dharmapuri", dist_no:"6", label:"Government Polytechnic College,Kadathur,Dharmapuri   - 635303."},
    {key:"67", district:"Dharmapuri", dist_no:"6", label:"Government Polytechnic College,Palacode, D D.Sugar Mills Campus, Dharmapuri – 636 808."},
    {key:"34", district:"Dindigul", dist_no:"7", label:" G.T.N. Arts College(Autonomous)Old Karur Road,Dindigul- 624 005."},
    {key:"68", district:"Dindigul", dist_no:"7", label:"University College Of Engineering, Dindigul, Mangarai Pirivu, Reddiyarchathiram, Dindigul  624622"},
    {key:"69", district:"Dindigul", dist_no:"7", label:"Arulmigu Palaniandavar Polytechnic College, Dindigul Road, Palani,  Dindigul - 624601"},
    {key:"20", district:"Erode", dist_no:"8", label:"Gopi  Arts And Science College, (Autonomous) Gopichetipalayam, Erode -638 453"},
    {key:"21", district:"Erode", dist_no:"8", label:" Government Polytechnic College, Perundurai, Erode  - 638 053"},
    {key:"70", district:"Erode", dist_no:"8", label:"Government College Of Engineering (IRTT),Erode - 638 316"},
    {key:"71", district:"Erode", dist_no:"8", label:"Sakthi Polytechnic College,Nachimuthupuram, Sakthinagar, Anthiyur (T.K), Erode - 638 315"},
    {key:"15", district:"Kallakurichi", dist_no:"9", label:"Government Polytechnic College, Sankarapuram, Kattuvannanjur Village,Kallakurichi – 606 401  "},
    {key:"8", district:"Kancheepuram", dist_no:"10", label:"Bakthavatsalam Polytechnic College,Karaipettai, Kanchipuram"},
    {key:"72", district:"Kancheepuram", dist_no:"10", label:"University College Of Engineering, Kancheepuram, Ponnerikarai Campus,  Karaipettai Village & Post, Kancheepuram  631552"},
    {key:"51", district:"Kanniyakumari", dist_no:"11", label:"S.T.Hindu College, Desi Vinayagar Nagar, Kottar, Nagercoil - 629 002"},
    {key:"83", district:"Kanniyakumari", dist_no:"11", label:"Government Polytechnic College,Nagercoil – 629 004."},
    {key:"110", district:"Kanniyakumari", dist_no:"11", label:"University College Of Engineering, Nagercoil, Kanyakumari-629 004"},
    {key:"30", district:"Karur", dist_no:"12", label:"Government Arts College, Thanthonimalai, Karur - 639 007."},
    {key:"73", district:"Karur", dist_no:"12", label:"Government Polytechnic College,Karur - 621 301."},
    {key:"29", district:"Krishnagiri", dist_no:"13", label:"Govt. College Of Engineering,  Bargur,Krishnagiri  - 635 104"},
    {key:"74", district:"Krishnagiri", dist_no:"13", label:"Government Polytechnic College,Krishnagiri –  635 001."},
    {key:"75", district:"Krishnagiri", dist_no:"13", label:"Government Polytechnic College,Uthangarai, Krishnagiri - 635 207."},
    {key:"76", district:"Krishnagiri", dist_no:"13", label:"Government Polytechnic College,Kelamangalam, Krishnagiri – 635 113."},
    {key:"31", district:"Madurai", dist_no:"14", label:" Tamil Nadu Polytechnic College, Tpk Road, Jaihindpuram, Madurai  – 625 011."},
    {key:"32", district:"Madurai", dist_no:"14", label:"Thiagarajar College Of Engineering Thiruparankundram, Madurai - 625 015. "},
    {key:"77", district:"Madurai", dist_no:"14", label:"Government Polytechnic College  For Women,Madurai – 625 011."},
    {key:"78", district:"Madurai", dist_no:"14", label:"Government Polytechnic College,Melur -625 109."},
    {key:"79", district:"Madurai", dist_no:"14", label:"Government Polytechnic College,Usilampatti – 625 537."},
    {key:"80", district:"Madurai", dist_no:"14", label:"Government Polytechnic College,Chekkanurani  – 625  514."},
    {key:"81", district:"Madurai", dist_no:"14", label:"Anna University Regional Campus - Madurai, Kanyakumari National Highway, Keelakuilkudi, Madurai  625019"},
    {key:"52", district:"Mayiladuthurai", dist_no:"15", label:"Srinivasa Subbaraya Polytechnic College,  Puttur, Sirkali - 609 108"},
    {key:"36", district:"Nagapattinam", dist_no:"16", label:"Valivalam Desikar Polytechnic College, Palpannaicherry, Collectroate (Po), Nagapattinam – 611 001."},
    {key:"82", district:"Nagapattinam", dist_no:"16", label:"University College Of Engineering, Thirukkuvalai, Nagappattinam - 610204"},
    {key:"27", district:"Namakkal", dist_no:"17", label:"N.K.R Government  Arts College For Women, Namakkal  – 637 001."},
    {key:"84", district:"Namakkal", dist_no:"17", label:"Government Polytechnic College, Mohanur, Namakkal - 637 015."},
    {key:"40", district:"Perambalur", dist_no:"18", label:"Government Polytechnic College Keelaknavai, Perambalur - 621 104."},
    {key:"43", district:"Pudukkottai", dist_no:"19", label:"Government Polytechnic College Aranthangi, Pudukkottai - 614 616. "},
    {key:"86", district:"Pudukkottai", dist_no:"19", label:"Government Polytechnic College,Kandarvakottai – 613 301."},
    {key:"33", district:"Ramanathapuram", dist_no:"20", label:"Government Arts College, Paramakudi, Ramanathapuram-623 701"},
    {key:"87", district:"Ramanathapuram", dist_no:"20", label:"University College Of Engineering, Ramanathapuram, Pullangudi, Ramanathapuram - 623513"},
    {key:"11", district:"Ranipet", dist_no:"21", label:"Arignar Anna Government Arts Collage For Women Wallajapet, Ranipet -632 513  "},
    {key:"19", district:"Salem", dist_no:"22", label:"Government  College Of Engineering , Karuppur, Omalur, Salem  - 636 011"},
    {key:"88", district:"Salem", dist_no:"22", label:"Government Polytechnic College,Vanavasi, Salem – 636 457."},
    {key:"89", district:"Salem", dist_no:"22", label:"Thiagarajar Polytechnic College, Salem-636005"},
    {key:"45", district:"Sivagangai", dist_no:"23", label:"A.C. College Of Engineering And  Technology, Karaikudi, Sivagangai - 630 004"},
    {key:"90", district:"Sivagangai", dist_no:"23", label:"Alagappa Government  Polytechnic College,Karaikudi – 630 004."},
    {key:"91", district:"Sivagangai", dist_no:"23", label:"Annamalai Polytechnic College, Chettinad, Sivagangai-630102"},
    {key:"47", district:"Tenkasi", dist_no:"24", label:"Government Arts and Science College, Kadayanallur-627751"},
    {key:"59", district:"Tenkasi", dist_no:"24", label:"Kamarajar Government Arts College, Surandai, Tenkasi - 627 859"},
    {key:"37", district:"Thanjavur", dist_no:"25", label:"Government Polytechnic College, Reghunathapuram, Opposite To 108 Sivalaym, Papanasam (Tk), Thanjavur – 614 205. "},
    {key:"38", district:"Thanjavur", dist_no:"25", label:"Govt. College of Engineering Sengipatti, Thanjavur - 613 402"},
    {key:"92", district:"Thanjavur", dist_no:"25", label:"University College of Engineering, Pattukkottai, ECR Road, Rajamadam, Pattukkottai Taluk, Thanjavur - 614701"},
    {key:"26", district:"The Nilgiris", dist_no:"26", label:" Government  Arts College, Udhagamandalam -643 002"},
    {key:"85", district:"The Nilgiris", dist_no:"26", label:"Government Polytechnic College, Uthagamandalam– 643 006."},
    {key:"35", district:"Theni", dist_no:"27", label:" Government College of Engineering, Bodinayakanoor, Theni - 625 582."},
    {key:"93", district:"Theni", dist_no:"27", label:"Government Polytechnic College,Theni – 625 534."},
    {key:"94", district:"Theni", dist_no:"27", label:"Government Polytechnic College,Tekkampatti, Aundipatti – 625 517."},
    {key:"9", district:"Tiruvallur", dist_no:"33", label:"Murugappa Polytechnic College, Avadi, Chennai, Thiruvallur - 600 062. "},
    {key:"10", district:"Tiruvallur", dist_no:"33", label:"Loganatha Narayanasami Government Collage (Autonomous),Ponneri Thiruvallur-601 204 "},
    {key:"96", district:"Tiruvallur", dist_no:"33", label:"Government Polytechnic College,Sevapettai,  Thiruvallur."},
    {key:"97", district:"Tiruvallur", dist_no:"33", label:"AMK Technological Polytechnic College,Chennai-Bangalore High Road, Sembarambakkam-600123"},
    {key:"14", district:"Tiruvannamalai", dist_no:"34", label:"Govt. Polytechnic College, Cheyyar, Thiruvannamalai – 606 705 "},
    {key:"98", district:"Tiruvannamalai", dist_no:"34", label:"University College Of Engineering, Arni, Arni To Devikapuram Road, Thatchur, Arni, Thiruvannamalai - 632326 "},
    {key:"44", district:"Tiruvarur", dist_no:"35", label:"Govt. Polytechnic College, Valangaiman, Tiruvarur – 612 804."},
    {key:"99", district:"Tiruvarur", dist_no:"35", label:"Government Polytechnic College,Korkai, Tiruvarur  –  614  713."},
    {key:"48", district:"Tirunelveli", dist_no:"30", label:" Government College Of Engg., Tirunelveli – 627007"},
    {key:"49", district:"Tirunelveli", dist_no:"30", label:" Rani Anna Government College For Women, Gandhi Nagar, Tirunelveli – 627008."},
    {key:"95", district:"Tirunelveli", dist_no:"30", label:"Anna University Regional Campus - Tirunelveli, Trivandrum Road, Palayamkottai, Tirunelveli - 627007"},
    {key:"13", district:"Tirupathur", dist_no:"31", label:"Government Polytechnic College Jolarpet, Tirupathur -635 651  "},
    {key:"25", district:"Tiruppur", dist_no:"32", label:"Chikkanna Government Arts College, College Road, Tiruppur - 641 602"},
    {key:"41", district:"Tiruchirappalli", dist_no:"29", label:"Govt. Polytechnic College, Thuvakudimalai, Trichy – 620 022. "},
    {key:"42", district:"Tiruchirappalli", dist_no:"29", label:"Government College Of Engineering, Srirangam, Thiruchirappalli - 620012."},
    {key:"100", district:"Tiruchirappalli", dist_no:"29", label:"Government Polytechnic College, Sethurapatti, Srirangam Taluk, Tiruchirappalli-620 012."},
    {key:"101", district:"Tiruchirappalli", dist_no:"29", label:"University College Of Engineering, Tiruchirappalli, (Bharathidasan Institute Of Technology), Tiruchirappalli - 620024"},
    {key:"50", district:"Thoothukkudi", dist_no:"28", label:"Govt. Polytechnic College,Palayamkottai Road, Thoothukudi – 628008."},
    {key:"103", district:"Thoothukkudi", dist_no:"28", label:"Bharathiyar Centenary Memorial Women’s Polytechnic College, Ettayapuram – 628902."},
    {key:"104", district:"Thoothukkudi", dist_no:"28", label:"University VOC College of Engineering, Briyant Nagar, 7th street west, Thoothukudi 628008"},
    {key:"12", district:"Vellore", dist_no:"36", label:"Thanthai Periyar Government Institute Of Technology, Bagayam,Vellore – 632 002. "},
    {key:"105", district:"Vellore", dist_no:"36", label:"Thanthaiperiyar EVR Government Polytechnic College,Vellore  – 632  002."},
    {key:"18", district:"Villupuram", dist_no:"37", label:"Government Polytechnic College, No.27, Kandachipuram Main Road, Arakandanallur (Po), Tirukoilur (Tk), Villupuram – 605 752."},
    {key:"106", district:"Villupuram", dist_no:"37", label:"University College Of Engineering, Tindivanam, Melpakkam, Tindivanam, Villupuram - 604001 "},
    {key:"107", district:"Villupuram", dist_no:"37", label:"University College Of Engineering, Villupuram, Kakuppam, Villupuram - 605103 "},
    {key:"46", district:"Virudhunagar", dist_no:"38", label:"V.V. Vanniya Perumal College For Women, Virudhunagar – 626 001"},
    {key:"108", district:"Virudhunagar", dist_no:"38", label:"PAC Ramasamy Raja's Polytechnic College,Rajapalayam - 626 108"},
    {key:"109", district:"Virudhunagar", dist_no:"38", label:"Ayya Nadar Janakiammal Polytechnic College,Sivakasi - 626 189"}];

const YES_NO_ARRAY = [{label:'Yes',key:'Y'},{label:'No', key:'N'}];

const PARENT_OCCUPATION = [{key: 1, label: "Agriculture"},
    {key: 2, label: "Central Govt Employee"},
    {key: 3, label: "State Govt Employee"},
    {key: 4, label: "Professionals"},
    {key: 5, label: "Business"},
    {key: 6, label: "Self Employed"},
    {key: 7, label: "Others"}
];

const ANNUAL_INCOME = [{key: 1, label: "Less than 1 Lakh"},
    {key: 2, label: "1 lakh to 2.5 Lakh"},
    {key: 3, label: "2.5 Lakh to 6 Lakh"},
   {key: 4, label: "More than 6 Lakh"}
];

const HTTP_HEADERS = {
    'REQUEST_ID':'X-RequestID',
    'DEBUG_ID': 'X-Debug-ID'
};

const YEAR_OF_PASSING = [];
const currYear = new Date().getFullYear();

for(let i=2000;i<=2022;i++){
    const v = String(i);
    YEAR_OF_PASSING.push({key:v,label:v});
}

const QUALIFYING_EXAM = [ {key: '1', label: "HSC"},
    {key:'2', label: "CBSE"},
    {key: '3', label:"ICSE"},
    {key: '4', label: "Others"}];

const EXAM_BOARDS = [{key : '1', label:"Tamil nadu Board of Higher Secondary Education"},
    {key : '2', label:"Central Board of Secondary Education"},
    {key : '3', label:"Council for Indian School Certificate Examinations"},
    {key: '27', label:"National Institute of Open Schooling (NIOS)"},
    {key : '4', label:"Andhra Pradesh Board of Intermediate Education"},
    {key : '5', label:"Assam Higher Secondary Education Council"},
    {key : '6', label:"Bihar Intermediate Education Council"},
    {key : '7', label:"Goa Board of Secondary & Higher Secondary Education"},
    {key : '8', label:"Gujarat Secondary & Higher Education Board"},
    {key : '9', label:"Haryana Board of Education"},
    {key : '10', label:"Himachal Pradesh Board of School Education"},
    {key : '11', label:"J & K State Board of School Education"},
    {key : '12', label:"Karnataka Board of the Pre-University Education"},
    {key : '13', label:"Kerala Board of Higher Secondary Examinations"},
    {key : '14', label:"Maharashtra State Board of Secondary and Higher Secondary Education"},
    {key : '15', label:"Madhya Pradesh Board of Secondary Education"},
    {key : '16', label:"Manipur Council of Higher Secondary Education"},
    {key : '17', label:"Meghalaya Board of School Education"},
    {key : '18', label:"Mizoram Board of School Education"},
    {key : '19', label:"Nagaland Board of School Education"},
    {key : '20', label:"Orissa Council of Higher Secondary Education"},
    {key : '21', label:"Punjab School Education Board"},
    {key : '22', label:"Rajasthan Board of Secondary Education"},
    {key : '28', label:"Telangana Board of Secondary Education"},
    {key : '23', label:"Tripura Board of Secondary Education"},
    {key : '24', label:"U.P. Board of High School & Intermediate Education"},
    {key : '25', label:"West Bengal Council of Higher Secondary Education"},
    {key : '26', label:"Other Boards if any"}];

const MONTHS = [{key:1,label:'January'},{key:2,label:'February'},{key:3,label:'March'},{key:4,label:'April'},{key:5,label:'May'},{key:6,label:'June'},
    {key:7,label:'July'},{key:8,label:'August'},{key:9,label:'September'},{key:10,label:'October'},{key:11,label:'November'},{key:12,label:'December'}];

const MAX_MARKS = [{key:100,label:'100'},{key:150,label:'150'},{key:200,label:'200'},{key:300,label: '300'},{key:400,label:'400'}];

const HSC_GROUPS = [{key:1,label:'HSC Academic'},{key:2,label:'HSC Vocational'}];

const MEDIUM_OF_INSTRUCTION= [{key:1,label:'Tamil'},{key:2,label:'English'},{key:3,label:'Others'}];

const PAYMENT_GATEWAY= [{label:'Canara Bank',key:'CANARA',icon:'canara'},{label:'Indian Bank',key:'IB',icon:'ib'},
    {label:'Indian Overseas Bank',key:'IOB',icon:'iob'},{label:'HDFC Bank',key:'HDFC',icon:'hdfc'}
    /*{label:'Pay by Demand Draft', key:'dd',icon:'dd'}*/];

const COLLEGE_DIST_MAP = {"1":"3","2":"3","4":"3","5":"5","1013":"36","1014":"36","1015":"33","1026":"10","1101":"32","1106":"32","1107":"32","1110":"32",
    "1112":"32","1113":"32","1114":"32","1115":"32","1116":"32","1118":"32","1120":"32","1121":"32","1122":"32","1123":"32","1124":"32","1125":"32","1126":"32",
    "1127":"32","1128":"32","1131":"32","1133":"2","1137":"35","1140":"10","1141":"32","1143":"32","1149":"2","1150":"32","1201":"10","1202":"10","1205":"10",
    "1206":"10","1207":"10","1209":"10","1210":"32","1211":"10","1212":"10","1213":"32","1214":"10","1216":"10","1217":"32","1218":"10","1219":"10","1221":"32",
    "1222":"10","1225":"10","1226":"10","1228":"32","1229":"32","1230":"10","1231":"32","1232":"2","1233":"10","1234":"2","1235":"10","1237":"32","1238":"32",
    "1241":"32","1243":"2","1301":"2","1303":"2","1304":"32","1306":"2","1307":"2","1309":"3","1310":"2","1311":"3","1313":"3","1315":"2","1316":"2","1317":"2",
    "1319":"3","1320":"2","1321":"3","1322":"2","1323":"2","1324":"10","1325":"10","1333":"2","1334":"2","1335":"10","1399":"2","1401":"2","1402":"9","1405":"2",
    "1406":"36","1407":"2","1408":"36","1409":"2","1411":"2","1412":"36","1413":"10","1414":"2","1415":"9","1416":"32","1417":"2","1419":"10","1420":"2","1421":"9",
    "1422":"2","1424":"10","1426":"2","1427":"10","1428":"36","1430":"9","1431":"10","1432":"32","1434":"36","1435":"2","1436":"36","1437":"10","1438":"35","1439":"33",
    "1441":"9","1442":"2","1444":"2","1445":"36","1447":"3","1449":"36","1450":"3","1452":"2","1501":"35","1503":"33","1504":"33","1505":"35","1506":"35","1507":"35",
    "1508":"33","1509":"3","1510":"27","1511":"35","1512":"33","1513":"33","1514":"27","1515":"35","1516":"35","1517":"10","1518":"33","1519":"27","1520":"35",
    "1521":"35","1523":"35","1524":"33","1525":"27","1526":"35","1529":"33","1605":"9","2005":"4","2006":"4","2007":"4","2025":"4","2302":"21","2314":"16",
    "2327":"12","2328":"16","2329":"4","2332":"8","2338":"4","2341":"21","2342":"4","2343":"21","2345":"21","2346":"21","2347":"21","2349":"4","2350":"31",
    "2354":"4","2355":"12","2356":"12","2357":"4","2358":"31","2360":"4","2367":"4","2369":"6","2377":"4","2601":"13","2602":"16","2603":"13","2606":"6",
    "2607":"16","2608":"12","2609":"16","2610":"16","2611":"16","2612":"16","2613":"16","2614":"16","2615":"21","2616":"6","2617":"16","2618":"21","2620":"16",
    "2621":"13","2622":"12","2623":"21","2624":"16","2625":"21","2627":"16","2628":"16","2629":"16","2630":"12","2631":"16","2632":"16","2633":"16","2634":"16",
    "2635":"16","2636":"21","2638":"16","2639":"21","2640":"6","2641":"6","2642":"13","2643":"21","2646":"21","2647":"16","2648":"21","2649":"12","2650":"4",
    "2651":"31","2652":"8","2653":"21","2656":"31","2657":"16","2658":"21","2659":"21","2660":"16","2661":"16","2662":"16","2664":"16","2665":"16","2666":"21",
    "2673":"4","2682":"31","2683":"6","2702":"8","2704":"4","2705":"21","2706":"4","2707":"8","2708":"4","2709":"8","2710":"4","2711":"8","2712":"4","2713":"8",
    "2714":"31","2715":"8","2716":"4","2717":"31","2718":"4","2719":"4","2721":"4","2722":"4","2723":"8","2725":"4","2726":"4","2727":"4","2729":"4","2731":"4",
    "2732":"4","2733":"31","2734":"4","2735":"4","2736":"4","2737":"4","2739":"4","2740":"4","2741":"4","2743":"4","2744":"4","2745":"4","2747":"8","2748":"8","2749":"4",
    "2750":"4","2751":"4","2752":"8","2753":"4","2755":"4","2758":"8","2761":"4","2762":"4","2763":"4","2764":"4","2765":"4","2767":"16","2768":"4","2769":"4",
    "2772":"4","2776":"4","3011":"29","3016":"1","3018":"15","3019":"5","3021":"24","3403":"29","3410":"5","3425":"5","3451":"24","3454":"17","3456":"24",
    "3457":"17","3458":"17","3459":"29","3460":"29","3461":"15","3462":"1","3463":"17","3464":"24","3465":"29","3466":"1","3701":"29","3760":"15","3766":"24",
    "3782":"29","3786":"29","3795":"29","3801":"15","3802":"29","3803":"34","3804":"24","3805":"17","3806":"15","3807":"29","3808":"29","3809":"29","3810":"29",
    "3811":"29","3812":"18","3813":"29","3814":"24","3815":"29","3817":"17","3819":"29","3820":"29","3821":"34","3822":"5","3823":"17","3825":"24","3826":"29","3829":"29",
    "3830":"29","3831":"29","3833":"24","3841":"29","3843":"5","3844":"29","3845":"29","3846":"18","3847":"17","3848":"24","3849":"24","3850":"29","3852":"18","3854":"18",
    "3855":"24","3857":"1","3859":"15","3860":"5","3905":"18","3908":"18","3918":"18","3920":"18","3923":"18","3925":"18","4020":"30","4023":"11","4024":"28","4669":"30",
    "4670":"11","4672":"11","4675":"30","4676":"37","4677":"11","4678":"37","4680":"37","4686":"11","4864":"28","4917":"37","4927":"11","4928":"11","4929":"11","4931":"28",
    "4932":"11","4933":"28","4934":"28","4937":"30","4938":"11","4941":"28","4943":"11","4944":"11","4945":"11","4946":"11","4948":"11","4949":"30","4952":"11","4953":"30",
    "4954":"28","4955":"30","4957":"28","4959":"37","4960":"37","4961":"30","4962":"28","4964":"30","4965":"37","4966":"30","4967":"23","4968":"23","4969":"30","4970":"37",
    "4971":"11","4972":"11","4973":"30","4974":"30","4975":"28","4976":"28","4977":"11","4978":"11","4979":"37","4980":"30","4981":"11","4982":"11","4983":"11","4984":"11",
    "4989":"30","4992":"11","4993":"11","4994":"23","4995":"37","4996":"37","4998":"23","4999":"11","5008":"14","5009":"26","5010":"14","5012":"22","5017":"19","5022":"7",
    "5502":"22","5530":"7","5532":"14","5533":"22","5536":"14","5537":"7","5703":"7","5720":"7","5832":"7","5842":"22","5851":"7","5862":"7","5865":"26","5901":"22",
    "5902":"26","5903":"22","5904":"22","5907":"19","5910":"7","5911":"14","5912":"22","5913":"7","5914":"14","5915":"14","5919":"22","5921":"19","5922":"22","5924":"19",
    "5930":"7","5935":"14","5942":"14","5986":"14","5988":"26","5990":"14"};

const COLLEGES_LIST = [{key:'1', label:'University Departments of Anna University, Chennai - CEG Campus, Sardar Patel Road, Guindy, Chennai 600 025'},
    {key:'2', label:'University Departments of Anna University, Chennai - ACT Campus, Sardar Patel Road, Guindy, Chennai 600 025'},
    {key:'4', label:'University Departments of Anna University, Chennai - MIT Campus, Chrompet, Tambaram Taluk, Kancheepuram District 600 044'},
    {key:'5', label:'Annamalai University Faculty of Engineering and Technology,Annamalai nagar,Cuddalore 608002'},
    {key:'1013', label:'University College of Engineering, Villupuram, Kakuppam, Villupuram District 605103 '},
    {key:'1014', label:'University College of Engineering, Tindivanam, Melpakkam, Tindivanam, Villupuram District 604001 '},
    {key:'1015', label:'University College of Engineering, Arni, Arni to Devikapuram Road, Thatchur, Arni, Thiruvannamalai District 632326 '},
    {key:'1026', label:'University College of Engineering, Kancheepuram, Ponnerikarai Campus, NH4, Chennai-Bangalore Highway, Karaipettai Village & Post, Kancheepuram District 631552'},
    {key:'1101', label:'Aalim Muhammed Salegh College of Engineering, Muthapudupet, Avadi IAF, Chennai 600055'},
    {key:'1106', label:'Jaya Engineering College, Thirunindravur, Chennai 602024'},
    {key:'1107', label:'Jaya Institute of Technology, Kanchipadi Post, Thiruvallur-Tiruttani NH  Road, Thiruvallur District 631204'},
    {key:'1110', label:'Prathyusha Engineering college, Aranvoyalkuppam, Thiruvallur District 602025'},
    {key:'1112', label:'R M D Engineering College, Kavaraipettai, Gummidipoondi, Thiruvallur District 601206'},
    {key:'1113', label:'R M K Engineering College, Kavaraipettai, Gummidipoondi, Thiruvallur District 601206'},
    {key:'1114', label:'S A Engineering College, Thiruverkadu, Chennai  Thiruvallur District 600077'},
    {key:'1115', label:'Sri Ram Engineering College, Perumalpattu, Veppampattu (R S), Thiruvallur District 602 024'},
    {key:'1116', label:'Sri Venkateswara College of Engineering and Technology, Thirupachur, Thiruvallur District 631203'},
    {key:'1118', label:'Vel Tech Multi Tech Dr. Rangarajan Dr. Sakunthala Engineering College (Autonomous), Avadi-Alamathi Road, Chennai 600 062'},
    {key:'1120', label:'Velammal Engineering College (Autonomous), Ambattur-Redhills Road, Chennai 600066'},
    {key:'1121', label:'Sri Venkateswara Institute of Science and Technology, Kozhundalur, Thiruvallur District 631203'},
    {key:'1122', label:'Vel Tech High Tech Dr. Rangarajan Dr. Sakunthala Engineering College, Avadi-Alamathi Road, Chennai 600 062'},
    {key:'1123', label:'Gojan School of Business and Technology, Alamathi, Chennai 600062'},
    {key:'1124', label:'SAMS College of Engineering and Technology, Panappakkam, Chennai-Tirupathi Road, Uthukkottai Taluk, Thiruvallur District 601102'},
    {key:'1125', label:'P M R Engineering College, Adayalampattu, Maduravoyal , Vanagaram, Chennai 600095'},
    {key:'1126', label:'J N N Institute of Engineering, Ushaa Garden, Kannigaipair Village, Uthukottai Taluk, Thiruvallur District 601102'},
    {key:'1127', label:'St. Peters College of Engineering and Technology, College Road, Avadi, Chennai 600 054'},
    {key:'1128', label:'R M K College of Engineering and Technology, Puduvoyal, Gummidipoondi Taluk, Thiruvallur District 601206'},
    {key:'1131', label:'Vel Tech, Avadi-Alamathi Road, Chennai 600062'},
    {key:'1133', label:'Annai Veilankannis College of Engineering, Gandhi Road, Nedungundram, Chennai 600048'},
    {key:'1137', label:'Annai Mira College of Engineering and Technology, Arcot Road, Arappakkam Village, Walaja Taulk, Vellore District 632 517'},
    {key:'1140', label:'Jeppiaar Institute Of Technology, Kunnam Village, Sriperumpudur Taluk, Kancheepuram District 631604'},
    {key:'1141', label:'R V S Padmavathy College of Engineering and Technology, Senthil Pakkam Village, Roshanagaram  Post, Madarapakkam Via, Gummidipoondi Taluk, Thiruvallur District 601202'},
    {key:'1143', label:'Kumaran Institute of Techonology, Kollati Village, Minjur, Ponneri Taluk, Thiruvallur District 601203'},
    {key:'1149', label:'St. Josephs Institute of Technology, Jeppiaar Nagar, Old Mahabalipuram Road (OMR), Chennai 600119'},
    {key:'1150', label:'Sri Jayaram Institute of Engineering and Technology, 51 N, Elavur, 58N, Natham, Gummidipoondi Taluk, Thiruvallur District 601201'},
    {key:'1201', label:'Arignar Anna Institute of Science and Technology, Pennalur, Sriperumpudur, Taluk, Kancheepuram District 602105'},
    {key:'1202', label:'D M I College of Engineering, Palanchoor, Nazrethpet, Chennai 602103'},
    {key:'1205', label:'Lord Venkateshwara Engineering College, Walajabad Post, Kancheepuram District 631605'},
    {key:'1206', label:'Jeppiaar Maamallan Engineering College, Vadamangalam Post, Kancheepuram District 602105'},
    {key:'1207', label:'Kings Engineering College, Sriperumpudur Taluk, Kancheepuram District 602105'},
    {key:'1209', label:'Pallavan College of Engineering, Thimmasamudram, Kancheepuram District 631502'},
    {key:'1210', label:'Panimalar Engineering College, Nazarethpet, Poonamallee, Chennai 602103'},
    {key:'1211', label:'Rajalakshmi Engineering College (Autonomous), Thandalam, Sriperumpudur Taluk, Kancheepuram District 602105'},
    {key:'1212', label:'Rajiv Gandhi College of Engineering, Nemili, Sriperumpudur Taluk, Kancheepuram District 602105'},
    {key:'1213', label:'S K R Engineering College, Poonamallee, Chennai 600123'},
    {key:'1214', label:'Sakthi Mariamman Engineering College, Narayanasamy Nagar, Thandalam, Chennai 602105'},
    {key:'1216', label:'Saveetha Engineering College (Autonomous), Thandalam, Sriperumpudur Taluk, Kancheepuram District 602105'},
    {key:'1217', label:'Sree Sastha Institute of Engineering and Technology, Chembarambakkam, Chennai 600123'},
    {key:'1218', label:'Sri Muthukumaran Institute of Technology, Near Mangadu, Chennai 600069'},
    {key:'1219', label:'Sri Venkateswara College of Engineering (Autonomous), Post Bag No.1, Chennai-Bengaluru High Road, Pennalur, Irungattukottai S.O., Sriperumbudur Taluk, Kancheepuram District 602117'},
    {key:'1221', label:'Jaya College of Engineering and Technology, Parivakkam, Poonamallee, Chennai 600056'},
    {key:'1222', label:'P B College of Engineering, Irungkattukottai, Sriperumpudur Taluk, Kancheepuram District 602105'},
    {key:'1225', label:'Loyola Institute of Technology, Mevaloorkuppam, B Village, Palanchoor, Nazarethpet Post, Chennai 600123'},
    {key:'1226', label:'P T Lee Chengalvaraya Naicker College of Engineering and Technology, Oovery, Veliyur Post, Kancheepuram District 631 502'},
    {key:'1228', label:'Alpha College of Engineering, Dr. Grace George Nagar, Udayavar Koil Street, Thirumazhisai, Poonamalle, Chennai 602107'},
    {key:'1229', label:'Indira Institute of Engineering and Technology, VGR Gardens, Pandur, Thiruvallur District 631203'},
    {key:'1230', label:'Apollo Engineering College, Mevaloorkuppam, Valarpuram Post, Sriperumpudur, Kancheepuram District 602105'},
    {key:'1231', label:'Panimalar Institute of Technology, Nazarethpet, Poonamallee, Chennai 602103'},
    {key:'1232', label:'A R M College of Engineering and Technology, Sattamangalam, Maraimalainagar, Chengalpattu District 603209'},
    {key:'1233', label:'Adhi College of Engineering and Technology, Pazhayaseevaram, Madura Sankarapuram Village, Kancheepuram District 631605'},
    {key:'1234', label:'Indira Gandhi College of Engineering and Technology for Women,  Chengalpattu-Kancheepuram High Road, Chengalpattu District 603101'},
    {key:'1235', label:'JEI Mathaajee College of Engineering, Vishakandikupam Village, Kancheepuram District 631552'},
    {key:'1237', label:'Velammal Institute of Technology, Chennai-Kolkatta Highway, Panchetti Village, Ponneri Taluk, Thiruvallur District 601204'},
    {key:'1238', label:'GRT Institute of Engineering and Technology, GRT Mahalakshmi Nagar, Chennai-Tirupathi Highway, Tiruttani Taluk, Thiruvallur District 631209'},
    {key:'1241', label:'T J S Engineering College, Peruvoyal, Near Kavaraipettai, Gummidipoondi Taluk, Thiruvallur District 601206'},
    {key:'1243', label:'Madha Institute of Engineering and Technology, Erandamkattalai Village, Sadhananthapuram, Thandalam Post, Chennai  602101'},
    {key:'1301', label:'Mohamed Sathak A J College of Engineering, Old Mahabalipuram Road (OMR), Egattur, Chengalpattu District 603103'},
    {key:'1303', label:'Anand Institute of Higher Technology, Old Mahabalipuram Road (OMR), Kazhipattur, Chengalpattu District  603103'},
    {key:'1304', label:'Easwari Engineering College (Autonomous), Ramapuram, Chennai 600089'},
    {key:'1306', label:'Jeppiar Engineering College, Old Mahabalipuram Road (OMR), Chennai 600119'},
    {key:'1307', label:'Jerusalem College of Engineering (Autonomous), Narayanapuram, Pallikaranai, Chennai 600 100'},
    {key:'1309', label:'Meenakshi Sundararajan Engineering College, Kodambakkam, Chennai 600024'},
    {key:'1310', label:'Misrimal Navajee Munoth Jain Engineering College, Rajiv Gandhi Salai (OMR), Thorappakkam, Chennai 600096'},
    {key:'1311', label:'K C G College of Technology, Karappakkam, Chennai 600096'},
    {key:'1313', label:'SMK  Fomra Institute of Technology,Fomra Nagar, OMR,(IT Express Highway),Kelambakkam,Chennai- 603103'},
    {key:'1315', label:'Sri Sivasubramaniya Nadar College of Engineering (Autonomous), Kalavakkam, Old Mahabalipuram Road (OMR), Chengalpattu District 603110'},
    {key:'1316', label:'Agni College of Technology, Old Mahabalipuram Road (OMR), Thalambur Village, Chengalpattu District 603103'},
    {key:'1317', label:'St. Joseph’s College of Engineering, Old Mahabalipuram Road (OMR), Chennai 600 119'},
    {key:'1319', label:'Thangavelu Engineering College, Karappakkam, Chennai 600097'},
    {key:'1320', label:'Jeppiaar SRR Engineering College, Old Mahabalipuram Road, Chengalpattu District 603103'},
    {key:'1321', label:'Central Institute of Plastics Engineering and Technology (CIPET), Guindy, Chennai 600032'},
    {key:'1322', label:'Dhanalakshmi Srinivasan College of Engineering and Technology, East Coast Road, Poonjeri, Chennai 603104'},
    {key:'1323', label:'A C T College of Engineering and Technology, Nelvoy Village, Pukkathurai Post, Maduranthagam, Chengalpattu District 603107'},
    {key:'1324', label:'Sri Sai Ram Institute of Technology, West Tambaram, Chennai 600044'},
    {key:'1325', label:'St. Joseph College of Engineering, Trinity Campus, Nemili, Sriperumpudur Taluk, Kancheepuram District 602105'},
    {key:'1333', label:'Vi Institute of Technology, Sirunkundram Village & Post, Chengalput Taluk, Chengalpattu District 603108'},
    {key:'1334', label:'ARS College of Engineering, Maraimalai Nagar Post, Chengalpattu District 603209'},
    {key:'1335', label:'Sri Krishna Institute of Technology, Panapakkam, Padappai,  Chennai 601301'},
    {key:'1399', label:'Chennai Institute of Technology, Puduper Village, Nandambakkam Post, Kundrathur, Chennai 600069'},
    {key:'1401', label:'Adhiparasakthi Engineering College, Melmaruvathur, Chengalpattu District 603319'},
    {key:'1402', label:'Annai Terasa College of Engineering, Thirunavalur, Kallakurichi District 607204'},
    {key:'1405', label:'Dhanalakshmi College of  Engineering, Manimangalam, Chennai 601301'},
    {key:'1406', label:'Dr. Pauls Engineering College, Pauls Nagar, Pulichapallam Village, Vanur Taluk, Villupuram District 605109'},
    {key:'1407', label:'G K M College of  Engineering and Technology, Alappakkam-Mappedu Road, Chennai 600063'},
    {key:'1408', label:'I F E T College of Engineering (Autonomous), Gangarampalayam, Villupuram District 605108'},
    {key:'1409', label:'Karpagavinayaga College of Engineering and Technology, Palayanoor Post, Maduranthagam Taluk, Chengalpattu District 603308'},
    {key:'1411', label:'Madha Engineering College, Kundrathur, Chennai 600069'},
    {key:'1412', label:'Mailam Engineering College, Mailam, Villupuram District 604304'},
    {key:'1413', label:'Sri Venkateswaraa College Of Technology, BHB Nagar, Vadakkal Village, Pondur Post, Sriperumbudur Taluk, Kancheepuram District 602105'},
    {key:'1414', label:'Prince Shri Venkateshwara  Padmavathy Enginering College, Ponmar, Chennai 600048'},
    {key:'1415', label:'T S M Jain College of Technology, Melur Village, Kallakurichi, Kallakurichi District 606201'},
    {key:'1416', label:'Jaya Sakthi Engineering College, Thirunindravur, Chennai 602024'},
    {key:'1417', label:'Shri Andal Alagar College of Engineering, Mamandur, Chengalpattu District 603111'},
    {key:'1419', label:'Sri Sairam Enginering College, West Tambaram, Chennai 600044'},
    {key:'1420', label:'Tagore Engineering College, Rathnamangalam, Vandalur Post, Chennai 600048'},
    {key:'1421', label:'V R S College of Engineering and Technology, Arasur, Kallakurichi District 607107'},
    {key:'1422', label:'SRM Valliammai Engineering College (Autonomous), Kattankulathur, Chennai 603203'},
    {key:'1424', label:'Dhaanish Ahmed College of Engineering, Padappai, Chennai 601301'},
    {key:'1426', label:'Sri Ramanujar Engineering College, Vandalur, Kolappakkam, Chennai 600048'},
    {key:'1427', label:'Sri Krishna Engineering College, Panappakkam, Padappai, Chennai 601301'},
    {key:'1428', label:'E S Engineering College, Chennai Trunk Road, Ayyankoilpattu, Villupuram District 605602'},
    {key:'1430', label:'Maha Bharathi Engineering College, A Vasudevanur, Chinnasalem, Kallakurichi Taluk, Kallakurichi District 606201'},
    {key:'1431', label:'New Prince Shri Bhavani College of Engineering and Technology, Vengaivasal Main Road, Gowriwakkam, Chennai 600073'},
    {key:'1432', label:'Rajalakshmi Institute of Technology, Irulapalayam, Kuthampakkam Post, Thiruvallur District 602107'},
    {key:'1434', label:'Surya Group of Institutions, GST Road, Vikravandi Village, Villupuram District 605652 '},
    {key:'1435', label:'Balaji Institute of Engineering and Technology, Thandalam Village, Thiruporur, Chengalpattu District 603110'},
    {key:'1436', label:'A R Engineering College, Vadakuchipalayam, Kappiyampuliyur Post, Villupuram District 605601'},
    {key:'1437', label:'Rrase College of Engineering, Padappai, Chennai 601301'},
    {key:'1438', label:'Sree Krishna College of Engineering, Anaicut Post, Unnai Village, Vellore District 632101'},
    {key:'1439', label:'S R I College of Engineering and Technology, Birudur, Vandavasi Taluk, Thiruvannamalai District 604408'},
    {key:'1441', label:'A K T Memorial College of Engineering and Technology, Neelamangalam Village, Kallakurichi Post & Taluk, Kallakurichi District 606202'},
    {key:'1442', label:'Prince Dr. K Vasudevan College of Engineering and Technology, Medavakkam-Mambakkam Road, Ponmar, Chennai 600048'},
    {key:'1444', label:'Chendu College of Engineering & Technology, Zamin Endathur Village, Madurantakam Taluk, Chengalpattu District 603331'},
    {key:'1445', label:'Sri Rangapoopathi College of Engineering, Alampoondi Village, Gingee, Villupuram District 604151'},
    {key:'1447', label:'Jawahar Engineering College, Kaveri Rangan Nagar, Saligramam, Chennai 600093'},
    {key:'1449', label:'Saraswathy College of Engineering and Technology, NH-45, Main Road, Olakkur, Tindivanam Taluk, Villupuram District 604307'},
    {key:'1450', label:'Loyola-ICAM College of Engineering and Technology, Loyola College Campus, Nungambakkam, Chennai 600034'},
    {key:'1452', label:'PERI Institute of Technology, Mannivakkam, West Tambaram, Chennai 600048'},
    {key:'1501', label:'Adhiparasakthi College of Engineering, Kalavai, Vellore District 632506'},
    {key:'1503', label:'Arulmigu  Meenakshi Amman College of Engineering, Vadamavandal,Thiruvannamalai District 604410'},
    {key:'1504', label:'Arunai Engineering College, Mathur, Thiruvannamalai District 606603'},
    {key:'1505', label:'C Abdul Hakeem College of Engineering and Technology, Melvisharam, Vellore District 632509'},
    {key:'1506', label:'G G R College of Engineering, Pillayarkuppam, Perumugai Post, Vellore District 632009'},
    {key:'1507', label:'Ganadipathy Tulsi’s Jain Engineering College, Kaniyambadi, Vellore District 632102'},
    {key:'1508', label:'Arunai College of Engineering, Mathur, Thiruvannamalai District 606603'},
    {key:'1509', label:'Meenakshi College of Engineering, Vembuliamman Koil Street, K K Nagar (West), Chennai 600078'},
    {key:'1510', label:'Priyadarshini Engineering College, Vaniyambadi Post, Thirupattur District 635751'},
    {key:'1511', label:'Ranippettai Engineering College, Thenkadappanthangal, Vellore District 632513'},
    {key:'1512', label:'S K P Engineering College, Thiruvannamalai District 606611'},
    {key:'1513', label:'Sri Balaji Chockalingam Engineering College, Arni, Thiruvannamalai District 632317'},
    {key:'1514', label:'Sri Nandhanam College of Engineering and Technology, Tiruppattur, Tiruppattur District 635601'},
    {key:'1515', label:'Saraswathi Velu College of Engineering, Melvenkatapuram Village, Sholingur, Vellore District 631102'},
    {key:'1516', label:'Thanthai Periyar Government Institute of Technology , Bagayam, Vellore District 632002'},
    {key:'1517', label:'Thirumalai Engineering College, Kilambi, Kancheepuram District 631551'},
    {key:'1518', label:'Thiruvalluvar College of Engineering and Technology, Vandavasi, Thiruvannamalai District 604505'},
    {key:'1519', label:'Bharathidasan Engineering College, Nattrampalli Post, Tiruppattur District 635854'},
    {key:'1520', label:'Kingston Engineering College, Chithoor Main Road, Christianpet Village, Katpadi Taluk,  Vellore District 632059'},
    {key:'1521', label:'Sri Sapthagiri Institute of Technology, Ocheri, Arakonam Taluk, Vellore District 632531'},
    {key:'1523', label:'Global Institute of Engineering and Technology, Bangalore-Chennai Highway, Melvisharam, Walajah Taluk, Vellore District 632506'},
    {key:'1524', label:'Annamalaiar College of Engineering, Modaiyur Village, Polur Taluk, Thiruvannamalai District 606902'},
    {key:'1525', label:'Podhigai College of Engineering and Technology, Vinayakapuram, Adiyur Post, Tirupattur Taluk, Tiruppattur District 635601'},
    {key:'1526', label:'Sri Krishna College of Engineering, Tiruttani High Road, Arakkonam, Vellore District 631003'},
    {key:'1529', label:'Oxford College of Engineering, Venmani Village, Karaipoondi Post, Polur Taluk, Thiruvannamalai District 606803'},
    {key:'1605', label:'Idhaya Engineering College for Women, Chinnasalem, Kallakurichi District 606201'},
    {key:'2005', label:'Government College of Technology (Autonomous), Thadagam Road, Coimbatore District 641013'},
    {key:'2006', label:'PSG College of Technology (Autonomous), Peelamedu, Coimbatore District 641004'},
    {key:'2007', label:'Coimbatore Institute of Technology (Autonomous), Civil Aerodrome Post, Coimbatore District 641014'},
    {key:'2025', label:'Anna University Regional Campus - Coimbatore, Maruthamalai Main Road, Navavoor Bharathiyar University Post, Somayampalayam, Coimbatore District 641046'},
    {key:'2302', label:'Sri Shanmugha College of Engineering and Technology, Morur Bit II Village, Salem District 637302'},
    {key:'2314', label:'Muthayammal College of Engineering, Kakkaveri Post, Namakkal District 637408'},
    {key:'2327', label:'N S N College of Engineering and Technology, Karur-Madurai NH7, Manalmedu, Karur District 639003'},
    {key:'2328', label:'K S R Institute for Engineering and Technology, Thokkavadi, Namakkal District 637215'},
    {key:'2329', label:'Rathinam Technical Campus, Pollachi Road, Eachanari, Coimbatore District 641021'},
    {key:'2332', label:'Aishwarya College of Engineering and Technology, Errattaikaradu Paruvachi Post, Anthiyur (Via), Bhavani Taluk, Erode District 638312'},
    {key:'2338', label:'Asian College of Engineering and Technology, Asian College Road, Kondayampalayam, Near Saravanampatti, Coimbatore District 641110'},
    {key:'2341', label:'Ganesh College of Engineering, Attur Main Road, Mettupatti, Salem District 636111'},
    {key:'2342', label:'Sri Ranganathar Institute of Engineering and Technology, Thudialur-Kovilpalay Road, Coimbatore District 641110'},
    {key:'2343', label:"Indian Institute of Handloom Technology, Foulke's Compound, Thillai Nagar, Salem District 636001"},
    {key:'2345', label:'Dhirajlal Gandhi College of Technology, Sikkanampatty, (Opp. to Airport), Omalur Taluk, Salem District 636309'},
    {key:'2346', label:'Shree Sathyam College of Engineering and Technology, NH-47, Manjakkalpatti, Kuppanur Post, Sankari Taluk, Salem District 637301'},
    {key:'2347', label:'AVS College of Technology, Attur Main Road, Near AVS College of Arts & Science, Chinnagoundapuram, Salem District 636106'},
    {key:'2349', label:'Dhaanish Ahmed Institute of Technology, Pichanur Post, (Near K G Chavadi), Coimbatore District 641105'},
    {key:'2350', label:'Jairupaa College of Engineering, Thottiapalayam, Kathankanni Post, Kangayam, Tiruppur District 641 604'},
    {key:'2354', label:'Pollachi Institute of Engineering and Technology, Main Road, Poosaripatti, Pollachi Taluk, Coimbatore District 642205'},
    {key:'2355', label:'Cheran College of Engineering, Karur-Coimbatore Main Road, NH-67, K.Paramathi Post, Aravakurichi Taluk, Karur District 639111'},
    {key:'2356', label:'Arulmurugan College of Engineering, Karvazhi Road, Thennilai Post, Karur District 639206'},
    {key:'2357', label:'V S B College of Engineering Technical Campus, Ealur Pirivu, Solavampalayam Post, Coimbatore District 642109'},
    {key:'2358', label:'SCAD Institute of Technology, Anupatti, Pollachi-Palladam Highways, Palladam, Tiruppur District 641664'},
    {key:'2360', label:'Suguna College of Engineering, Kalappatti Road, Civil Aerodrome Post, Coimbatore District 641014'},
    {key:'2367', label:'Arjun College of Technology, Chettiyakkapalayam, Kinathukadavu, Coimbatore District 642120'},
    {key:'2369', label:'Government College of Engineering, Chettikkarai Post, Dharmapuri District 635 704'},
    {key:'2377', label:'PSG Institute of Technology and Applied Research, Avinashi Road, Neelambur, Coimbatore 641062'},
    {key:'2601', label:'Adhiyamaan  College of Engineering (Autonomous), Hosur, Krishnagiri District 635109'},
    {key:'2602', label:'Annai Mathammal Sheela Enginereing College, Erumapatty Post, Namakkal District 637013'},
    {key:'2603', label:'Government College of Engineering (Autonomous), Bargur, Krishnagiri District 635104'},
    {key:'2606', label:'Jayam College of Engineering and Technology, Nallanur Post, Dharmapuri District 636813'},
    {key:'2607', label:'K S Rangasamy College of Technology (Autonomous), Tiruchengode, Namakkal District 637215'},
    {key:'2608', label:'M Kumarasamy College of Engineering (Autonomous), Thalavapalayam, Karur District 639113'},
    {key:'2609', label:'Mahendra Engineering College (Autonomous), Mahendhirapuri, Mallasamudram, Namakkal District 637503'},
    {key:'2610', label:'Muthayammal Engineering College (Autonomous), Rasipuram, Namakkal District 637408'},
    {key:'2611', label:'Paavai Engineering College (Autonomous), NH-7, Pachal Post, Namakkal District 637018'},
    {key:'2612', label:'P G P College of Engineering and Technology, Paramathi Post, Namakkal District 637207'},
    {key:'2613', label:'K S R College of Engineering (Autonomous), Tiruchengode, Namakkal District 637215'},
    {key:'2614', label:'S S M College of Engineering, Komarapalayam, Namakkal District 638183'},
    {key:'2615', label:'Government College of Engineering (Autonomous), Karuppur, Salem District 636011'},
    {key:'2616', label:'Sapthagiri College of Engineering, Periyanahalli Post, Dharmapuri District 635205'},
    {key:'2617', label:'Sengunthar Engineering College (Autonomous), Tiruchengode, Namakkal District 637205'},
    {key:'2618', label:'Sona College of Technology (Autonomous), Suramangalam Post, Salem District 636005'},
    {key:'2620', label:'Vivekanandha College of Engineering for Women (Autonomous), Sathinaickenpalayam, Elayampalayam Village, Kumaramangalam, Namakkal District 637205'},
    {key:'2621', label:'Er. Perumal Manimekalai College of Engineering, Near Koneripalli, Hosur, Krishnagiri District 635117'},
    {key:'2622', label:'V S B Engineering College, Kovai Road, Karur District 639111'},
    {key:'2623', label:'Mahendra College of Engineering, Attur Road, Minnampalli, Valapady, Salem District 636106'},
    {key:'2624', label:'Gnanamani College of Technology, Pachal Post, Namakkal District 637018'},
    {key:'2625', label:'The Kavery Engineering College, M Kalipatti Post, Mecheri, Salem District 634453'},
    {key:'2627', label:'Selvam College of Technology, Pappanaickenpatti Post, Namakkal District 637003'},
    {key:'2628', label:'Paavai College of Engineering, NH-7, Pachal Post, Namakkal District 637018'},
    {key:'2629', label:'Sengunthar College of Engineering, Tiruchengode, Namakkal District 637205'},
    {key:'2630', label:'Chettinad College of Engineering and Technology, NH-67, Trichy Main Road, Puliyur C F, Karur District 639114'},
    {key:'2631', label:'King College of Technology, Nallur, N Pudupatty Post, Namakkal District 637020'},
    {key:'2632', label:'Mahendra Institute of Technology, Mahendhirapuri, Mallasamudram,  Namakkal District 637503'},
    {key:'2633', label:'Vidyaa Vikas College of Engineering and Technology, Varahoorampatti, Tiruchengode, Namakkal District 637 214'},
    {key:'2634', label:'Excel Engineering College, Pallakkapalayam, Sankari West Post, Namakkal District 637303'},
    {key:'2635', label:'C M S College of Engineering, Emapuram Post, Namakkal District 637003'},
    {key:'2636', label:'A V S Engineering College, Military Road, Ammapet, Salem District 636003'},
    {key:'2638', label:'Mahendra Engineering College for Women, Kumaramangalam, Namakkal District 637205'},
    {key:'2639', label:'Narasus Sarathy Institute of Technology, Poosaripatty Village, Omalur Taluk, Salem District 636305'},
    {key:'2640', label:'Jayalakshmi Institute of Technology, Thoppur, Dharmapuri District 635352'},
    {key:'2641', label:'Varuvan Vadivelan Institute of Technology, Nallanahalli, Dharmapuri District 636701'},
    {key:'2642', label:'P S V College of Engineeering and Technology, Mittapalli, Balinayanapalli Post, Elathagiri, Krishnagiri District 635108'},
    {key:'2643', label:'Bharathiyar Institute of Engineering for Women, Deviayakurichi, Attur Taluk, Salem District 636112'},
    {key:'2646', label:'Tagore Institute of Engineering and Technology, Deviyakurichi, Attur Taluk, Salem District 636112'},
    {key:'2647', label:'J K K Nataraja College of Engineering and Technology, Komarapalayam Amani, Thattankuttai Panchayat, Namakkal District 638183'},
    {key:'2648', label:'Annaporna Engineering College, Sankari Main Road, NH-47, Periaseeragapadi, Salem District 636308'},
    {key:'2649', label:'Karur College of Engineering, Sanjay Nagar, Karur-Erode Main Road, Athur Post, Karur District 639002'},
    {key:'2650', label:'Christ the King Engineering College, Cecilia Gardens, Chikkarampalayam Village, Coimbatore District 641104'},
    {key:'2651', label:'Jai Shriram Engineering College, Dharapuram Road, Avinashi Palayam, Tiruppur District 638660'},
    {key:'2652', label:'Al-Ameen Engineering College, Karundevan Palayam, Nanjai Uthukuli Post, Erode District 638104 '},
    {key:'2653', label:'Knowledge Institute of Technology, KIOT Campus, Kakapalayam Post, Salem District 637504'},
    {key:'2656', label:'Builders Engineering College,Erode Rode,Nathakadiyur, Kangeyam Taluk, Tiruppur District 638108'},
    {key:'2657', label:'Paavai College of Technology, NH-7, Pachal Post, Namakkal District 637018'},
    {key:'2658', label:'V S A Group of Institutions, NH-47, Uthamasola Puram Post, Salem District 636010'},
    {key:'2659', label:'Salem College of Engineering and Technology, Salem-Attur Main Road, NH-68, Mettupatty Perumapalayam, Salem District 636111'},
    {key:'2660', label:'Gnanamani College of Engineering, NH-7, A K Samuthiram, Pachal Post, Namakkal District 637018'},
    {key:'2661', label:'Vivekanandha College of Technology for Women, Sathinaickenpalayam, Elayampalayam Village, Kumaramangalam, Namakkal District 637205'},
    {key:'2662', label:'Dr. Nagarathinams College of Engineering, Anadhagoundam Palayam, Minakkal Post, Rasipuram, Namakkal District 637505'},
    {key:'2664', label:'Excel College of Engineering and Technology, Pallakapalayam, Sankari West Post, Namakkal District 637303'},
    {key:'2665', label:'Mahendra Institute of Engineering and Technology, Mahendrapuri, Mallasamudram, Namakkal District 637503'},
    {key:'2666', label:'The Kavery College of Engineering, M Kalipatti Post, Mecheri, Salem District 636453'},
    {key:'2673', label:'Sree Sakthi Engineering College, Bettathapuram, Bilichi Village, Karamadai, Coimbatore District 641104'},
    {key:'2682', label:'Ambal Professional Group of Institutions, Coimbatore to Trichy Road, K N Puram Post, Palladam Taluk, Tiruppur District 641662'},
    {key:'2683', label:'Shreenivasa Engineering College, B Pallipatti, Bommidi, Pappireddipatti Taluk, Dharmapuri District 635301'},
    {key:'2702', label:'Bannari Amman Institute of Technology (Autonomous), Sathyamanagalam, Erode District  638401'},
    {key:'2704', label:'Coimbatore Institute of Engineering and Technolgoy (Autonomous), Narasipuram Post, Coimbatore District 641109'},
    {key:'2705', label:'C S I College of Engineering, Ketti, The Nilgiris 643215'},
    {key:'2706', label:'Dr. Mahalingam College of  Engineering and Technology (Autonomous), Mackinaickenpatti Post, Pollachi Taluk, Coimbatore District 642003'},
    {key:'2707', label:'Erode Sengunthar Engineering College (Autonomous), Thudupathi Post, Erode District 638057'},
    {key:'2708', label:'Hindusthan College of Engineering and Technology (Autonomous), Othakkalmandapam Post, Coimbatore District 641032'},
    {key:'2709', label:'Institute of Road and Transport Technology, Vasavi College Post, Erode District 638316'},
    {key:'2710', label:'Karpagam College of Engineering (Autonomous), Othakkalmandapam, Coimbatore District 641032'},
    {key:'2711', label:'Kongu Engineering College (Autonomous), Perundurai, Erode District 638052'},
    {key:'2712', label:'Kumaraguru College of Technology (Autonomous), Chinnavedampatti Post, Coimbatore District 641006'},
    {key:'2713', label:'M P Nachimuthu M Jagannathan Engineering College, Chennimalai, Erode District 638112'},
    {key:'2714', label:'Maharaja Engineering College, Avinashi, Tiruppur District 641654'},
    {key:'2715', label:'Nandha Engineering College (Autonomous), Pitchandampalayam Post, Erode District 638052'},
    {key:'2716', label:'Park College of Engineering and Technology, Kaniyur, Coimbatore District 641659'},
    {key:'2717', label:'Sasurie College of Engineering, Vijayamangalam, Tiruppur District 638056'},
    {key:'2718', label:'Sri Krishna College of Enginering and Technology (Autonomous), Kuniamuthur, Coimbatore District 641008'},
    {key:'2719', label:'Sri Ramakrishna Engineering College (Autonomous), Vattamalaipalayam, Coimbatore District 641022'},
    {key:'2721', label:'Tamil nadu College of Engineering, Karumathampatti Post, Coimbatore District 641659'},
    {key:'2722', label:'Sri Krishna College of Technology (Autonomous), Kovaipudur Post, Coimbatore District 641042'},
    {key:'2723', label:'Velalar College of Engineering and Technology (Autonomous), Thindal Post, Erode District 638009'},
    {key:'2725', label:'Sri Ramakrishna Institute of Technology (Autonomous), Pachapalayam, Coimbatore District 641010'},
    {key:'2726', label:'SNS College of Technology (Autonomous), Kalappatti Post, Coimbatore District 641035'},
    {key:'2727', label:'Sri Shakthi Institute of Engineering and Technology (Autonomous), L&T Bye-Pass, Venkitapuram Post, Comibatore District 641062'},
    {key:'2729', label:'Nehru Institute of Engineering and Technology, Thirumalayampalayam Post, Coimbatore District 641105'},
    {key:'2731', label:'R V S College of Engineering and Technology, Kannampalayam, Sulur, Coimbatore District 641402'},
    {key:'2732', label:'INFO Institute of Engineering, Sarkar Samakulam, Coimbatore District 641107'},
    {key:'2733', label:'Angel College of Engineering and Technology, P.K.Palayam, Ugayanur Village, Tirupur District 641 665'},
    {key:'2734', label:'SNS College of Engineering, Sathy Main Road, Kurumbapalayam Post, Coimbatore District 641107'},
    {key:'2735', label:'Karpagam Institute of Technology, Seerapalayam Village, L&T By pass Road, Coimbatore District 641021'},
    {key:'2736', label:'Dr. N G P Institute of Technology, Kalapatti Road, Coimbatore District 641035'},
    {key:'2737', label:'Sri Sai Ranganathan Engineering College, Zahirnaickenpalayam Village, Viraliyur Post, Thondamuthur Via, Coimbatore District 641109'},
    {key:'2739', label:'Sri Eshwar College of Engineering (Autonomous), Kondampatti Post, Vadasithur (Via), Kinathukadavu, Coimbatore District 641202'},
    {key:'2740', label:'Hindustan Institute of Technology, Othakkalmandapam, Coimbatore District 641032'},
    {key:'2741', label:'P A College of Engineering and Technology (Autonomous), Palladam Road, Pollachi, Coimbatore District 642002'},
    {key:'2743', label:'Dhanalakshmi Srinivasan College of Engineering, NH47, Palakkad Main Road, Navakkarai Post, Near Nandhi Temple, Coimbatore District 641105'},
    {key:'2744', label:'Adithya Institute of Technology, Kurumbapalayam Village, Coimbatore District 641107'},
    {key:'2745', label:'Kathir College of Engineering, Neelambur, Avinashi Road, Coimbatore District 641062'},
    {key:'2747', label:'Shree Venkateshwara Hi-Tech Engineering College, Othakuthirai, K Mettupalayam Post, Gobichettipalayam, Erode District 638455'},
    {key:'2748', label:'Surya Engineering College, Mettukadai, Kathirampatti Post, Erode District 638107'},
    {key:'2749', label:'EASA College of Engineering and Technology, NH-47, Coimbatore-Palakkad Main Road, Navakarai Post, Coimbatore District 641105'},
    {key:'2750', label:'KIT-Kalaignar Karunanidhi Institute of Technology (Autonomous), Kannampalayam, Coimbatore District 641402'},
    {key:'2751', label:'KGISL Institute of Technology, KGISL Campus, Thudiyalur Road, Saravanampatti, Coimbatore District 641035'},
    {key:'2752', label:'Nandha College of Technology, Pitchandampalayam Post, Erode District 638052'},
    {key:'2753', label:'P P G Institute of Technology, Vilankurichi Village, Coimbatore District 641035'},
    {key:'2755', label:'Nehru Institute of Technology, Thirumalayampalayam Post, Coimbatore District 641105'},
    {key:'2758', label:'J K K Muniraja College of Technology, T N Palayam, Gobi Taluk, Erode District 638506'},
    {key:'2761', label:'United Institute of Technology, Gadalore Village, Perianaickenpalayam, Coimbatore District 641020'},
    {key:'2762', label:'Jansons Institute of Technology, Karumathampatty, Somaur, Coimbatore District 641659'},
    {key:'2763', label:'Akshaya College of Engineering and Technology, Kinathukadavu, Coimbatore District 642109'},
    {key:'2764', label:'K P R Institute of Engineering and Technology, Kollupalayam Village, Arasur Panchayat, Coimbatore District 641407'},
    {key:'2765', label:'Sriguru Institute of Technology, Varathaiyanagar Palayam, Kondayampalayam Post, Coimbatore District 641110'},
    {key:'2767', label:'SRG Engineering College, Aniyapuram Post, Namakkal District 637017'},
    {key:'2768', label:'Park College of Technology, Prema Ravi Nagar, Karumathampatty, Coimbatore District 641059'},
    {key:'2769', label:'J C T College of Engineering and Technology, Pichanur, Coimbatore District 641105'},
    {key:'2772', label:'C M S College of Engineering and Technology, Appachigoundenpathy, Kumittipatti, Coimbatore District 641105'},
    {key:'2776', label:'R V S Technical Campus-Coimbatore, Kumaran Kottam Campus,  Kannampalayam, Sulur, Coimbatore District 641 402'},
    {key:'3011', label:'University College of Engineering,BIT Campus,Anna university ,Tiruchirappalli District 620024'},
    {key:'3016', label:'University College of Engineering, Ariyalur, Kathankudikadu Village, Thelur Post, Ariyalur District 621704'},
    {key:'3018', label:'University College of Engineering, Thirukkuvalai, Nagappattinam District 610204'},
    {key:'3019', label:'University College of Engineering, Panruti, Chennai-Kumbakonam Highway, Panikkankuppam, Panruti, Cuddalore District 607106'},
    {key:'3021', label:'University College of Engineering, Pattukkottai, ECR Road, Rajamadam, Pattukkottai Taluk, Thanjavur District 614701'},
    {key:'3403', label:'Mahalakshmi Engineering College, Trichy-Salem Highways, (Near No.1 Tollgate), Melpathu, Siruganbur Post, Tiruchirappalli District 621213'},
    {key:'3410', label:'Krishnaswamy Collge of Engineering and Technology, S Kumarapuram, Cuddalore District 607109'},
    {key:'3425', label:'C K College of Engineering and Technology, Chellangkuppam, Cuddalore District 607003'},
    {key:'3451', label:'SMR East Coast College of Engineering & Technology, East Coast Road, Somanathapattinam, Thanjavur District 614612'},
    {key:'3454', label:'Sri Ramakrishna College of Engineering, Sri Saradha Nagar, NH-45, Perambalur District  621113'},
    {key:'3456', label:'K S K College of Engineering and Technology, Thanjavur Main Road, Darasuram, Kumbakonam, Thanjavur District 612702'},
    {key:'3457', label:'Dhanalakshmi Srinivasan College of Engineering, Thuraiyur Road, Perambalur District 621212'},
    {key:'3458', label:'Dhanalakshmi Srinivasan Institute of Research and Technology, NH-45, Trichy–Chennai Main Road, Siruvachur, Perambalur District 621113'},
    {key:'3459', label:'Dhanalakshmi Srinivasan Institute of Technology,  Samayapuram, Chennai-Trichy Main Road, Tiruchirappalli District 621112'},
    {key:'3460', label:'Sureya College of Engineering, Konalai, Tiruchirappalli District 621132'},
    {key:'3461', label:'Arifa Institute of Techonology, Esanoor, Keelaiyur Post, Thirukkuvalai Taluk, Nagapattinam District 611103'},
    {key:'3462', label:'Ariyalur Engineering College, NH-227, Trichy-Chithambaram NH, Karuppur-Senapathy Post, Ariyalur District 621707'},
    {key:'3463', label:'Elizabeth College of Engineering and Technology, Annamangalam Post, Veppanthattai Taluk, Perambalur District 621102'},
    {key:'3464', label:'Government College of Engineering, Gandarvakottai Road, Sengipatti, Thanjavur District 613402'},
    {key:'3465', label:'Government College of Engineering, Srirangam, Sethurappatti,  Trichy District 620 012'},
    {key:'3466', label:'Nelliandavar Institute of Technology, Nerunjikorai Village, Pudhupalayam, Ariyalur Taluk, Ariyalur District 621704'},
    {key:'3701', label:'K Ramakrishnan College of Technology, Kariyamanickam Road, Samayapuram, Manachanallur Taluk, Tiruchirappalli District 621112'},
    {key:'3760', label:'Sir Issac Newton College of Engineering and Technology, Anthanapeetai Post, Papakoil, Nagappattinam District 611102'},
    {key:'3766', label:'Star Lion College of Engineering & Technology, Manankorai Main Road, Manankorai Village, Thanjavur District 614206'},
    {key:'3782', label:'OASYS Institute of Technology , Pulivalam Village, Musiri Taluk, Tiruchirappalli District 626001'},
    {key:'3786', label:'M.A.M. School of Engineering, Siruganur, Tiruchirappalli District 621105'},
    {key:'3795', label:'SRM TRP Engineering College, Irungalur, Manachanallur Taluk, Tiruchirappalli District 621105'},
    {key:'3801', label:'A V C College of Engineering, Mannampandal Post, Mayiladuthurai, Nagappattinam District 609305'},
    {key:'3802', label:'Shri Angalamman College of Engineering and Technology  Siruganoor, Tiruchirappalli District 621105'},
    {key:'3803', label:'Anjalai Ammal-Mahalingam Engineering College, Kovilvenni, Thiruvarur District 614403'},
    {key:'3804', label:'Arasu Engineering  College, Chennai Main Road, Kumbakonam, Thanjavur District 612501'},
    {key:'3805', label:'Dhanalakshmi Srinivasan  Engineering College, Thuraiyur Road, Perambalur District 621212'},
    {key:'3806', label:'E G S Pillay  Engineering College (Autonomous), Nagapattinam District 611002'},
    {key:'3807', label:'J J College of Engineering and Technology, Poolankulathupatti Post, Thiruchirappalli District 620009'},
    {key:'3808', label:'Jayaram College of Engineering and Technology , Pagalavadi Post, Tiruchirappalli District 621014'},
    {key:'3809', label:'Kurinji College of Engineering and Technology, Manapparai, Tiruchirappalli District 621307'},
    {key:'3810', label:'M.A.M. College of Engineering, Siruganur, Tiruchirappalli District 621105'},
    {key:'3811', label:'M I E T Engineering College, Trichy-Pudukkottai Road, Tiruchirappalli District 620007'},
    {key:'3812', label:'Mookambigai College of Engineering, Keeranur, Pudukkottai District 622502'},
    {key:'3813', label:'Oxford Engineering College, Pirattiyur(W), Tiruchirappalli District 620009'},
    {key:'3814', label:'P R Engineering College, Vallam, Thanjavur District 613403'},
    {key:'3815', label:'Pavendhar Bharathidasan College of Engineering and Technology, Pudukkottai Main Road, Tiruchirappalli District 620024'},
    {key:'3817', label:'Roever Engineering College, Elambalur, Perambalur District 621212'},
    {key:'3819', label:'Saranathan College of  Engineering, Panjappur, Tiruchirappalli District  620012'},
    {key:'3820', label:'Trichy Engineering College, Konalai, Tiruchirappalli District 621132'},
    {key:'3821', label:'A R J College of Engineering and Technology, Mannargudi Taluk, Thiruvarur District 614001'},
    {key:'3822', label:'Dr. Navalar Nedunchezhian College of Engineering, Tholudur, Cuddalore District 606303'},
    {key:'3823', label:'Srinivasan Engineering College, Thuraiyur Road, Perambalur District 621212'},
    {key:'3825', label:'St. Josephs College Of Engineering And Technology, Elupatti Village, Rawaspatti Post, Thanjavur District 613403'},
    {key:'3826', label:'Kongunadu College of Engineering and Technology, Tholurpatti, Thottiyam, Tiruchirappalli District 621215'},
    {key:'3829', label:'M.A.M. College of Engineering and Technology, Trichy-Chennai Trunk Road, Siruganur, Tiruchirappalli District 621105'},
    {key:'3830', label:'K Ramakrishnan College of Engineering, Kariyamanickam Road, Samayapuram, Manachanallur Taluk, Tiruchirappalli District 621112'},
    {key:'3831', label:'Indra Ganesan College of Engineering, Madurai Main Road, Manikandam, Tiruchirappalli District 620012'},
    {key:'3833', label:'Parisutham Institute of Technology and Science, Ring Road, Nanjikottai, Thanjavur District 613006'},
    {key:'3841', label:'C.A.R.E Group of Institutions, Thayanoor Village, Thayanoor Village, Kuttappatti, Srirangam Taluk, Tiruchirappalli District 620009'},
    {key:'3843', label:'M R K Institute of Technology, Nattarmangalam Village, Kattumannarkoil, Cuddalore District 608306'},
    {key:'3844', label:'Shivani Engineering College, Ammapettai, Srirangam, Tiruchirappalli District 620009'},
    {key:'3845', label:'Imayam College of Engineering, Kannanur, Thuraiyur, Tiruchirappalli District 621206'},
    {key:'3846', label:'Mother Terasa College of Engineering and Technology, Mettusalai Post and Taluk, Pudukkottai District 622102'},
    {key:'3847', label:'Roever College of Engineering and Technology, Elambalur, Perambalur District 621212'},
    {key:'3848', label:'Vandayar Engineering College, Pulavarnatham Post, Mariamman Koil (Via), Thanjavur District 613001'},
    {key:'3849', label:'Annai College of Engineering and Technology, Kovilacheri, Kumbakonam, Thanjavur District 612503'},
    {key:'3850', label:'Vetri Vinayaha College of Engineering and Technology, Namakkal-Trichy Main Road, Tholurpatti Village, Thottiam, Tiruchirappalli District 621215'},
    {key:'3852', label:'Sri Bharathi Engineering College for Women, Kaikkuruchi Village, Alangudi Taluk, Pudukkottai District 622303'},
    {key:'3854', label:'Mahath Amma Institute of Engineering and Technology (MIET), Ariyur, Annavasal Road, Illupur Taluk, Pudukkottai District 622101'},
    {key:'3855', label:'As-Salam College of Engineering and Technology, Thirumangalakudi, Aduthurai, Thiruvidaimaruthur, Thanjavur District 612102'},
    {key:'3857', label:'Meenakshi Ramaswamy Engineering College, Trichy Main Road,  Thathanur, Udayarpalayam, Ariyalur District 621804'},
    {key:'3859', label:'Sembodai Rukmani Varatharajan Engineering College, Sembodai Village, Vedaraniam, Nagappattinam District 614820'},
    {key:'3860', label:'St. Annes College of Engineering and Technology, Anguchettypalayam, Siruvathur Post, Panruti, Cuddalore District 607110'},
    {key:'3905', label:'Kings College of Engineering, Punalkulam, Pudukkottai District 613303'},
    {key:'3908', label:'Mount Zion College of Engineering and Technology, Pilivalam Post, Pudukkottai District 622507'},
    {key:'3918', label:'Shanmuganathan Engineering College, Pillivalam Post, Pudukkottai District 622507'},
    {key:'3920', label:'Sudharsan Engineering College, Sathiyamangalam Post, Pudukkottai District 622501'},
    {key:'3923', label:'M N S K College of Engineering, Vallathirakkottai Post, Pudukkottai District 622305'},
    {key:'3925', label:'M A R College of Engineering and Technology, Boothakudi, Rasanaickenpatty Post, Illuppur Taluk, Pudukkottai District 621316'},
    {key:'4020', label:'Anna University Regional Campus - Tirunelveli, Trivandrum Road, Palayamkottai, Tirunelveli District 627007'},
    {key:'4023', label:'University College of Engineering, Nagercoil, Nagercoil Industrial Estate, Konam, Kanyakumari District 629004'},
    {key:'4024', label:'University V.O.C. College of Engineering, Thoothukudi, Near V.O.C. College, Millerpuram, Thoothukudi District 628008'},
    {key:'4669', label:'Thamirabharani Engineering College, Chathirampudukulam Village, Thatchanallur, Tirunelveli District 625358'},
    {key:'4670', label:'Rohini College of Engineering & Technology, Anjugramam-Kanyakumari Main Road, Palkulam, Variyoor Post, Kanyakumari District 629401'},
    {key:'4672', label:'Stella Marys College of Engineering, Arunthengan Vilai, Azhikal Post, Kanyakumari District 629202'},
    {key:'4675', label:'Universal College of Engineering and Technology, Anbagam Campus, Radhapuram Road, Vallioor, Tirunelveli District 627117'},
    {key:'4676', label:'Renganayagi Varatharaj College of Engineering, Salvarpatti, Sivakasi, Virudhunagar District 626128'},
    {key:'4677', label:'Lourdes Mount College of Engineering and Technology, Marthandam-Karungal Road, Chundavilai, Mullanganavilai, Nattalam Post, Kanyakumari District 629195'},
    {key:'4678', label:'Ramco Institute of Technology, North venganallur Village, Krishnapuram Panchayat, Rajapalayam, Virudhunagar District 626117'},
    {key:'4680', label:'AAA College of Engineering and Technology, Kamarajar Educational Road, Amathur Village, Sivakasi, Virudhunagar District 626005'},
    {key:'4686', label:'Good Shepherd College of Engineering and Technology, Maruthamparai, Kanyakumari District 629101'},
    {key:'4864', label:'V V College of Engineering, Arasoor Village, Idaichivilai Post, Santhakulam Taluk, Thoothukudi District 628656'},
    {key:'4917', label:'Sethu Institute of Technology (Autonomous), Kariapatti Post, Virudhunagar District 626106'},
    {key:'4927', label:'Maria College of Engineering and Technology, Attoor Puliyamoodu Junction, Thiruvattar Post, Kanyakumari District 629177'},
    {key:'4928', label:'MAR Ephraem College of Engineering & Technology, Malankara Hills, Elavuvillai, Marthandam, Kanyakumari District 629171 '},
    {key:'4929', label:'M E T Engineering College, Mogals Garden, Thovalai Village, Aralvaimozhi Town Panchayat, Thovalai, Kanyakumari District 629304'},
    {key:'4931', label:'Chandy College of Engineering, Chandy Nagar, Mullakkadu, Thoothukudi District 628005'},
    {key:'4932', label:'Immanuel Arasar J J College of Engineering, Anappara, Edavilagam, Nattalam, Kanyakumari District 629195'},
    {key:'4933', label:'St. Mother Theresa Engineering College, Vagaikulam, Thoothukudi District 628102'},
    {key:'4934', label:'Holy Cross Engineering College, Vagaikulam, Sri Mulakarai, Srivaikuntam,  Thoothukudi District 628851'},
    {key:'4937', label:'A R College of Engineering and Technology, Kadayam-Alangulam Road, Therikkumadathoor Post, Tirunelveli District 627423'},
    {key:'4938', label:'Sivaji College of Engineering and Technology, Manivilla Palulai Panchayat, Vilavancode, Kanyakumari District 629170'},
    {key:'4941', label:'Unnamalai Institute of Technology, Ayyaneri, Kovilpatti, Thoothukudi District 628502'},
    {key:'4943', label:'Satyam College of Engineering & Technology, Kannappannallur, Aralvaimozhi, Kanyakumari District 629301'},
    {key:'4944', label:'Arunachala College of Engineering for Women, Thanka Gardens, Manavilai, Vellichanthai, Nagercoil, Kanyakumari District 629203'},
    {key:'4945', label:'Vins Christian Womens College of Engineering, Chunkankadai Post, Nagercoil, Kanyakumari District 629807'},
    {key:'4946', label:'D M I Engineering College, Aralvaimozhi Village, Thovalai, Kanyakumari District 629301'},
    {key:'4948', label:'Rajas International Institute of Technology for Women, Ozhuginasery, Nagercoil, Kanyakumari District 629001'},
    {key:'4949', label:'P S N Institute of Technology & Science, Melathediyoor, Tirunelveli District 627152'},
    {key:'4952', label:'C S I Institute of Technology, Thovalai, Kanyakumari District 629302'},
    {key:'4953', label:'CAPE Institute of Technology, Levingipuram, Tirunelveli District 627114'},
    {key:'4954', label:'Dr. Sivanthi Aditanar College of Engineering, Tiruchendur, Thoothukudi District 628215'},
    {key:'4955', label:'Francis Xavier Engineering College, Vannarpettai, Tirunelveli District 627003'},
    {key:'4957', label:'Jayaraj Annapackiam CSI College of Engineering, Nazareth, Thoothukudi District 628617'},
    {key:'4959', label:'Kamaraj College of Engineering and Technology, Virudhunagar District 626001'},
    {key:'4960', label:'Mepco Schlenk Engineering College (Autonomous), Sivakasi, Virudhunagar District 626005'},
    {key:'4961', label:'National College of Engineering, Maruthakulam Post, Tirunelveli District 627151'},
    {key:'4962', label:'National  Engineering College (Autonomous), Kovilpatti, Thoothukudi District 628503'},
    {key:'4964', label:'P S N College of Engineering and Technology (Autonomous), Melathediyoor, Tirunelveli Dstrict 627152'},
    {key:'4965', label:'P S R  Engineering College (Autonomous), Appayanaickenpatti, Sevalpatti, Virudhunagar District 626140'},
    {key:'4966', label:'PET Engineering College, Vallioor Post, Tirunelveli District 627117'},
    {key:'4967', label:'S Veerasamy Chettiar College of  Engineering and Technology, Puliangudi Post, Tenkasi District 627855'},
    {key:'4968', label:'Sardar Raja College of Engineering, Alangulam,  Tenkasi District 627808'},
    {key:'4969', label:'SCAD College of Engineering and Technology, Cheranmahadevi, Tirunelveli District 627414'},
    {key:'4970', label:'Sree Sowdambiga  College of Engineering, Aruppukkottai Taluk, Virudhunagar District 626134'},
    {key:'4971', label:'St. Xavier Catholic College of Engineering, Chunkankadai, Nagercoil, Kanyakumari District 629807'},
    {key:'4972', label:'Amrita College of Engineering and Technology, Erachakulam Post, Kanyakumari District 629902'},
    {key:'4973', label:'Rajas Engineering College , Vadakkangulam, Tirunelveli District 627116'},
    {key:'4974', label:'Government College of Engineering, Tirunelveli District 627007'},
    {key:'4975', label:'Dr. G U Pope College of Engineering, Sawyerpuram, Thoothukudi District  628251'},
    {key:'4976', label:'Infant Jesus College of Engineering, Thoothukudi Highway (NH7/A), Thoothukudi District 628851'},
    {key:'4977', label:'Narayanaguru College of Engineering, Manjalumoodu, Kanyakumari District 629151'},
    {key:'4978', label:'Udaya School of Engineering, Ammandivilai Post, Kanyakumari District 629204'},
    {key:'4979', label:'V P Muthaiah Pillai Meenakshi Ammal Engineering College for Women, Srivilliputhur Taluk, Virudhunagar District 626190'},
    {key:'4980', label:'Einstein College of Engineering, Seethaparpanallur, Tirunelveli District 627012'},
    {key:'4981', label:'Ponjesly College of  Engineering, Vettornimadam Post, Nagercoil, Kanyakumari District 629003'},
    {key:'4982', label:'Vins Christian College of Engineering, Chunkankadai Post, Nagercoil, Kanyakumari District  629807'},
    {key:'4983', label:'Lord Jegannath College of Engineering and Technology, Kumarapuram, Thoppur Post, Kanyakumari District 629402'},
    {key:'4984', label:'Marthandam College of Engineering & Technology, Kuttakuzhi, Veeyanoor Post, Kanyakumari District 629177'},
    {key:'4989', label:'P S N Engineering College, Melathediyoor, Tirunelveli District 627152'},
    {key:'4992', label:'Bethlahem Institute of Engineering, Karungal, Kanyakumari District 629157'},
    {key:'4993', label:'Loyola Institute of Technology and Science, Loyola Nagar,  P B No.2, Thovalai, Kanyakumari District 629302'},
    {key:'4994', label:'J P College of Engineering, College Road, Ayakudy, Tenkasi Taluk,  Tenkasi District 627852'},
    {key:'4995', label:'P S R Rengasamy College of Engineering for Women, Appayanaickenpatti, Sevalpatti, Virudhunagar District 626140'},
    {key:'4996', label:'Sri Vidhya College of Engineering and Technology, Sivakasi Main Road, P Kumaralinapuram, Virudhunagar District 626005'},
    {key:'4998', label:'Mahakavi Bharathiyar College of Engineering and Technology, Vasudevanallur, Tenkasi District 627758'},
    {key:'4999', label:'Annai Vailankanni College of Engineering, Pothaiyadi Salai, Pottalkulam, Azhagappapuram, Kanyakumari District 629401'},
    {key:'5008', label:'Thiagarajar College of Engineering (Autonomous), Tirupparankundram, Madurai District 625015'},
    {key:'5009', label:'Government College of Engineering,  Melachokkanathapuram, Bodinayakkanur, Theni District 625 582'},
    {key:'5010', label:'Anna University Regional Campus - Madurai, Kanyakumari National Highway, Keelakuilkudi, Madurai District 625019'},
    {key:'5012', label:'Central Electrochemical Research Institute, (CECRI), Karaikudi, Sivagangai District 630006'},
    {key:'5017', label:'University College of Engineering, Ramanathapuram, Pullangudi, Ramanathapuram District 623513'},
    {key:'5022', label:'University College of Engineering, Dindigul, Mangarai Pirivu, Reddiyarchathiram, Dindigul District 624622'},
    {key:'5502', label:'Sree Raaja Raajan College of Engineering & Technology, Amaravathi Village, Amaravathi Pudur Post, Karaikudi, Sivagangai District 630301'},
    {key:'5530', label:'SSM Institute of Engineering and Technology, Kuttathupatti Village, Sindalaigundu Post, Dindigul District 624002'},
    {key:'5532', label:'Vaigai College of Engineering, Therkutheru, Melur Taluk, Madurai District 625122'},
    {key:'5533', label:'Karaikudi Institute of Technology, KIT & KIM Technical Campus, Keeranipatti, Thalakkuvur, Karaikudi, Sivagangai District 630307'},
    {key:'5536', label:'Mangayarkarasi College of Engineering, First Street, Mangayarkarasi Nagar, Paravai, Madurai District 625402'},
    {key:'5537', label:'Jainee College of Engineering and Technology, Dindigul-Theni Main Road (Near Vakkampatti), Dindigul District 624303'},
    {key:'5703', label:'Christian College of Engineering and Technology, Oddanchatram, Dindigul District 624619'},
    {key:'5720', label:'Sri Subramanya College of Engineering and Technology, Sukkamanaickanpatti, Palani, Dindigul District 624615'},
    {key:'5832', label:'N P R College of Engineering and Technology, Natham, Dindigul District 624003'},
    {key:'5842', label:'Madurai Institute of Engineering & Technology, Pottapalayam Village, Manamadurai Taluk, Sivagangai District 630611 '},
    {key:'5851', label:'Veerammal Engineering College, K Singarakottai, Dindigul District 624708'},
    {key:'5862', label:'R V S Educational Trust’s Groups of Institutions (Integrated Campus), N Paraipatti Post, Vedasandur Taluk, Dindigul District 624005'},
    {key:'5865', label:'Nadar Saraswathi College of Engineering and Technology, Vadupudupatti, Annanji Post, Theni District 625531'},
    {key:'5901', label:'Alagappa Chettair Government College of Engineering and Technology (Autonomous), Karaikudi, Sivagangai District 630004'},
    {key:'5902', label:'Bharath Niketan Engineering College, Thimmarasanaickanoor, Aundipatti, Theni District 625536'},
    {key:'5903', label:'K L N College of Information Technology, Pottapalayam, Sivagangai District  630611'},
    {key:'5904', label:'K L N College of Engineering, Pottapalayam, Sivagangai District 630611'},
    {key:'5907', label:'Mohamed Sathak Engineering College, Kilakarai, Ramanathapuram District 623806'},
    {key:'5910', label:'P S N A Colllege of Engineering and Technology, Dindigul District 624622'},
    {key:'5911', label:'P T R College of Engineering and Technology, Austinpatty Post, Madurai District 625008'},
    {key:'5912', label:'Pandian Saraswathi Yadav Engineering College, Thirumansolai Post, Sivagangai District 630 561'},
    {key:'5913', label:'Ratnavel Subramaniam College of Engineering and Technology, N Paraipatti Post, Dindigul District 624005'},
    {key:'5914', label:'SOLAIMALAI COLLEGE OF ENGINEERING, Veerapanjan, Madurai District 625020'},
    {key:'5915', label:'SACS-M A V M M Engineering College, Kidaripatty Post, Madurai District 625001'},
    {key:'5919', label:'St. Michael College of Engineering and Technology, Kalayarkoil, Sivagangai District 630551'},
    {key:'5921', label:'Syed Ammal Engineering College, Achunthanvayal Post, Ramanathapuram District-623502'},
    {key:'5922', label:'Vickram College of Engineering, Enathi Post, Sivagangai District 630561'},
    {key:'5924', label:'Ganapathy Chettiar College of Engineering and Technology, Melakavanur Post, Paramakudi, Ramanathapuram District 623706'},
    {key:'5930', label:'SBM College of Engineering and Technology, Thamaraipady, T N Paraipatti Pirivu, Dindigul District 624005'},
    {key:'5935', label:'Fatima Michael College of Engineering & Technology, Sengottai Village, Sivagangai Main Road, Madurai District 625020'},
    {key:'5942', label:'Ultra College of Engineering & Technology , Kodikulam, 1 Bit Village, Madurai-Chennai Highway, Madurai District 625104'},
    {key:'5986', label:'Velammal College of Engineering and Technology, Madurai - Rameshwaram High Road, Viraganoor, Madurai District 625009'},
    {key:'5988', label:'Theni Kammavar Sangam College of Technology, Theni Main Road, Koduvillarpatti Post, Theni District 625534'},
    {key:'5990', label:'Latha Mathavan Engineering College, Kidaripatti Post, Alagarkoil (Via), Melur Taluk, Madurai District 625301'}];

const DEPT_LIST = [{ key:'AE', label:'Aeronautical Engineering' },
    { key:'AG', label:'Agricultural Engineering' },
    { key:'AI', label:'Agricultural and Irrigation Engineering (SS)' },
    { key:'AP', label:'Apparel Technology (SS)' },
    { key:'AR', label:'Architecture' },
    { key:'AS', label:'Automobile Engineering (SS)' },
    { key:'AU', label:'Automobile Engineering' },
    { key:'BM', label:'Bio- Medical Engineering' },
    { key:'BS', label:'Bio Technology (SS)' },
    { key:'BT', label:'Bio Technology' },
    { key:'BY', label:'Bio- Medical Engineering (SS)' },
    { key:'CC', label:'Chemical and Electro Chemical Engineering (SS)' },
    { key:'CE', label:'Civil Engineering' },
    { key:'CH', label:'Chemical Engineering' },
    { key:'CL', label:'Chemical Engineering (SS)' },
    { key:'CM', label:'Computer Science and Engineering (SS)' },
    { key:'CN', label:'Civil Engineering (SS)' },
    { key:'CP', label:'Civil Engg. and Planning' },
    { key:'CR', label:'Ceramic Technology (SS)' },
    { key:'CS', label:'Computer Science and Engineering' },
    { key:'EC', label:'Electronics and Communication Engineering' },
    { key:'EE', label:'Electrical and Electronics Engineering' },
    { key:'EI', label:'Electronics and Instrumentation Engineering' },
    { key:'EM', label:'Electronics and Communication Engg. (SS)' },
    { key:'EN', label:'Environmental Engg.' },
    { key:'ES', label:'Electrical and Electronics (Sandwich) (SS)' },
    { key:'ET', label:'Electronics and Telecommunication Engg.' },
    { key:'EX', label:'Electronics and Instrumentation Engg. (SS)' },
    { key:'EY', label:'Elec. And Electronics Engg (SS)' },
    { key:'FD', label:'Food Technology' },
    { key:'FS', label:'Food Technology (SS)' },
    { key:'FT', label:'Fashion Technology' },
    { key:'FY', label:'Fashion Technology (SS)' },
    { key:'GI', label:'Geo-Informatics' },
    { key:'HT', label:'Handloom and Textile Technology' },
    { key:'IB', label:'Industrial Bio-Technology' },
    { key:'IC', label:'Instrumentation and Control Engineering' },
    { key:'IE', label:'Industrial Engineering' },
    { key:'IM', label:'Information Tech. (SS)' },
    { key:'IN', label:'Industrial Engineering and Management' },
    { key:'IS', label:'Industrial Bio-Tech. (SS)' },
    { key:'IT', label:'Information Technology' },
    { key:'IY', label:'Instrumentation and Control Engineering (SS)' },
    { key:'LE', label:'Leather Technology' },
    { key:'MA', label:'Material Science and Engineering (SS)' },
    { key:'MC', label:'Mechatronics' },
    { key:'MD', label:'Medical Electronics Engg.' },
    { key:'ME', label:'Mechanical Engineering' },
    { key:'MF', label:'Mechanical Engg. (SS)' },
    { key:'MG', label:'Mechatronics (SS)' },
    { key:'MH', label:'Mechanical Engineering (Sandwich)' },
    { key:'MI', label:'Mining Engineering' },
    { key:'MN', label:'Manufacturing Engineering' },
    { key:'MR', label:'Marine Engineering' },
    { key:'MS', label:'Mechanical Engineering (Sandwich) (SS)' },
    { key:'MT', label:'Metallurgical Engineering' },
    { key:'MU', label:'Mechanical  and Automation Engineering' },
    { key:'MY', label:'Metallurgical Engg. (SS)' },
    { key:'NS', label:'Nano Science and Technology' },
    { key:'PA', label:'Plastic Technology' },
    { key:'PC', label:'Petro Chemical Technology' },
    { key:'PD', label:'Petrochemical Engineering' },
    { key:'PE', label:'Petroleum Engineering' },
    { key:'PH', label:'Pharmaceutical Technology' },
    { key:'PL', label:'Polymer Technology' },
    { key:'PM', label:'Pharmaceutical Tech (SS)' },
    { key:'PN', label:'Production Engineering (SS)' },
    { key:'PP', label:'Petroleum Engineering and Technology (SS)' },
    { key:'PR', label:'Production Engineering' },
    { key:'PS', label:'Production Engineering (Sandwich) (SS)' },
    { key:'PT', label:'Printing Technology' },
    { key:'RA', label:'Robotics and Automation (SS)' },
    { key:'RM', label:'Robotics and Automation' },
    { key:'RP', label:'Rubber and Plastic Tech.' },
    { key:'TC', label:'Textile Chemistry' },
    { key:'TT', label:'Textile Technology (SS)' },
    { key:'TX', label:'Textile Technology' },
    { key:'XC', label:'Civil Engineering (Tamil Medium)' },
    { key:'XM', label:'Mechanical Engineering (Tamil Medium)' },
    { key:'AO', label:'Aerospace Engineering' }];

const ELIGIBILITY_TYPE=[{key:'studiedInTN',label:'From 8th to 12th studied in Tamil nadu'},
    {key:'parentGovtOfficer',label:'My parent is Central/State Government/ Public sector Employee for past five years in Tamil nadu'},
    {key:'allIndiaTamilnaduCadre',label:'My parent is serving as All India Tamil nadu cadre officer'}];

const HSC_ACADEMIC_GROUP_CODES = [{key:'1501', label:'Physics/ Chemistry/ Maths/ Statistics'},
    {key:'1502',label: 'Physics/ Chemistry/ Maths/ Computer Science'},
    {key:'1503',label:'Physics/ Chemistry/ Maths/ Biology'},
    {key:'1504',label:'Physics/ Chemistry/ Maths/ Bio-Chemistry'},
    {key:'1505',label:'Physics/ Chemistry/ Maths/ English for communication'},
    {key:'1506',label:'Physics/ Chemistry/ Maths/ Home Science'}];

const HSC_VOCATIONAL_GROUP_CODES = [{key:'2921', label:'Basic Mechanical Engineering'},
    {key:'2922',label: 'Basic Electrical Engineering'},
    {key:'2923',label:'Basic Electronics Engineering'},
    {key:'2924',label:'Basic Civil Engineering'},
    {key:'2925',label:'Basic Automobile Engineering'},
    {key:'2926',label:'Textile Technology'}];

const OTHER_GROUP_CODES = [{key:'0001',label:'Physics/ Chemistry/ Maths/ Optional Subject (0001)'}];

const RESERVED_KEYS = {
    CREATED_AT:'createdAt',
    UPDATED_AT:'updatedAt'
};

const TX_ID_QUERY_PARAM = 'txId';

const TENTATIVE_ALLOTMENT_ACCEPTANCE={
    ACCEPT_AND_CONFIRM_CURRENT_ALLOTMENT:'aacca',
    DECLINE_CURRENT_MOVE_TO_NEXT_ROUND:'dcmtnr',
    DECLINE_AND_QUIT:'daq',
    ACCEPT_CURRENT_AND_OPT_UPWARD:'acoup',
    DECLINE_CURRENT_OPT_UPWARD_NOT_ALLOTTED_IN_UP_MOVE_NEXT_ROUND:'dcounaiumnr',
    IF_ALLOTTED_IN_UPWARD_CONFIRM_ELSE_MOVE_TO_NEXT:'aumcmtn',
    I_WILL_MOVE_TO_NEXT_ROUND:'wmtn'
};

const ALLOTMENT_STATUS = {
    INITIAL_ALLOTMENT_PROCESSED: 'initialAllotmentRun',
    ALLOTMENT_DONE: "allotted",
    ACTIVE_FINAL_ROUND: 'activeFinalRound',
    QUIT: "quit"
};

const GOVT_SCHOOL_TYPE = [{key:"1",label:'ADHIDRAVIDA WELFARE'},{key:"2",label:'CORPORATION'},{key:'3',label:'FOREST'},
    {key:'4',label:'GOVERNMENT'},{key:'5',label:'KALLAR'},{key:'6',label:'MUNICIPALITY'},{key:'7',label: 'TRIBAL WELFARE'}]


const CATEGORY_OF_SCHOOL = [{key:'1',label:'State Govt'},{key:'2',label:'Central Govt'},{key:'3',label:'Govt. Aided'},{key:'5',label:'Private'}]
const DOMAIN_NAME = 'tneaonline.org';

// const S3_UPLOAD_CERTIFICATES_LIST = [
//     {label: "X_CERTIFICATE", key: "x"},
//     {label: "XI_CERTIFICATE", key: "xi"},
//     {label: "XII_CERTIFICATE", key: 'xii'},
//     {label: "DECLARATION", key:'declaration'},
//     {label: "COMMUNITY", key: "community"},
//     {label: "SPORTS_FORM1", key:'sportsForm1'},
//     {label: "SPORTS_FORM2", key:'sportsForm2'},
//     {label: "SPORTS1", key:'sports1'},
//     {label: "SPORTS2", key:'sports2'},
//     {label: "SPORTS3", key:'sports3'},
//     {label: "SPORTS4", key:'sports4'},
//     {label: "EX-SERVICEMEN", key:'exservice'},
//     {label: "DIFF_ABLED_BLIND", key:'diff_abled_blind'},
//     {label: "DIFF_ABLED_DEAF", key:'diff_abled_deaf'},
//     {label: "DIFF_ABLED_LOCOMOTOR", key:'diff_abled_loco'},
//     {label: "DIFF_ABLED_AUTISM", key:'diff_abled_autism'},
//     {label: "DIFF_ABLED_MULTIPLE_DISABILITY", key:'diff_abled_multiple'},
//     {label: "PHOTO", key:'passport_photo'},
//     {label: "SIGNATURE", key:'sign'},
//     {label: "TC_CERTIFICATE", key:'tc'},
//     {label: "NATIVITY_CERTIFICATE", key:'nativity'},
//     {label: "SRILANKAN_REFUGEE", key:'srilankanRefuge'},
//     {label: "ELIGIBILITY_CERTIFICATE", key:"eligibility"},
//     {label: "EXTRA_1", key:'extra1'},
//     {label: "EXTRA_2", key:'extra2'},
//     {label: "EXTRA_3", key:'extra3'},
//     {label: "EXTRA_4", key:'extra4'},
//     {label: "EXTRA_5", key:'extra5'},
//     {label: "GOVERNMENT SCHOOL", key:'GovtSc'},
//     {label: "INCOME", key:'income'},
//     {label: "FIRST GRADUATE", key:'firstGraduate'},
//     {label: "INCOMPLETE - PENDING CERTIFICATES", key:'pendingCertificate'}    
// ];

const CV_SLOTS = [
    {key: 1, label: "9.AM to 10.AM"},
    {key: 2, label: "10.AM to 11.AM"},
    {key: 3, label: "11.AM to 12.PM"},
    {key: 4, label: "12.PM to 1.PM"},
    {key: 5, label: "2.PM to 3.PM"},
    {key: 6, label: "3.PM to 4.PM"},
    {key: 7, label: "4.PM to 5.PM"},
];

const INELIGIBLE_LIST = [{key: 1, label: "Nationality Others", res: "Not eligible to participate in counseling"},
    {key: 2, label: "Nativity Others - Eligibility Certificate not produced", res: "Not eligible to participate in counseling"},
    {key: 3, label: "Srilankan Refugee - Certificates not produced", res: "Not eligible to participate in counseling"},
    {key: 4, label: "Physics Chemistry Mathematics not studied", res: "Not eligible to participate in counseling"},
    {key: 5, label: "Nativity Certificate not produced", res: "Not eligible to participate in counseling"},
    {key: 6, label: "Minimum Marks not obtained", res: "Not eligible to participate in counseling"},
    {key: 7, label: "Fail in a Subject", res: "Not eligible to participate in counseling"},
    {key: 8, label: "Duplicate Application", res: "Eligible to participate in counseling"},
    {key: 9, label: "Mandatory Certificates not Uploaded", res: "Not eligible to participate in counseling"},
    {key: 10, label: "Invalid Community Certificate", res: "Candidate will be considered under OC"},
    {key: 11, label: "Invalid Ex-Serviceman Certificate", res: "Candidate can participate in General Counseling. Not eligible under Ex-Serviceman Category."},
    {key: 12, label: "Invalid Differently abled Certificate", res: "Candidate can participate in General Counseling. Not eligible under Differently abled Category."},
];

const CURR_TRANSFER_REASON = [{key:"1", label: "100% visually impaired", tamilLabel:'(100 % பார்வை குறைபாடு உள்ளோர்)'},
    {key:"2", label: "Physically handicapped to the extent of traveling in a three-wheeler (or) with the help of crutches (Using Calipers)", tamilLabel:'(மூன்று சக்கர வண்டியில் பயணம் செய்யும் அளவு (அ) ஊன்று கோல் (Using Calipers) துணையுடன் தான் நடக்க இயலும் என்னும் அளவிற்கு உடல் ஊனமுற்றவர்கள்)'},
    {key:"3", label: "Faculties having intellectually disabled or differently abled children", tamilLabel: '(அறிவுசார் குறைபாடுடைய (Intellectual disability) அல்லது மாற்றுத்திறனாளி குழந்தைகளுடைய ஆசிரியர்கள்)'},
    {key:"4", label: "Severe Cancer Patients",tamilLabel: '(கடுமையான புற்று நோயாளிகள்)'},
    {key:"5", label: "Faculty who have undergone cardiac surgery or kidney transplant or brain tumor surgery", tamilLabel: '(இருதய அறுவை சிகிச்சை, சிறுநீரக மாற்று மற்றும் மூளை கட்டிக்கான அறுவை சிகிச்சை செய்த ஆசிரியர்கள்)'},
    {key:"6", label: "Spouse serving in army, widows or spinster", tamilLabel: '(கணவன்/மனைவி ராணுவத்தில் பணிபுரிதல், விதவைகள் மற்றும் 40 வயதை கடந்த திருமணம் செய்து கொள்ளாத முதிர் கன்னியர்)'},
    {key:"7", label: "Teachers serving three years or more in Government Colleges located in Hilly areas", tamilLabel: '(மலைப்பிரதேசத்தில் உள்ள அரசு கல்லூரிகளில் மூன்றாண்டும் அதற்கு மேலும் பணிபுரியும் ஆசிரியர்கள்)'},
    {key:"8", label: "Teachers who are working in the same place for  five years or more",tamilLabel: '(ஒரே இடத்தில் குறைந்த பட்சம் ஐந்தாண்டுகள் அதற்கு மேலும் பணியாற்றும் ஆசிரியர்கள்)'},
    {key:"9", label: 'General Non-Priority', tamilLabel: '(பொது முன்னுரிமையற்றது)'}
];

const COMPANY_TYPES = [{key:"1", label: "Office of Central Government",tamilLabel:'(மைய அரசு அலுவலகம்)'},
    {key:"2", label: "Office of State Government", tamilLabel: '(மாநில அரசு அலுவலகம்)'},
    {key:"3", label: "Government Public Sector Undertaking", tamilLabel: '(அரசு பொதுத்துறை நிறுவனம்)'},
    {key:"4", label: "Government School", tamilLabel: '(அரசுப்பள்ளி)'},
    {key:"5", label: "Government Aided School", tamilLabel: '(அரசு உதவி பெறும் பள்ளி)'},
    {key:"6", label: "Private Organisation", tamilLabel: '(தனியார் நிறுவனம்)'}];

const PREV_TRANSFER_REASON= [{key:'1', label:'General Transfer', tamilLabel:'(பொது இடமாறுதல்)'},
    {key:'2', label:'Transfer due to Administrative Reasons', tamilLabel: '(நிர்வாக காரணங்களால் மாறுதல்)'},
    {key:'3', label:'First Appointment', tamilLabel: '(முதல் நியமனம்)'},
    {key:"4", label: 'Personal Request/Promotion', tamilLabel: '(தனிப்பட்ட கோரிக்கை/பதவி உயர்வு)'}
];


    const NDEPT_LIST = [{key:"1010", label:"CIVIL ENGINEERING", category:"poly"},
        {key:"1020", label:"MECHANICAL ENGINEERING", category:"poly"},
        {key:"1030", label:"ELECTRICAL & ELECTRONICS ENGG", category:"poly"},
        {key:"1040", label:"ELECTRONICS & COMMUNICATION ENGG.", category:"poly"},
        {key:"1052", label:"COMPUTER ENGINEERING", category:"poly"},
        {key:"2078", label:"FISHERIES TECHNOLOGY", category:"poly"},
        {key:"1027", label:"MARINE ENGINEERING", category:"poly"},
        {key:"2020", label:"MECHANICAL ENGINEERING (SW)", category:"poly"},
        {key:"1123", label:"MECHANICAL ENGINEERING (CAD)", category:"poly"},
        {key:"2023", label:"Diploma in Automobile Engineering (SW)", category:"poly"},
        {key:"1011", label:"CIVIL ENGINEERING (ARCHITECTUR", category:"poly"},
        {key:"7010", label:"DIPLOMA IN CIVIL ENGINEERING (TAMIL MEDIUM)", category:"poly"},
        {key:"7020", label:"DIPLOMA IN MECHANICAL ENGINEERING (TAMIL MEDIUM)", category:"poly"},
        {key:"1208", label:"PROSTHETICS & ORTHOTICS", category:"poly"},
        {key:"2040", label:"ELECTRONICS & COMMUNICATION ENGINEERING(SW)", category:"poly"},
        {key:"1202", label:"PRINTING TECHNOLOGY", category:"poly"},
        {key:"2074", label:"POLYMER TECHNOLOGY (SW)", category:"poly"},
        {key:"2079", label:"CHEMICAL ENGG. (SW)", category:"poly"},
        {key:"2101", label:"LEATHER TECHNOLOGY (SW)", category:"poly"},
        {key:"1060", label:"TEXTILE TECHNOLOGY", category:"poly"},
        {key:"1081", label:"MODERN OFFICE PRACTICE / COMMERCIAL PRACTICE ", category:"poly"},
        {key:"1082", label:"OFFICE MANAGEMENT AND COMPUTER APPLICATIONS", category:"poly"},
        {key:"1021", label:"AUTOMOBILE ENGINEERING", category:"poly"},
        {key:"1025", label:"PRODUCTION ENGINEERING", category:"poly"},
        {key:"1146", label:"ECG TECHNOLIGUES", category:"poly"},
        {key:"1042", label:"INSTRUMENTATION & CONTROL ENGG.", category:"poly"},
        {key:"1074", label:"SUGAR TECHNOLOGY", category:"poly"},
        {key:"2073", label:"PLASTIC TECHNOLOGY ", category:"poly"},
        {key:"2075", label:"PLASTIC TECHNOLOGY (SW)", category:"poly"},
        {key:"1094", label:"Diploma in Logistics Technology", category:"poly"},
        {key:"1057", label:"WEB DESIGNING", category:"poly"},
        {key:"1145", label:"BIO MEDICAL ELECTRONICS", category:"poly"},
        {key:"1046", label:"INFORMATION TECHNOLOGY", category:"poly"},
        {key:"1047", label:"MECHATRONICS ENGG.", category:"poly"},
        {key:"1066", label:"GARMENT TECHNOLOGY", category:"poly"},
        {key:"2060", label:"TEXTILE TECHNOLOGY (SW)", category:"poly"},
        {key:"1023", label:"1023-AGRICULTURAL ENGINEERING", category:"poly"},
        {key:"1220", label:"TOOL AND DIE ENGINEERING", category:"poly"},
        {key:"1012", label:"ARCHITECTURAL ASSISTANTSHIP", category:"poly"},
        {key:"1048", label:"ENVIRONMENTAL ENGG", category:"poly"},
        {key:"1211", label:"COSMETOLOGY", category:"poly"},
        {key:"1014", label:"INTERIOR DECORATION", category:"poly"},
        {key:"1148", label:"CIVIL ENGINEERING (ENVIRONMENTAL ENGINEERING)", category:"poly"},
        {key:"2050", label:"DIPLOMA IN COMPUTER TECHNOLOGY (SANDWICH)", category:"poly"},
        {key:"CE", label:"CIVIL ENGINEERING ", category:"engg"},
        {key:"CS", label:"COMPUTER SCIENCE AND ENGINEERING", category:"engg"},
        {key:"EC", label:"ELECTRONICS AND COMMUNICATION ENGINEERING", category:"engg"},
        {key:"EE", label:"ELECTRICAL AND ELECTRONICS ENGINEERING", category:"engg"},
        {key:"ME", label:"MECHANICAL ENGINEERING", category:"engg"},
        {key:"EI", label:"ELECTRONICS AND INSTRUMENTATION ENGINEERING", category:"engg"},
        {key:"IB", label:"INDUSTRIAL BIO TECHNOLOGY", category:"engg"},
        {key:"IT", label:"INFORMATION TECHNOLOGY", category:"engg"},
        {key:"PR", label:"PRODUCTION ENGINEERING", category:"engg"},
        {key:"MT", label:"METALLURGICAL ENGINEERING", category:"engg"},
        {key:"Phy", label:"Physics", category:"both"},
        {key:"Chem", label:"Chemistry", category:"both"},
        {key:"Eng", label:"English", category:"both"},
        {key:"Mat", label:"Mathematics", category:"both"},
        {key:"PD", label:"Physical Education Department", category:"both"},
        {key:"LIB", label:"Library", category:"both"}];


        const S3_UPLOAD_CERTIFICATES_LIST = [
            
            {label: "EXTRA_1", key:'extra1'},
            {label: "EXTRA_2", key: 'extra2'},
            {label: "EXTRA_3", key: 'extra3'},
            {label: "EXTRA_4", key: 'extra4'},
            {label: "EXTRA_5", key: 'extra5'},
            {label: "SIGNED_APPLICATION", key: 'application'}
        ];


const TRANS_COLLEGES_LIST = [
        {
            "key": "101",
            "label": "CENTRAL POLYTECHNIC COLLEGE",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "102",
            "label": "INSTITUTE OF PRINTING TECHNOLOGY",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "103",
            "label": "INSTITUTE OF CHEMICAL TECHNOLOGY",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "104",
            "label": "INSTITUTE OF LEATHER TECHNOLOGY",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "105",
            "label": "INSTITUTE OF TEXTILE TECHNOLOGY",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "106",
            "label": "STATE INSTITUTE OF COMMERCE EDUCATION",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "109",
            "label": "T P E V R GOVERNMENT POLYTECHNIC COLLEGE,VELLORE",
            "category": "poly",
            "district": "36"
        },
        {
            "key": "110",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, KRISHNAGIRI",
            "category": "poly",
            "district": "13"
        },
        {
            "key": "111",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, COIMBATORE",
            "category": "poly",
            "district": "4"
        },
        {
            "key": "112",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, OOTY",
            "category": "poly",
            "district": "26"
        },
        {
            "key": "113",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, ARANTHANGI",
            "category": "poly",
            "district": "19"
        },
        {
            "key": "115",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, TRICHY",
            "category": "poly",
            "district": "29"
        },
        {
            "key": "116",
            "label": "TAMILNADU POLYTECHNIC COLLEGE,MADURAI",
            "category": "poly",
            "district": "14"
        },
        {
            "key": "117",
            "label": "ALAGAPPA POLYTECHNIC COLLEGE,KARAIKUDI",
            "category": "poly",
            "district": "23"
        },
        {
            "key": "118",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, TUTICORIN",
            "category": "poly",
            "district": "28"
        },
        {
            "key": "119",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, NAGERCOIL",
            "category": "poly",
            "district": "11"
        },
        {
            "key": "120",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, PURASAWALKAM",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "121",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, MELUR",
            "category": "poly",
            "district": "14"
        },
        {
            "key": "122",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,BAISUHALLI, DHARMAPURI",
            "category": "poly",
            "district": "6"
        },
        {
            "key": "123",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, KARUR",
            "category": "poly",
            "district": "12"
        },
        {
            "key": "124",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, PERAMBALUR",
            "category": "poly",
            "district": "18"
        },
        {
            "key": "125",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, THIRUVARUR",
            "category": "poly",
            "district": "35"
        },
        {
            "key": "126",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, THENI",
            "category": "poly",
            "district": "27"
        },
        {
            "key": "127",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, THIRUVANNAMALAI",
            "category": "poly",
            "district": "34"
        },
        {
            "key": "128",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, VILLUPURAM",
            "category": "poly",
            "district": "37"
        },
        {
            "key": "129",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, ANDIPATTI",
            "category": "poly",
            "district": "27"
        },
        {
            "key": "130",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, JOLARPATTAI",
            "category": "poly",
            "district": "36"
        },
        {
            "key": "131",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, KILAPALURE",
            "category": "poly",
            "district": "1"
        },
        {
            "key": "132",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, PERUNDURAI",
            "category": "poly",
            "district": "8"
        },
        {
            "key": "133",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, THIRUMANGALAM",
            "category": "poly",
            "district": "14"
        },
        {
            "key": "134",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, SANKARAPURAM",
            "category": "poly",
            "district": "37"
        },
        {
            "key": "135",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, CHEYYAR",
            "category": "poly",
            "district": "34"
        },
        {
            "key": "136",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, SRIRANGAM",
            "category": "poly",
            "district": "29"
        },
        {
            "key": "137",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, GANDHARVAKOTTAI",
            "category": "poly",
            "district": "19"
        },
        {
            "key": "138",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, USILAMPATTI",
            "category": "poly",
            "district": "14"
        },
        {
            "key": "139",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, UTHANGARAI",
            "category": "poly",
            "district": "13"
        },
        {
            "key": "140",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,RK NAGAR",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "141",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, KADATHUR",
            "category": "poly",
            "district": "6"
        },
        {
            "key": "142",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,KELAMANGALAM",
            "category": "poly",
            "district": "13"
        },
        {
            "key": "143",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, THANJAVUR",
            "category": "poly",
            "district": "25"
        },
        {
            "key": "144",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE, VALANGAIMAN",
            "category": "poly",
            "district": "35"
        },
        {
            "key": "145",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,PALACODE,DHARMAPURI",
            "category": "poly",
            "district": "6"
        },
        {
            "key": "146",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,NAMAKKAL",
            "category": "poly",
            "district": "17"
        },
        {
            "key": "147",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,CUDDALORE",
            "category": "poly",
            "district": "5"
        },
        {
            "key": "148",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,THIRUVALLUR",
            "category": "poly",
            "district": "33"
        },
        {
            "key": "149",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE,SALEM",
            "category": "poly",
            "district": "22"
        },
        {
            "key": "175",
            "label": "DR DHARMAMBAL GOVT POLYTECHNIC COLLEGE FOR WOMEN",
            "category": "poly",
            "district": "3"
        },
        {
            "key": "176",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE (WOMEN), COIMBATORE",
            "category": "poly",
            "district": "4"
        },
        {
            "key": "177",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE FOR WOMEN, MADURAI",
            "category": "poly",
            "district": "14"
        },
        {
            "key": "178",
            "label": "B C M GOVT. POLYTECHNIC COLLEGE FOR WOMEN",
            "category": "poly",
            "district": "28"
        },
        {
            "key": "218",
            "label": "SRINIVASA SUBBARAYA POLYTECHNIC COLLEGE",
            "category": "poly",
            "district": "16"
        },
        {
            "key": "150",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE - CHROMEPET",
            "category": "poly",
            "district": "2"
        },
        {
            "key": "151",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE - BARGUR",
            "category": "poly",
            "district": "13"
        },
        {
            "key": "152",
            "label": "GOVERNMENT POLYTECHNIC COLLEGE - TIRUNELVELI",
            "category": "poly",
            "district": "30"
        },
        {
            "key": "1516",
            "label": "Thanthai Periyar Government Institute of Technology Bagayam Vellore District 632002",
            "category": "engg",
            "district": "36"
        },
        {
            "key": "2005",
            "label": "Government College of Technology (Autonomous) Thadagam Road Coimbatore District 641013",
            "category": "engg",
            "district": "4"
        },
        {
            "key": "2369",
            "label": "Government College of Engineering Chettikkarai Post Dharmapuri District 635704",
            "category": "engg",
            "district": "6"
        },
        {
            "key": "2603",
            "label": "Government College of Engineering (Autonomous) Bargur Krishnagiri District 635104",
            "category": "engg",
            "district": "13"
        },
        {
            "key": "2615",
            "label": "Government College of Engineering (Autonomous) Karuppur Salem District 636011",
            "category": "engg",
            "district": "22"
        },
        {
            "key": "2709",
            "label": "Government College of Engineering (Formerly Institute of Road and Transport Technology) Vasavi College Post  Erode District 638316",
            "district": "8",
            "category": "engg"
        },
        {
            "key": "3464",
            "label": "Government College of Engineering Gandarvakottai Road Sengipatti Thanjavur District 613402",
            "category": "engg",
            "district": "25"
        },
        {
            "key": "3465",
            "label": "Government College of Engineering Srirangam Sethurappatti Tiruchirappalli District 620012",
            "category": "engg",
            "district": "29"
        },
        {
            "key": "4974",
            "label": "Government College of Engineering Tirunelveli District 627007",
            "category": "engg",
            "district": "30"
        },
        {
            "key": "5009",
            "label": "Government College of Engineering Melachokkanathapuram Bodinayakkanur Theni District 625582",
            "category": "engg",
            "district": "27"
        },
        {
            "key": "5901",
            "label": "Alagappa Chettair Government College of Engineering and Technology (Autonomous) Karaikudi Sivagangai District 630004",
            "category": "engg",
            "district": "23"
        }
    ];


module.exports = {
    RESERVED_KEYS,
    TX_ID_QUERY_PARAM,
    EXAMINATION_BOARDS,
    
    TFC_CENTER,
    YES_NO_ARRAY,
    HTTP_HEADERS,
    
    COLLEGE_DIST_MAP,
    COLLEGES_LIST,
    DEPT_LIST,
    
    CURR_TRANSFER_REASON,
    PREV_TRANSFER_REASON,
    TRANS_COLLEGES_LIST,

    COMPANY_TYPES,
    NDEPT_LIST,
    S3_UPLOAD_CERTIFICATES_LIST
};
