import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout } from '../../actions/auth';

const Navbar = ({ auth: { isAuthenticated, user }, logout }) => {
  const authLinks = (
    <ul>
      {/* <li>
        <Link to="/profiles">Developers</Link>
      </li>
      <li>
        <Link to="/posts">Posts</Link>
      </li> */}
      {/* <li>
        <Link to="/search">
        <i className="fas fa-list" />{' '}
          <span className="hide-sm">Change Password</span>
        </Link>
      </li>  */}
      <li>
        <Link to="/dashboard">
          <i className="fas fa-user" />{' '}
          <span className="hide-sm">Dashboard</span>
        </Link>
      </li>
      {/* <li>
        <a onClick={logout} href="/">
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout</span>
        </a>
      </li> */}
    </ul>
  );

  const guestLinks = (
    <ul>
      <li>
        <Link to="/">Directorate of Technical Education</Link>
      </li>
      {/* <li>
        <Link to="/register">Register</Link>
      </li> */}
      <li>
        <Link to="/login">Login</Link>
      </li>
    </ul>
  );

  const coordLinks = (
    <ul>
      {/* <li>        
        <Link to="/csearch">
        <i className="fa fa-list"  aria-hidden="true"/>{' '}
         <span className='hide-sm'> Search </span>
        </Link>
      </li> */}
      {/* <li>        
        <Link to="/reports">
        <i className="fa fa-list"  aria-hidden="true"/>{' '}
         <span className='hide-sm'> Reports </span>
        </Link>
      </li>   */}

      <li>
        <Link to="/coord3">
          <i className="fas fa-user" />{' '}
          <span className="hide-sm">Dashboard</span>
        </Link>
      </li>
      {/* <li>
        <a onClick={logout} href="/">
          <i className="fas fa-sign-out-alt" />{' '}
          <span className="hide-sm">Logout</span>
        </a>
      </li> */}
    </ul>
  );

  return (
    <nav className="navbar bg-dark">
      <h6 style={{padding: "0px"}}>
        
      <i className="fa fa-spinner" aria-hidden="true"></i>{' '}
          Transfer Counselling 2024
        
      </h6>
      <Fragment>{isAuthenticated ? (user && user.ltype && user.ltype.toString() === "C" ? coordLinks: authLinks) : guestLinks}</Fragment>
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logout })(Navbar);
