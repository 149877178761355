import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';

import Login from './components/auth/Login';
import Alert from './components/layout/Alert';
import Dashboard from './components/dashboard/Dashboard';

import TFCSelect from './components/dashboard/TFCSelect';
import VDash from './components/dashboard/VDash';
import VDash2 from './components/dashboard/VDash2';
import Pview from './components/dashboard/Pview';
import Pending from './components/profile-forms/Pending';

import NotFound from './components/layout/NotFound';
import PrivateRoute from './components/routing/PrivateRoute';
import { LOGOUT } from './actions/types';

// Redux
import { Provider } from 'react-redux';
import store from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';
import Dload2 from './components/dashboard/Dload2';
import EditCand from './components/dashboard/EditCand';
import CorSelect from './components/dashboard/CorSelect';

// import underconst from './components/layout/underconst';
import ReportDash from './components/dashboard/ReportDash';
import VerSearch from './components/profile-forms/VerSearch';
import VerSearchMore from './components/profile-forms/VerSearchMore';
import EditCandFG from './components/dashboard/EditCandFG';

import CVerSearch from './components/profile-forms/CVerSearch';
import Pview2 from './components/dashboard/Pview2';
import CorSelect2 from './components/dashboard/CorSelect2';
import CorSelect3 from './components/dashboard/CorSelect3';
import CorSelect4 from './components/dashboard/CorSelect4';

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Navbar />
        <Alert />
        <Routes>
          <Route path="/" element={<CorSelect3 />} />
          <Route path="login" element={<Login />} />

          <Route
            path="dashboard"
            element={<PrivateRoute component={CorSelect3} />}
          />
          <Route
            path="tfcdash"
            element={<PrivateRoute component={TFCSelect} />}
          />
          
          <Route
            path="verify"
            element={<PrivateRoute component={VDash} />}
          />

          <Route
            path="verify2"
            element={<PrivateRoute component={VDash2} />}
          />


          <Route
            path="vdload"
            element={<PrivateRoute component={Dload2} />}
          />

          <Route
            path="download"
            element={<PrivateRoute component={Pview} />}
          />

          <Route
            path="download2"
            element={<PrivateRoute component={Pview2} />}
          />

          <Route
            path="adash"
            element={<PrivateRoute component={EditCand} />}
          />

          
          <Route
            path="inlist"
            element={<PrivateRoute component={Pending} />}
          />

          <Route
            path="reports"
            element={<PrivateRoute component={ReportDash} />}
          />

          <Route
            path="coord"
            element={<PrivateRoute component={CorSelect} />}
          />

          <Route
            path="coord2"
            element={<PrivateRoute component={CorSelect2} />}
          />

          <Route
            path="search"
            element={<PrivateRoute component={VerSearch} />}
          />

          <Route
            path="csearch"
            element={<PrivateRoute component={CVerSearch} />}
          />

          <Route
            path="searchmore"
            element={<PrivateRoute component={VerSearchMore} />}
          />

          
          <Route
            path="fgedit"
            element={<PrivateRoute component={EditCandFG} />}
          />

          <Route
            path="coord3"
            element={<PrivateRoute component={CorSelect3} />}
          />
          <Route
            path="coord4"
            element={<PrivateRoute component={CorSelect4} />}
          />

        {/* <Route
            path="coord"
            element={<PrivateRoute component={underconst} />}
          /> */}


          <Route path="/*" element={<NotFound />} />
        </Routes>
      </Router>
    </Provider>
  );
};

export default App;
